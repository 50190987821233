<template>
  <div class="article-list wrapper">
    <div class="title">
      <div class="word">{{ name }}نىڭ بارلىق قىسىملىرى</div>
      <!--<div class="btn" v-show="ToCopyStatus">-->
        <!--<div class="btn-add" @click="toCopySeriesUrl">-->
          <!--توپلام ئادرېسنى كۆچۈرۈش-->
        <!--</div>-->
      <!--</div>-->
      <div class="btn">
        <div class="btn-refresh" @click="refresh">
          <i class="iconfont icon-shuaxin"></i>
        </div>
      </div>
      <div class="btn">
        <div class="btn-add" @click="toAdd">قوشۇش</div>
      </div>
      <div class="btn">
        <div class="btn-back" @click="toBack">
          <i class="iconfont icon-arrow-down"></i>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="header">
        <div class="col id">ID</div>
        <div class="col name">ماۋزو</div>
        <div class="col status">ھالىتى</div>
        <div class="col status">باھاسى</div>
        <!--<div class="col status">VIP تەلەپ قىلامدۇ؟</div>-->
        <div class="col opr">مەشغۇلات</div>
      </div>
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="col id">{{ item.id }}</div>
        <div class="col name">{{ item.name }}</div>
        <div class="col status">
          <Switch
            :status="item.status === 1"
            @update="switchState(item.id, item.status, index)"
          />
        </div>
        <div class="col status price">
          <div class="money" v-if="item.is_free === 0">
            {{ item.price }}
          </div>
          <div class="free" v-if="item.is_free === 1">
            ھەقسىز
          </div>
        </div>

        <!--<div class="col status">-->
          <!--<Switch-->
            <!--:status="item.is_vip === 1"-->
            <!--@update="switchVip(item.id, item.is_vip, index)"-->
            <!--positive-text="ھەئە"-->
            <!--negative-text="ياق"-->
          <!--/>-->
        <!--</div>-->
        <div class="col opr">
          <operation>
            <slot>
              <div class="btn opr-btn" @click="toEdit(item.id, item.name)">
                <i class="iconfont icon-bianji"></i>
                <div class="word">تەھرىرلەش</div>
              </div>
              <div
                class="btn opr-btn"
                @click="confirmDelete(item.id, item.name)"
              >
                <i class="iconfont icon-shanchu"></i>
                <div class="word">ئۆچۈرۈش</div>
              </div>
            </slot>
          </operation>
        </div>
      </div>
    </div>
    <div class="paginator" v-if="paginatorShown">
      <paginator
        :total="totalRecord"
        :page="curPage"
        :size="pageSize"
        :change="switchPage"
      />
    </div>
  </div>
  <confirm-box ref="confirmBox" :content="confirmBoxContent" @confirm="del" />
  <toast ref="toast" position="center" type="danger" />
</template>

<style lang="less" scoped>
@import "../../assets/less/global.less";
@import "../../assets/less/anim.less";

@icon-size: 40px;
@title-height: 60px;
.article-list {
  direction: rtl;
  display: flex;
  flex-direction: column;
  max-width: @wrap-width;
  min-width: @wrap-min-width;
  margin: @margin-size-large auto;
  border-radius: @radius-size;
  box-sizing: border-box;
  background: white;
  min-height: @wrap-min-height;

  .title {
    @btn-size: 40px;
    box-sizing: border-box;
    font-weight: bold;
    padding: 0 @margin-size;
    margin-bottom: @margin-size-large;
    border-bottom: fade(@color-nice-black, 10%) 1px solid;
    height: @title-height;
    line-height: @title-height;
    display: flex;

    .word {
      flex: 1;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-inline-start: @margin-size;

      .btn-refresh {
        width: @btn-size;
        height: @btn-size;
        line-height: @btn-size;
        text-align: center;
        border-radius: @radius-size;
        color: @color-primary;
        cursor: pointer;
        transition: background-color 200ms;

        &:hover {
          background: fade(@color-nice-black, 4%);
        }
      }

      .btn-back {
        width: @btn-size;
        height: @btn-size;
        line-height: @btn-size;
        text-align: center;
        border-radius: @radius-size;
        color: @color-primary;
        cursor: pointer;
        transform: rotate(90deg);
        transition: background-color 200ms;

        &:hover {
          background: fade(@color-nice-black, 4%);
        }
      }

      .btn-add {
        height: @btn-size;
        line-height: @btn-size - 4;
        background: fade(@color-primary-dark, 6%);
        color: @color-primary-dark;
        cursor: pointer;
        font-weight: normal;
        padding: 0 @margin-size;
        border-radius: @radius-size;
        transition: background-color 200ms, color 200ms;

        &:hover {
          background: @color-primary-dark;
          color: white;
        }
      }
    }
  }

  .list {
    flex: 1;
    @opr-btn-size: 40px;
    padding: @margin-size;

    .header {
      width: 100%;
      height: @icon-size * 2;
      align-items: center;
      display: flex;

      .col.id {
        width: @icon-size * 2;
        text-align: center;
      }

      .col.cover {
        flex: 1;
      }

      .col.name {
        flex: 3;
      }

      .col.status {
        flex: 1;
      }

      .col.opr {
        flex: 1;
        text-align: center;
      }
    }

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      border-top: fade(@color-nice-black, 10%) 1px dashed;
      padding: @margin-size-small 0;

      .col.id {
        width: @icon-size * 2;
        text-align: center;
      }

      .col.cover {
        flex: 1;

        img {
          height: 80px;
        }
      }

      .col.name {
        flex: 3;
      }

      .col.status {
        flex: 1;
      }

      .col.price {
        font-size: @font-size-mini;

        .free {
          display: inline-block;
          vertical-align: middle;
          background: @color-danger;
          color: white;
          padding: @margin-size-mini @margin-size-small;
          border-radius: @radius-size;
        }

        .money {
          display: inline-block;
          vertical-align: middle;
          background: @color-warning;
          color: white;
          padding: @margin-size-mini @margin-size-small;
          border-radius: @radius-size;

          &:before {
            content: "￥";
          }
        }
      }

      .col.opr {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: row;

        .btn {
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: transparent;
          transition: background-color 200ms, color 200ms;
          display: flex;

          &:hover {
            background: fade(@color-primary-dark, 6%);
            color: @color-primary-dark;
          }
        }
        .word {
          padding-right: 6px;
        }
      }
    }
  }

  .paginator {
    direction: ltr;
    box-sizing: border-box;
    height: @title-height;
    line-height: @title-height;
    padding: 0 @margin-size-large;
  }
}
</style>

<script>
import ConfirmBox from "../../components/ConfirmBox";
import Toast from "../../components/Toast";
import Paginator from "../../components/Paginator";
import Switch from "../../components/Switch";
import { copyToClipBoard } from "../../utils/Utils";
import Operation from "../../components/Operation";
import {
  getList,
  remove,
  updateStatus,
  updateVip,
  getArticleSeriesLinks,
} from "../../api/series";

let self;
let standById;
let requesting = false;
let searching = false;
export default {
  name: "SeriesList",
  emits: ["showLoading"],
  components: { Switch, Toast, ConfirmBox, Paginator, Operation },
  data() {
    return {
      id: 0,
      name: "",
        type:0,
      curPage: 1,
      pageSize: 10,
      totalRecord: 0,
      list: [],
      keyword: "",
      confirmBoxContent: "",
      ToCopyStatus: false,
      seriesLinks: "",
    };
  },
  computed: {
    paginatorShown() {
      if (self.totalRecord === 0) return false;
      return self.totalRecord / self.pageSize > 1;
    },
  },
  watch: {
    keyword(val) {
      if (val.length === 0) {
        searching = false;
        self.curPage = 1;
        self.loadList();
      }
    },
  },
  created() {
    self = this;
    self.id = Number(self.$route.params.id);
    self.type = Number(self.$route.params.type);
    self.name = self.$route.params.name;
  },
  mounted() {
    self.loadList();
    self.getSeriesLinks();
  },
  methods: {
    toBack() {
      self.$router.back();
    },
    toAdd() {
      self.$router.push({
        name: "SeriesCreate",
        params: { id: self.id,type:self.type, article_name: self.name },
      });
    },
    loadList() {
      getList({
        article_id: self.id,
        page: self.curPage,
        size: self.pageSize,
      }).then((res) => {
        self.curPage = res.page;
        self.pageSize = res.pageSize;
        self.totalRecord = res.total;
        self.list = res.series;
      });
    },
    getSeriesLinks() {
      getArticleSeriesLinks(self.id).then((res) => {
       self.seriesLinks = res.links
       if (self.seriesLinks.length > 0) {
         self.ToCopyStatus = true
       }
      });
    },
    confirmDelete(id, title) {
      standById = id;
      self.confirmBoxContent = "«" + title + "»" + "نى ئۆچۈرۈشنى جەزىملەمسىز؟";
      self.$refs.confirmBox.show();
    },
    del() {
      self.$refs.confirmBox.cancel();
      remove(standById).then(() =>
        searching ? self.search() : self.loadList()
      );
    },
    switchState(id, status, index) {
      if (requesting) return;
      requesting = true;
      let nextStatus = status === 0 ? 1 : 0;
      updateStatus({
        id: id,
        status: nextStatus,
      }).then((res) => {
        requesting = false;
        self.list[index].status = nextStatus;
        self.$refs.toast.show(res, "success", "center");
      });
    },
    toCopy(url) {
      copyToClipBoard(url);
      self.$refs.toast.show("كۆچۈرۈش مۇۋاپىقىيەتلىك بولدى");
    },
    switchVip(id, vip, index) {
      if (requesting) return;
      requesting = true;
      let nextVip = vip === 0 ? 1 : 0;
      updateVip({
        id: id,
        vip: nextVip,
      }).then((res) => {
        requesting = false;
        self.list[index].is_vip = nextVip;
        self.$refs.toast.show(res, "success", "center");
      });
    },
    toEdit(id, name) {
      self.$router.push({
        name: "SeriesEdit",
        params: { id: id, article_name: self.name, name: name },
      });
    },
    switchPage(val) {
      self.curPage = val;
      searching ? self.search() : self.loadList();
    },
    refresh() {
      self.curPage = 1;
      self.keyword = "";
      searching = false;
      self.loadList();
    },
    toCopySeriesUrl() {
      copyToClipBoard(self.seriesLinks);
      self.$refs.toast.show("!كۆچۈرۈش مۇۋاپىقىيەتلىك بولدى");
    },
  },
};
</script>