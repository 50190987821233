<template>
    <div class="switch" :class="{'on': status}" @click="switchStatus">
        <div class="dot">{{status ? positiveText : negativeText}}</div>
    </div>
</template>

<script>
    export default {
        name: "Switch",
        props: {
            status: {
                type: Boolean,
                default: false
            },
            positiveText: {
                type: String,
                default: 'ئوچۇق'
            },
            negativeText: {
                type: String,
                default: 'ئېتىك'
            }
        },
        methods: {
            switchStatus() {
                this.$emit('update')
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../assets/less/global.less";

    @switch-width: 80px;
    @switch-height: 36px;
    .switch {
        display: inline-block;
        width: @switch-width;
        height: @switch-height;
        box-sizing: border-box;
        position: relative;
        border-radius: @switch-height / 2;
        background: transparent;
        border: fade(@color-primary-dark, 20%) 1px solid;
        transition: background-color 200ms;
        cursor: pointer;
        user-select: none;

        .dot {
            position: absolute;
            width: @switch-width - 30px;
            height: @switch-height - 4px;
            line-height: @switch-height - 6px;
            box-sizing: border-box;
            text-align: center;
            font-size: @font-size-mini;
            background: @color-primary-dark;
            color: white;
            border-radius: (@switch-height - 4px) / 2;
            left: 1px;
            top: 1px;
            border: @color-primary-dark 1px solid;
            transition: background-color 200ms, color 200ms, left 200ms;
        }

        &.on {
            background: @color-primary-dark;

            .dot {
                left: @switch-width - (@switch-width - 30px) - 3px;
                background: white;
                color: @color-primary-dark;
                border-color: white;
            }
        }
    }
</style>