<template>
  <div class="adv-create wrapper">
    <div class="title">
      <div class="word">ئېلان قوشۇش</div>
    </div>
    <div class="form">
      <div class="row">
        <div class="col col-2">
          <label>ماۋزۇ</label>
          <label>
            <input
              class="input"
              type="text"
              placeholder="ئېلان ماۋزۇسىنى كىرگۈزۈڭ"
              v-model="title"
              ref="title"
              @change="setModified"
            />
          </label>
        </div>
        <div class="col col-1">
          <label>تەرتىپى</label>
          <label>
            <input
              class="input ltr"
              type="number"
              placeholder="ئېلان تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ"
              v-model="sort"
              @change="sortSetModified"
            />
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>ھالىتى</label>
          <Switch :status="status" @update="updateStatus" />
        </div>
        <!--<div class="col">-->
          <!--<label>تىپى</label>-->
          <!--<Radio-->
            <!--:options="types"-->
            <!--:chosen-index="chosenTypeIndex"-->
            <!--@update="updateChosenTypeIndex"-->
          <!--/>-->
        <!--</div>-->
        <div class="col">
          <label>مەنبە</label>
          <Radio
            :options="sources"
            :chosen-index="chosenSourceIndex"
            @update="updateChosenSourceIndex"
          />
        </div>
      </div>
      <div class="row" v-show="chosenSourceIndex > 0">
        <div class="col">
          <label>مەنبە ئۇچۇرى</label>
          <label v-if="chosenSourceIndex === 1">
            <article-search class="input" @articleId="getArticleId" />
            <!--<input class="input btn sm" type="text" disabled placeholder="ئەسەر تاللاڭ">-->
          </label>
          <label v-if="chosenSourceIndex === 2">
            <input
              class="input mn ltr"
              type="text"
              placeholder="appid"
              v-model="appId"
            />
            <input
              class="input mn ltr"
              type="text"
              placeholder="ئەپچاق بەت ئۇلانمىسىنى تولدۇرۇڭ"
              v-model="pageIndex"
            />
          </label>
          <label v-if="chosenSourceIndex === 3">
            <input
              class="input ltr sm"
              type="text"
              placeholder="سىرتقى ئۇلانما ئادرېسىنى تولدۇرۇڭ"
              v-model="url"
            />
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>
            <span>ئېلان رەسىمى</span>
            <Helper :content="imageHelp" />
          </label>
          <div
            class="upload-placeholder"
            @click="pickImage"
            v-if="imageInfo === null"
          >
            <i class="iconfont icon-tupian"></i>
          </div>
          <img
            class="image-viewer"
            @click="pickImage"
            :src="imageInfo.url"
            v-if="imageInfo != null"
            alt="adv image"
          />
          <input
            type="file"
            accept="image/*"
            ref="imageInput"
            @change="imageInputAtChange"
          />
        </div>
        <div class="col">
          <label>
            <span>باشلىنىش ۋاقتى</span>
            <Helper :content="dateHelp" />
          </label>
          <label>
            <input
              class="input-text"
              type="text"
              placeholder="باشلىنىش ۋاقتىنى تاللاڭ"
              v-model="startDate"
              @click="showStartDatePicker"
            />
            <date-picker
              v-if="startDatePickerShow"
              v-model="startDate"
              @close="startDatePickerShow = false"
              @input="startDatePickerInput"
            />
          </label>
        </div>
        <div class="col">
          <label>
            <span>ئاخىرلىشىش ۋاقتى</span>
            <Helper :content="dateHelp" />
          </label>
          <label>
            <input
              class="input-text"
              type="text"
              placeholder="ئاخىرلىشىش ۋاقتىنى تاللاڭ"
              @click="showEndDatePicker"
              v-model="endDate"
            />
            <date-picker
              v-if="endDatePickerShow"
              v-model="endDate"
              @close="endDatePickerShow = false"
              @input="endDatePickerInput"
            />
          </label>
        </div>
      </div>
      <div class="btn">
        <div class="btn-save" @click="add">ساقلاش</div>
        <div class="btn-back" @click="toBack">بىكار قىلىش</div>
      </div>
    </div>
  </div>
  <confirm-box
    ref="confirmBox"
    :content="confirmBoxContent"
    @confirm="cancel"
  />
  <toast ref="toast" position="center" type="danger" />
</template>

<script>
import Switch from "../../components/Switch";
import Radio from "../../components/Radio";
import Helper from "../../components/Helper";
import Toast from "../../components/Toast";
import ConfirmBox from "../../components/ConfirmBox";
import { upload } from "../../api/common";
import { create } from "../../api/adv";
import ArticleSearch from "../../components/ArticleSearch";
import Datepicker from "../../components/DatePicker.vue";

let self;
let requesting = false;
let modified = false;
export default {
  name: "AdvCreate",
  emits: ["showLoading"],
  // components: { Toast, Helper, Radio, Switch, ArticleSearch, ConfirmBox, Datepicker},
  components: {
    Toast: Toast,
    Helper: Helper,
    Radio: Radio,
    Switch: Switch,
    ArticleSearch: ArticleSearch,
    ConfirmBox: ConfirmBox,
    "date-picker": Datepicker,
  },
  created() {
    self = this;
  },
  data() {
    return {
      status: true,
      imageInfo: null,
      title: "",
      sort: "",
      dateStart: "",
      dateEnd: "",
      // types: ["باش بەت ئېلان"],
      chosenTypeIndex: 0,
      sources: ["يوق", "ئەسەر", "ئەپچاق", "سىرتقى ئۇلانما"],
      chosenSourceIndex: 0,
      imageHelp: "كەڭلىك 750 پېكسىل ئېگىزلىك 350 پېكسىل",
      dateHelp:
        "مەسىلەن: <span style='display: inline-block;direction:ltr'>2021/10/12</span>",
      articleId: 0,
      appId: "",
      pageIndex: "",
      url: "",
      confirmBoxContent: "",
      startDate: "",
      endDate: "",
      startDatePickerShow: false,
      endDatePickerShow: false,
    };
  },
  methods: {
    setModified() {
      modified = true;
    },
    sortSetModified() {
      modified = true;
    },
    statusSetModified() {
      modified = true;
    },
    dateStartSetModified() {
      modified = true;
    },
    dateEndSetModified() {
      modified = true;
    },
    toBack() {
      console.log(modified);
      if (modified) {
        self.confirmBoxContent = "توشقۇزغان ئۇچۇرلارنى راستىنلا بىكا قىلامسىز؟";
        self.$refs.confirmBox.show();
        return;
      }
      self.$router.back();
    },
    cancel() {
      self.$router.back();
      modified = false;
    },
    updateStatus() {
      modified = true;
      self.status = !self.status;
    },
    updateChosenTypeIndex(e) {
      modified = true;
      self.chosenTypeIndex = e.chosenIndex;
    },
    updateChosenSourceIndex(e) {
      self.articleId = 0;
      self.appId = "";
      self.pageIndex = "";
      self.url = "";
      self.chosenSourceIndex = e.chosenIndex;
      modified = true;
    },
    pickImage() {
      self.$refs.imageInput.click();
    },
    imageInputAtChange(e) {
      modified = true;
      if (e.target.files.length !== 1) {
        return;
      }
      let formData = new FormData();
      console.log(formData);
      formData.append("image", e.target.files[0]);
      formData.append("type", "adv");
      upload(formData).then((res) => {
        if (res.msg) {
          self.$refs.toast.show(res.msg);
          return;
        }
        self.imageInfo = res;
      });
    },
    add() {
      if (requesting) return;
      if (!self.validate()) return;
      let data = {
        title: self.title,
        image: self.imageInfo.path,
        sort: self.sort === "" ? 1 : Number(self.sort),
        status: self.status ? 1 : 0,
        // type: self.chosenTypeIndex,
        article_id: self.articleId,
        app_id: self.appId,
        app_url: self.pageIndex,
        web_url: self.url,
        start_time: self.startDate,
        end_time: self.endDate,
        sourceType: self.chosenSourceIndex,
      };

      requesting = true;

      create(data).then((res) => {
        self.$refs.toast.show(res, "success");
        setTimeout(() => {
          self.$router.back();
          requesting = false;
        }, 1000);
      });
    },
    getArticleId(e) {
      console.log(e);
      self.articleId = e;
    },
    validate() {
      if (self.title === "") {
        self.$refs.toast.show("ماۋزۇنى تولدۇرۇڭ");
        self.$refs.title.focus();
        return false;
      }
      if (self.imageInfo === null) {
        self.$refs.toast.show("ئېلان رەسىمى يوللاڭ");
        return false;
      }
      if (self.startDate === "") {
        self.$refs.toast.show("باشلىنىش ۋاقىنى تاللاڭ");
        return false;
      }
      if (self.endDate === "") {
        self.$refs.toast.show("ئاخىرلىشىش ۋاقىنى تاللاڭ");
        return false;
      }

      return true;
    },
    showStartDatePicker() {
      self.startDatePickerShow = true;
    },
    startDatePickerInput(date) {
      self.startDate = date;
    },
    showEndDatePicker() {
      self.endDatePickerShow = true;
    },
    endDatePickerInput(date) {
      self.endDate = date;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/less/global.less";
@import "../../assets/less/anim.less";

.adv-create {
  direction: rtl;
  max-width: @wrap-width;
  min-width: @wrap-min-width;
  margin: @margin-size-large auto;
  border-radius: @radius-size;
  box-sizing: border-box;
  background: white;
  min-height: @wrap-min-height;
  @title-height: 60px;
  @inputHeight: 36px;

  .title {
    @btn-size: 40px;
    box-sizing: border-box;
    font-weight: bold;
    padding: 0 @margin-size;
    margin-bottom: @margin-size-large;
    border-bottom: fade(@color-nice-black, 10%) 1px solid;
    height: @title-height;
    line-height: @title-height;
    display: flex;

    .word {
      flex: 1;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn-back {
        width: @btn-size;
        height: @btn-size;
        line-height: @btn-size;
        text-align: center;
        border-radius: @radius-size;
        color: @color-primary;
        cursor: pointer;
        transform: rotate(90deg);
        transition: background-color 200ms;

        &:hover {
          background: fade(@color-nice-black, 4%);
        }
      }

      .btn-save {
        height: @btn-size;
        line-height: @btn-size - 4;
        background: fade(@color-primary-dark, 6%);
        color: @color-primary-dark;
        cursor: pointer;
        font-weight: normal;
        padding: 0 @margin-size;
        border-radius: @radius-size;
        margin-inline-end: @margin-size;
        transition: background-color 200ms, color 200ms;

        &:hover {
          background: @color-primary-dark;
          color: white;
        }
      }
    }
  }

  .form {
    padding: @margin-size;

    .row {
      display: flex;
      margin-bottom: @margin-size-xl;

      .col {
        flex: 1;

        &.col-1 {
          flex: 1;
        }

        &.col-2 {
          flex: 2;
        }

        &.col-3 {
          flex: 3;
        }

        label {
          display: block;
          margin-bottom: @margin-size;
        }

        .input-text {
          padding: 8px;
          text-align: center;
          border: fade(@color-nice-black, 15%) 1px solid;
          color: #555;
          border-radius: @inputHeight / 2;
        }

        input.input {
          width: 74%;
          height: @inputHeight;
          border-radius: @inputHeight / 2;
          padding: 0 @margin-size;
          border: fade(@color-primary-dark, 20%) 1px solid;
          box-sizing: border-box;
          margin-left: @margin-size;

          &:focus {
            border-color: fade(@color-primary-dark, 40%);
          }

          &.mn {
            width: 30%;
          }

          &.sm {
            width: 60%;
          }

          &.btn {
            cursor: pointer;
          }

          &.ltr {
            direction: ltr;
          }
        }

        input[type="file"] {
          display: none;
        }

        @ph-width: 320px;
        @ph-height: 150px;

        .upload-placeholder {
          width: @ph-width;
          height: @ph-height;
          line-height: @ph-height;
          text-align: center;
          box-sizing: border-box;
          border-radius: @radius-size;
          border: fade(@color-primary-dark, 40%) 1px dashed;
          cursor: pointer;

          i {
            font-size: @font-size-xxxl;
            color: fade(@color-primary-dark, 40%);
          }
        }

        .image-viewer {
          height: @ph-height;
          max-width: @ph-width;
        }
      }
    }

    .btn {
      align-items: center;
      display: flex;

      .btn-save {
        height: 40px;
        line-height: 40px;
        width: 50px;
        background: fade(@color-primary-dark, 6%);
        color: @color-primary-dark;
        cursor: pointer;
        font-weight: normal;
        padding: 0 @margin-size;
        border-radius: @radius-size;
        margin-inline-end: @margin-size;
        transition: background-color 200ms, color 200ms;
        background: @color-primary-dark;
        color: white;

        &:hover {
          background: @color-primary-dark;
          color: white;
        }
      }

      .btn-back {
        height: 40px;
        line-height: 40px;
        width: 70px;
        background: fade(@color-accent, 6%);
        color: @color-accent;
        cursor: pointer;
        font-weight: normal;
        padding: 0 @margin-size;
        border-radius: @radius-size;
        margin-inline-end: @margin-size;
        transition: background-color 200ms, color 200ms;
        background-color: #fd397a;
        border-color: #fd397a;
        color: #fff;

        &:hover {
          background-color: #fd397a;
          border-color: #fd397a;
          color: #fff;
        }
      }
    }
  }
}
</style>