import request from "../utils/Request";

export function getList(obj) {
    return request({
        // url: '/api/admin/article-series-list',
        url: 'admin/article-series-list',
        method: 'get',
        params: obj
    })
}



export function updateStatus(obj) {
    return request({
        // url: '/api/admin/article-series-status',
        url: 'admin/article-series-status',
        method: 'post',
        data: obj
    })
}



export function remove(id) {
    return request({
        // url: '/api/admin/article-series-delete',
        url: 'admin/article-series-delete',
        method: 'get',
        params: {
            id: id
        }
    })
}


export function create(obj) {
    return request({
        // url: '/api/admin/article-series-add',
        url: 'admin/article-series-add',
        method: 'post',
        data: obj,
    })
}

export function getDetail(id) {
    return request({
        // url: '/api/admin/article-series-detail',
        url: 'admin/article-series-detail',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function update(obj) {
    return request({
        // url: '/api/admin/article-series-update',
        url: 'admin/article-series-update',
        method: 'post',
        data: obj,
    })
}

export function updateVip(obj) {
    return request({
        // url: '/api/admin/article-series-vip',
        url: 'admin/article-series-vip',
        method: 'post',
        data: obj,
    })
}

export function getArticleSeriesLinks(id) {
    return request({
        // url: '/api/admin/article-series-links/'+id,
        url: 'admin/article-series-links/'+id,
        method: 'get',
    })
}