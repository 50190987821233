import request from "../utils/Request";

export function send(obj) {
    return request({
        // url: '/api/admin/send-subscribe-message',
        url: 'admin/send-subscribe-message',
        method: 'post',
        data: obj,
    })
}


export function getList() {
    return request({
        // url: '/api/admin/subscribe-templates',
        url: 'admin/subscribe-templates',
        method: 'get',
    })
}

export function getDetail(obj) {
    return request({
        // url: '/api/admin/template-details',
        url: 'admin/template-details',
        method: 'get',
        params: obj
    })
}

export function getSubscribe(templateId) {
    return request({
        // url: '/api/admin/subscribe-message-log/' + templateId,
        url: 'admin/subscribe-message-log/' + templateId,
        method: 'get',
    })
}