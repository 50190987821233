<template>
    <div class="adv-list wrapper">
        <div class="title">
            <div class="word">تېڭشۈن ئېلان تەڭشىكى</div>
            <div class="btn">
                <div class="btn-refresh" @click="loadList">
                    <i class="iconfont icon-shuaxin"></i>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="header">
                <div class="col id">ID</div>
                <div class="col adv-title">تەڭشەك ئىسمى</div>
                <div class="col adv-content">مەزمۇنى</div>
                <div class="col adv-time">ئەڭ ئاخىرقى قىتىم ئۆزگەرتكەن ۋاقىت</div>
                <div class="col opr">مەشغۇلات</div>
            </div>
            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="col id">{{item.id}}</div>
                <div class="col adv-title">{{item.label}}</div>
                <div class="col adv-content">{{item.v}}</div>
                <div class="col adv-time">{{item.updated_at}}</div>
                <div class="col opr">
                    <div class="btn opr-btn" @click="toEdit(item.id,item.v)">
                        <i class="iconfont icon-bianji"></i>
                        <div class="word">تەھرىرلەش</div>
                    </div>
                </div>
            </div>
        </div>
        <div id="demo"></div>
    </div>
    <toast ref="toast"/>
    <edit-modal v-if="editMoalShown" height="250px" title="تەھرىرلەش" @positive="updateContent" @hide="hideEditModal">
        <slot>
            <textarea class="pretty-textarea" rows="6" v-model="content"></textarea>
        </slot>
    </edit-modal>
</template>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    @icon-size: 40px;
    .adv-list {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;
        @title-height: 60px;

        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-refresh {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .btn-add {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-start: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .list {
            @opr-btn-size: 40px;
            padding: @margin-size;

            .header {
                width: 100%;
                height: @icon-size * 2;
                align-items: center;
                display: flex;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.adv-title {
                    flex: 2;
                }

                .col.adv-name {
                    flex: 1;
                }

                .col.adv-content {
                    flex: 4;
                }

                .col.adv-time {
                    flex: 2;
                    text-align: center;

                }

                .col.status {
                    flex: 1;
                }

                .col.opr {
                    flex: 1;
                    text-align: center;
                }
            }

            .item {
                width: 100%;
                display: flex;
                align-items: center;
                border-top: fade(@color-nice-black, 10%) 1px dashed;
                padding: @margin-size-small 0;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.adv-title {
                    flex: 2;
                }

                .col.adv-name {
                    flex: 1;
                }

                .col.adv-content {
                    flex: 4;
                    text-align: justify;
                    padding: 0 8px;
                }

                .col.adv-time {
                    flex: 2;
                    text-align: center;
                    direction: ltr;
                }

                .col.status {
                    flex: 1;
                }


                .col.opr {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    flex-direction: row;

                    .btn {
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: transparent;
                        transition: background-color 200ms, color 200ms;
                        display: flex;

                        &:hover {
                            background: fade(@color-primary-dark, 6%);
                            color: @color-primary-dark;
                        }
                    }

                    .word {
                        padding-right: 6px;
                    }
                }
            }

        }
    }

    .pretty-textarea {
        width: 95%;
        box-sizing: border-box;
        margin:@margin-size ;
        padding: @margin-size;
        background: @color-nice-bg;
        border-radius: @radius-size;
        border: none;
        direction: rtl;
    }
</style>

<script>
    import Toast from "../../components/Toast";
    import {advSetting, update} from "../../api/setting";
    import EditModal from "../../components/EditModal";
    // import E from "wangeditor"

    let self

    export default {
        name: "SettingTencentAdv",
        emits: ['showLoading'],
        components: {Toast, EditModal},
        data() {
            return {
                list: [],
                editMoalShown: false,
                content: "",
                id: 0,
                editor:null
            }
        },
        created() {
            self = this
        },
        mounted() {
            self.loadList()

        },
        methods: {
            loadList() {
                advSetting().then(res =>
                    self.list = res)
            },
            toEdit(id, content) {
                self.id = id
                self.content = content
                self.editMoalShown = true
            },
            hideEditModal() {
                self.id = 0
                self.content = ""
                self.editMoalShown = false
            },
            updateContent() {
                update({
                    id: self.id,
                    value: self.content
                }).then(res => {
                    self.hideEditModal()
                    self.$refs.toast.show(res, "success", "center")
                    self.loadList()
                })
            },

        }
    }
</script>