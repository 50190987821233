import request from '../utils/Request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

export function login(obj) {
    return request({
        // url: '/api/admin/login', // url = base url + request url
        url: 'admin/login', // url = base url + request url
        method: 'post',
        data: obj,
    })
}

export function newUsers() {
    return request({
        // url: '/api/admin/new-users',
        url: 'admin/new-users',
        method: 'get',
    })
}

export function upload(obj) {
    return request({
        // url: '/api/admin/upload', // url = base url + request url
        url: 'admin/upload', // url = base url + request url
        method: 'post',
        data: obj,
    })
}
export function uploadVideo(obj) {
    return request({
        url: '/api/admin/upload-video', // url = base url + request url
        // url: 'admin/upload-video', // url = base url + request url
        method: 'post',
        data: obj,
    })
}

export function getSalonResult(obj) {
    return request({
        // url: '/api/admin/tencent-vid',
        url: 'admin/tencent-vid',
        method: 'get',
        params: obj
    })
}
export function usersChart(obj) {
    return request({
        // url: '/api/admin/user-chart',
        url: 'admin/user-chart',
        method: 'get',
        params: obj
    })
}
export function incomeChart(obj) {
    return request({
        // url: '/api/admin/income-chart',
        url: 'admin/income-chart',
        method: 'get',
        params: obj
    })
}


export function updateAdminInfo(obj) {
    return request({
        // url: '/api/admin/admin-update',
        url: 'admin/admin-update',
        method: 'post',
        data: obj,
    })
}