<template>
    <div class="article-list wrapper">
        <div class="title">
            <div class="word"> قويۇلمايدىغان ئەسەرلەر</div>
            <div class="btn">
                <div class="btn-refresh" @click="refresh">
                    <i class="iconfont icon-shuaxin"></i>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="header">
                <div class="col id">ID</div>
                <div class="col cover">مۇقاۋا</div>
                <div class="col name">كىنو نامى</div>
                <div class="col id">كۆرۈلۈشى</div>
                <div class="col id">باھاسى</div>
                <div class="col status">ھالىتى</div>
                <div class="col status">تەۋسىيە ھالىتى</div>
                <div class="col opr">مەشغۇلات</div>
            </div>
            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="col id">{{item.id}}</div>
                <div class="col cover">
                    <img :src="item.coverUrl" @error="handleImageError(index)" :alt="item.name">
                </div>
                <div class="col name">{{item.name}}</div>
                <div class="col id">{{item.rank}}</div>
                <div class="col id price">
                    <div class="free" v-if="item.price === 0">ھەقسىز</div>
                    <div class="money" v-if="item.price > 0">{{item.price}}</div>
                </div>
                <div class="col status">
                    <Switch :status="item.status === 1" @update="switchState(item.id,item.status,index)"/>
                </div>
                <div class="col status">
                    <Switch :status="item.is_recommend === 1" @update="switchRecommend(item.id,item.is_recommend,index)"/>
                </div>
                <div class="col opr">
                    <operation>
                        <slot>
                            <div class="btn opr-btn" v-if="item.is_series === 1" @click="toSeries(item.id,item.name)">
                                <i class="iconfont icon-dianying1"></i>
                                <div class="word">قىسىم باشقۇرۇش</div>
                            </div>
                            <div class="btn opr-btn" @click="toCopy(item.url)">
                                <i class="iconfont icon-fuzhi"></i>
                                <div class="word">ئادىرىسنى كۆچۈرۈش</div>
                            </div>
                            <div class="btn opr-btn" @click="toEdit(item.id)">
                                <i class="iconfont icon-bianji"></i>
                                <div class="word">تەھرىرلەش</div>
                            </div>
                            <div class="btn opr-btn" @click="confirmDelete(item.id,item.name)">
                                <i class="iconfont icon-shanchu"></i>
                                <div class="word">ئۆچۈرۈش</div>
                            </div>
                        </slot>
                    </operation>
                </div>
            </div>
        </div>
        <div class="paginator" v-if="paginatorShown">
            <paginator :total="totalRecord" :page="curPage" :size="pageSize" :change="switchPage"/>
        </div>
    </div>
    <confirm-box ref="confirmBox" :content="confirmBoxContent" @confirm="del"/>
    <toast ref="toast" position="center" type="danger"/>
</template>
<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    @icon-size: 40px;
    @title-height: 60px;
    .article-list {
        direction: rtl;
        display: flex;
        flex-direction: column;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;


        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-inline-start: @margin-size;

                .btn-refresh {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .search-box {
                    width: @btn-size * 8;
                    height: @btn-size;
                    background: fade(@color-nice-black, 4%);
                    border-radius: @btn-size / 2;
                    display: flex;
                    position: relative;

                    label {
                        flex: 1;
                        display: flex;
                        input {
                            box-sizing: border-box;
                            flex: 1;
                            background: none;
                            border: none;
                            padding: 0 @margin-size;
                            padding-inline-end: @icon-size;
                        }
                    }

                    .btn-search {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: @icon-size;
                        height: @icon-size;
                        line-height: @icon-size;
                        text-align: center;
                        cursor: pointer;
                        transition: color 200ms;

                        &:hover {
                            color: @color-primary;
                        }
                    }
                }

                .btn-add {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .list {
            flex: 1;
            @opr-btn-size: 40px;
            padding: @margin-size;

            .header {
                width: 100%;
                height: @icon-size * 2;
                align-items: center;
                display: flex;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.cover {
                    flex: 1;
                }

                .col.name {
                    flex: 3;
                }

                .col.status {
                    flex: 1;
                }

                .col.opr {
                    flex: 1;
                    text-align: center;
                }
            }

            .item {
                width: 100%;
                display: flex;
                align-items: center;
                border-top: fade(@color-nice-black, 10%) 1px dashed;
                padding: @margin-size-small 0;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.cover {
                    flex: 1;

                    img {
                        height: 80px;
                    }
                }

                .col.name {
                    flex: 3;
                }

                .col.status {
                    flex: 1;
                }

                .col.price {
                    font-size: @font-size-mini;
                    .free {
                        display: inline-block;
                        vertical-align: middle;
                        background: @color-danger;
                        color: white;
                        padding: @margin-size-mini @margin-size-small;
                        border-radius: @radius-size;
                    }

                    .money {
                        display: inline-block;
                        vertical-align: middle;
                        background: @color-warning;
                        color: white;
                        padding: @margin-size-mini @margin-size-small;
                        border-radius: @radius-size;

                        &:before {
                            content: '￥';
                        }
                    }
                }

                .col.opr {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    flex-direction: row;

                    .btn {
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: transparent;
                        transition: background-color 200ms, color 200ms;
                        display: flex;

                        &:hover {
                            background: fade(@color-primary-dark, 6%);
                            color: @color-primary-dark;
                        }
                    }
                    .word{
                        padding-right: 6px;
                    }
                }
            }

        }
        .paginator {
            direction: ltr;
            box-sizing: border-box;
            height: @title-height;
            line-height: @title-height;
            padding: 0 @margin-size-large;
        }
    }
</style>      

<script>
    import {getWarningList, remove, updateRecommend, updateStatus} from "../../api/article";
    import ConfirmBox from "../../components/ConfirmBox";
    import Toast from "../../components/Toast";
    import Paginator from "../../components/Paginator";
    import Switch from "../../components/Switch";
    import {copyToClipBoard} from "../../utils/Utils";
    import Operation from "../../components/Operation";

    let self
    let standById
    let requesting = false
    export default {
        name: "WarningArticleList",
        emits: ['showLoading'],
        components: {Switch, Toast, ConfirmBox, Paginator,Operation},
        data() {
            return {
                curPage: 1,
                pageSize: 10,
                totalRecord: 0,
                list: [],
                confirmBoxContent: ''
            }
        },
        computed: {
            paginatorShown() {
                if (self.totalRecord === 0) return false
                return (self.totalRecord / self.pageSize) > 1
                
            }
        },
        created() {
            self = this
        },
        mounted() {
            self.loadList()
        },
        methods: {
            loadList() {
                getWarningList({
                    page: self.curPage,
                    size:self.pageSize
                }).then(res => {
                    console.log(res)
                    self.curPage = res.page
                    self.pageSize = res.pageSize
                    self.totalRecord = res.total
                    self.list = res.articles
                })
            },
            handleImageError(index) {
                self.list[index].coverUrl = require('../../assets/logo.png')
            },
            confirmDelete(id, title) {
                standById = id
                self.confirmBoxContent = '«' + title + '»' + 'نى ئۆچۈرۈشنى جەزىملەمسىز؟'
                self.$refs.confirmBox.show()
            },
            del() {
                self.$refs.confirmBox.cancel()
                remove(standById).then(() => self.loadList())
            },
            switchState(id, status, index) {
                if (requesting) return
                requesting = true
                let nextStatus = status === 0 ? 1 : 0
                updateStatus({
                    id: id,
                    status: nextStatus
                }).then((res) => {
                    requesting = false
                    self.list[index].status = nextStatus
                    self.$refs.toast.show(res, "success", "center")
                })
            },
            switchRecommend(id, recommend, index) {
                if (requesting) return
                requesting = true
                let nextRecommend = recommend === 0 ? 1 : 0
                updateRecommend({
                    id: id,
                    recommend: nextRecommend
                }).then((res) => {
                    requesting = false
                    self.list[index].is_recommend = nextRecommend
                    self.$refs.toast.show(res, "success", "center")
                })
            },
            toEdit(id) {
                self.$router.push({name: 'ArticleEdit', params: {id: id}})
            },
            toSeries(id,name){
                self.$router.push({name: 'SeriesList', params: {id: id,name:name}})
            },
            toCopy(url) {
            copyToClipBoard(url)
            self.$refs.toast.show('!كۆچۈرۈش مۇۋاپىقىيەتلىك بولدى')
            },
            switchPage(val) {
                self.curPage = val;
                self.loadList()
            },
        
            keywordInputAtKeydown(e) {
                if (e.code === 'Enter') self.search()
            },
            refresh() {
                self.curPage = 1
                self.loadList()
            }
        }
    }
</script>