<template>
    <div class="container">
        <div class="icon" @click="showOperation">
            <text class="iconfont icon-gengduo"></text>
        </div>
        <div class="more" :class="{bot:showFromBottom}" v-if="shown" @mouseleave="down">
            <slot></slot>
        </div>
    </div>

</template>

<script>
    export default {
        name: "Operation",
        data() {
            return {
                shown: false,
                showFromBottom: false
            }
        },
        methods: {
            down() {
                this.hide()
            },
            hide() {
                this.shown = false
            },
            showOperation(e){
                let cursorY = e.clientY
                let screenHeight = document.getElementById('app').clientHeight
                this.showFromBottom = (screenHeight - cursorY) < 360
                this.shown = !this.shown
            }
        }
    }
</script>
<style lang="less" scoped>
    @import "../assets/less/global.less";
    .container{
        position: relative;
        .icon{
            border-radius: 50%;
            padding: 8px;
            &:hover {
                background: fade(@color-primary-dark, 6%);
                color: @color-primary-dark;
            }
            .iconfont{
                font-size: @font-size-xxl;
            }

        }
        .more{
            position: absolute;
            top: 23px;
            left: 48px;
            min-width: 144px;
            width: 220px;
            // min-width: 144px;
            // max-width: 300px;
            background: #f2f3f8;
            border-radius: 4px;
            padding: 8px 20px;
            z-index: 1;
            &.bot{
                top: auto;
                bottom: 23px;
            }
        }
    }
</style>