<template>
  <div class="adv-list wrapper">
    <div class="title">
      <div class="word">بارلىق تۈرلەر</div>
      <div class="btn">
        <div class="btn-refresh" @click="loadList">
          <i class="iconfont icon-shuaxin"></i>
        </div>
      </div>
      <div class="btn">
        <div class="btn-add" @click="toAdd">قوشۇش</div>
      </div>
    </div>
    <div class="list">
      <div class="header">
        <div class="col id">ID</div>
        <div class="col adv-title">ماۋزۇ</div>
        <div class="col status">ھالەت</div>
        <div class="col updateHomePageRecommendStatus">
          باش بەتتە كۆرۈنەمدۇ؟
        </div>
        <div class="col opr">مەشغۇلات</div>
      </div>

      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="col id">{{ item.id }}</div>
        <div class="col adv-title">{{ item.name }}</div>

        <div class="col status">
          <Switch
            :status="item.status === 1"
            @update="switchState(item.id, item.status, index)"
          />
        </div>
        <div class="col updateHomePageRecommendStatus">
          <Switch
            :status="item.is_recommend === 1"
            @update="
              updateHomePageRecommendStatus(item.id, item.is_recommend, index)
            "
            positive-text="ھەئە"
            negative-text="ياق"
          />
        </div>
        <div class="col opr">
          <operation>
            <slot>
              <div class="btn opr-btn" @click="toEdit(item.id)">
                <i class="iconfont icon-bianji"></i>
                <div class="word">تەھرىرلەش</div>
              </div>
              <div
                class="btn opr-btn"
                @click="updateArticleStatus(item.id, item.name)"
              >
                <i class="iconfont icon-lockopenoutline"></i>
                <div class="word">تۈردىكى ئەسەرلەر كۆرۈنسۇن</div>
              </div>
              <div
                class="btn opr-btn"
                @click="confirmUpdateArticleStatus(item.id, item.name)"
              >
                <i class="iconfont icon-lockoutline"></i>
                <div class="word">تۈردىكى ئەسەرلەر كۆرۈنمىسۇن</div>
              </div>
              <div
                class="btn opr-btn"
                @click="confirmHiddenStatus(item.id, item.name)"
              >
                <i class="iconfont icon-eyeclose-fill"></i>
                <div class="word">تۈردىكى ئەسەرلەر يوشۇرۇلسۇن</div>
              </div>
              <div
                class="btn opr-btn"
                @click="confirmDisplayStatus(item.id, item.name)"
              >
                <i class="iconfont icon-yanjing2"></i>
                <div class="word">تۈردىكى ئەسەرلەر يوشۇرۇلمىسۇن</div>
              </div>
              <div
                class="btn opr-btn"
                @click="updateArticleRecommen(item.id, item.name)"
              >
                <i class="iconfont icon-Put-away-left-outlined"></i>
                <div class="word">تۈردىكى ئەسەرلەر تەۋسىيەلەنسۇن</div>
              </div>
              <div
                class="btn opr-btn"
                @click="confirmUpdateArticleRecommendStatus(item.id, item.name)"
              >
                <i class="iconfont icon-Put-away-right-outlined-copy"></i>
                <div class="word">تۈردىكى ئەسەرلەر تەۋسىيەلەنمىسۇن</div>
              </div>
              <div
                class="btn opr-btn"
                @click="confirmDelete(item.id, item.name)"
              >
                <i class="iconfont icon-shanchu"></i>
                <div class="word">ئۆچۈرۈش</div>
              </div>
            </slot>
          </operation>
        </div>
      </div>
    </div>
  </div>
  <confirm-box ref="confirmBox" :content="confirmBoxContent" @confirm="del" />
  <toast ref="toast" position="center" type="danger"/>
</template>

<style lang="less" scoped>
@import "../../assets/less/global.less";
@import "../../assets/less/anim.less";

@icon-size: 40px;
.adv-list {
  direction: rtl;
  max-width: @wrap-width;
  min-width: @wrap-min-width;
  margin: @margin-size-large auto;
  border-radius: @radius-size;
  box-sizing: border-box;
  background: white;
  min-height: @wrap-min-height;
  @title-height: 60px;

  .title {
    @btn-size: 40px;
    box-sizing: border-box;
    font-weight: bold;
    padding: 0 @margin-size;
    margin-bottom: @margin-size-large;
    border-bottom: fade(@color-nice-black, 10%) 1px solid;
    height: @title-height;
    line-height: @title-height;
    display: flex;

    .word {
      flex: 1;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn-refresh {
        width: @btn-size;
        height: @btn-size;
        line-height: @btn-size;
        text-align: center;
        border-radius: @radius-size;
        color: @color-primary;
        cursor: pointer;
        transition: background-color 200ms;

        &:hover {
          background: fade(@color-nice-black, 4%);
        }
      }

      .btn-add {
        height: @btn-size;
        line-height: @btn-size - 4;
        background: fade(@color-primary-dark, 6%);
        color: @color-primary-dark;
        cursor: pointer;
        font-weight: normal;
        padding: 0 @margin-size;
        border-radius: @radius-size;
        margin-inline-start: @margin-size;
        transition: background-color 200ms, color 200ms;

        &:hover {
          background: @color-primary-dark;
          color: white;
        }
      }
    }
  }

  .list {
    @opr-btn-size: 40px;
    padding: @margin-size;

    .header {
      width: 100%;
      height: @icon-size * 2;
      align-items: center;
      display: flex;

      .col.id {
        width: @icon-size * 2;
        text-align: center;
      }

      .col.cover {
        flex: 3;
      }

      .col.adv-title {
        flex: 2;
      }

      .col.status {
        flex: 2;
      }

      .col.updateHomePageRecommendStatus {
        flex: 2;
      }

      .col.opr {
        flex: 1;
        text-align: center;
      }
    }

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      border-top: fade(@color-nice-black, 10%) 1px dashed;
      padding: @margin-size-small 0;

      .col.id {
        width: @icon-size * 2;
        text-align: center;
      }

      .col.cover {
        flex: 3;

        img {
          height: 80px;
          width: 80px;
        }
      }

      .col.adv-title {
        flex: 2;
      }

      .col.status {
        flex: 2;
      }

      .col.updateHomePageRecommendStatus {
        flex: 2;
      }

      .col.opr {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: row;

        .btn {
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: transparent;
          transition: background-color 200ms, color 200ms;
          display: flex;

          &:hover {
            background: fade(@color-primary-dark, 6%);
            color: @color-primary-dark;
          }
        }
        .word {
          padding-right: 6px;
        }
      }
    }
  }
}
</style>

<script>
import {
  getList,
  remove,
  updateStatus,
  updateRecommend,
  updateArticleHideStatus,
  updateArticleStatus,
  updateArticleRecommendStatus
} from "../../api/category";
import ConfirmBox from "../../components/ConfirmBox";
import Switch from "../../components/Switch";
import Toast from "../../components/Toast";
import Operation from "../../components/Operation";

let self;
let standById;
let statusRequestHold = false;
let statusIsRecommend = false;
let opr;
let oprVal;
export default {
  name: "CategoryList",
  emits: ["showLoading"],
  components: { Toast, Switch, ConfirmBox, Operation },
  data() {
    return {
      list: [],
      confirmBoxContent: "",
    };
  },
  created() {
    self = this;
  },
  mounted() {
    self.loadList();
  },
  methods: {
    toAdd() {
      self.$router.push("/category/create");
    },
    loadList() {
      getList().then((res) => (self.list = res));
    },
    handleImageError(index) {
      self.list[index].image = require("../../assets/logo.png");
    },
    confirmDelete(id, title) {
      opr = "confirmDelete"
      standById = id;
      self.confirmBoxContent = "«" + title + "»" + "نى ئۆچۈرۈشنى جەزىملەمسىز؟";
      self.$refs.confirmBox.show();
    },
    confirmHiddenStatus(id, name) {
      opr = "updateHideStatus";
      oprVal = 1;
      standById = id;
      self.confirmBoxContent =
        "«" + name + "»" + "تۈردىكى ئەسەرلەرنى يوشۇرامسىز";
      self.$refs.confirmBox.show();
    },
    confirmDisplayStatus(id,name) {
     opr = "updateDisplayStatus";
     oprVal = 0;
     standById = id;
     self.confirmBoxContent ="«" + name + "»" + "تۈردىكى ئەسەرلەرنى يۇشۇرمامسىز؟";
      self.$refs.confirmBox.show();
    },

    updateArticleStatus(id,name) {
      opr = "updateArticleStatus";
      oprVal = 1;
      standById = id;
      self.confirmBoxContent ="«" + name + "»" + "تۈردىكى ئەسەرلەر كۆرۈنسۇنمۇ";
      self.$refs.confirmBox.show();
    },

    confirmUpdateArticleStatus(id,name) {
      opr = "confirmUpdateArticleStatus";
      oprVal = 0;
      standById = id;
      self.confirmBoxContent ="«" + name + "»" + "تۈردىكى ئەسەرلەر كۆرۈنمىسۇنمۇ؟";
      self.$refs.confirmBox.show();
    } ,
    updateArticleRecommen(id,name) {
       opr = "updateArticleRecommen";
      oprVal = 1;
      standById = id;
      self.confirmBoxContent ="«" + name + "»" + "تۈردىكى ئەسەرلەر تەۋسىيەلەمسىز؟";
      self.$refs.confirmBox.show();
    },
    confirmUpdateArticleRecommendStatus(id,name) {
       opr = "confirmUpdateArticleRecommendStatus";
      oprVal = 0;
      standById = id;
      self.confirmBoxContent ="«" + name + "»" + "تۈردىكى ئەسەرلەر تەۋسىيەلىمەمسىز؟";
      self.$refs.confirmBox.show();
    },

    del() {
      if (opr == "updateHideStatus") {
        self.$refs.confirmBox.cancel();
        updateArticleHideStatus(standById,oprVal).then((res) => {
          self.$refs.toast.show(res);
        });
      }
      if (opr == "updateDisplayStatus") {
       self.$refs.confirmBox.cancel();
        updateArticleHideStatus(standById,oprVal).then((res) => {
          self.$refs.toast.show(res);
        });
      }
      if (opr == "updateArticleStatus") {
        self.$refs.confirmBox.cancel();
        updateArticleStatus(standById,oprVal).then((res) => {
          self.$refs.toast.show(res);
        });
      }
      if (opr == "confirmUpdateArticleStatus") {
        self.$refs.confirmBox.cancel();
        updateArticleStatus(standById,oprVal).then((res) => {
          self.$refs.toast.show(res);
        });
      }
      if (opr == "updateArticleRecommen") {
        self.$refs.confirmBox.cancel();
        updateArticleRecommendStatus(standById,oprVal).then((res) => {
          self.$refs.toast.show(res);
        });
      }
      if (opr == "confirmUpdateArticleRecommendStatus") {
         self.$refs.confirmBox.cancel();
        updateArticleRecommendStatus(standById,oprVal).then((res) => {
          self.$refs.toast.show(res);
        });
      }
       if (opr == "confirmDelete") {
           self.$refs.confirmBox.cancel();
        remove(standById).then(() => self.loadList());
       }
    },
    switchState(id, status, index) {
      if (statusRequestHold) return;
      statusRequestHold = true;
      let nextStatus = status === 0 ? 1 : 0;
      updateStatus({
        id: id,
        status: nextStatus,
      }).then((res) => {
        statusRequestHold = false;
        self.list[index].status = nextStatus;
        self.$refs.toast.show(res, "success", "center");
      });
    },
    updateHomePageRecommendStatus(id, is_recommend, index) {
      if (statusIsRecommend) return;
      statusIsRecommend = true;
      let nextIsRecommend = is_recommend === 0 ? 1 : 0;
      updateRecommend({
        id: id,
        is_recommend: nextIsRecommend,
      }).then((res) => {
        statusIsRecommend = false;
        self.list[index].is_recommend = nextIsRecommend;
        self.$refs.toast.show(res, "success", "center");
      });
    },
    toEdit(id) {
      self.$router.push({ name: "CategoryEdit", params: { id: id } });
    },
  },
};
</script>