<template>
    <div class="start-list wrapper">
        <div class="title">
            <div class="word">باشلاش تىزىملىكلىرى</div>
            <div class="btn">
                <div class="btn-refresh" @click="loadList">
                    <i class="iconfont icon-shuaxin"></i>
                </div>
            </div>
            <div class="btn">
                <div class="btn-add" @click="toAdd">قوشۇش</div>
            </div>
        </div>
        <div class="list">

            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="status">
                    <Switch :status="item.status === 1" @update="updateStatus(item.id,item.status,index)"/>
                </div>
                <div class="basic-info">
                    <div class="image">
                        <img :src="item.imageUrl" :alt="item.title">
                    </div>
                    <div class="name">{{item.title}}</div>
                </div>
                <div class="meta-info">
                    <div class="row">
                        <div class="col key">تىپى</div>
                        <div class="col val">{{item.source_label}}</div>
                    </div>
                    <div class="row">
                        <div class="col key">{{item.source_key}}</div>
                        <div class="col val" v-if="item.source_type < 3">{{item.source_val}}</div>
                        <div class="col val" v-if="item.source_type === 3">
                            <a :href="item.source_val" target="_blank">
                                <i class="iconfont icon-yuyan"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="btn-edit" @click="toEdit(item.id)">تەھرىرلەش</div>
            </div>

        </div>
    </div>
    <toast ref="toast" position="center" type="danger"/>
</template>

<script>
    import Switch from "../../components/Switch";
    import {getList, updateStatus} from "../../api/start";
    import Toast from "../../components/Toast";

    let self
    let requesting = false
    export default {
        name: "StartList",
        emits: ['showLoading'],
        components: {
            Toast,
            Switch
        },
        created() {
            self = this
            self.loadList()
        },
        data() {
            return {
                list: []
            }
        },
        methods: {
            toAdd() {
                self.$router.push('/start/create')
            },
            loadList() {
                getList().then(res => self.list = res)
            },
            updateStatus(id, status, index) {
                if (requesting) return
                requesting = true
                let nextStatus = status === 1 ? 0 : 1
                updateStatus({
                    id: id,
                    status: nextStatus
                }).then((res) => {
                    self.list[index].status = nextStatus
                    self.$refs.toast.show(res)
                    requesting = false
                })
            },
            toEdit(id) {
                self.$router.push({name: 'StartEdit', params: {id: id}})
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    @icon-size: 40px;
    .start-list {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        min-height: @wrap-min-height;
        @title-height: 70px;

        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            background: white;
            border-radius: @radius-size;
            padding: 0 @margin-size;
            margin-bottom: @margin-size;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-refresh {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .btn-add {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-start: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .list {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;

            .item {
                width: 346px;
                height: 346px;
                box-sizing: border-box;
                padding: @margin-size-xl;
                border-radius: @radius-size;
                background: white;
                margin: @margin-size;

                &:nth-child(4n) {
                    margin-left: 0;
                }

                &:nth-child(4n+1) {
                    margin-right: 0;
                }

                .status {
                    text-align: left;
                }

                .basic-info {
                    @image-size: 60px;
                    display: flex;
                    align-items: center;

                    .image {
                        width: @image-size;
                        height: @image-size;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .name {
                        padding-inline-start: @margin-size;
                        font-weight: bold;
                        font-size: @font-size-large;
                    }
                }

                .meta-info {
                    display: flex;
                    flex-direction: column;
                    margin-top: @margin-size-xl;

                    .row {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: @margin-size-large;

                        .col {
                            &.key {
                                flex: 1;
                                font-weight: bold;
                            }

                            &.val {
                                color: fade(@color-primary-dark, 40%);

                                a {
                                    color: fade(@color-primary-dark, 40%);
                                    transition: color 200ms;

                                    &:hover {
                                        color: fade(@color-primary-dark, 80%);
                                    }
                                }
                            }
                        }
                    }
                }

                .btn-edit {
                    margin-top: @margin-size-large;
                    height: @icon-size;
                    line-height: @icon-size - 4px;
                    text-align: center;
                    border-radius: @radius-size;
                    background: lighten(@color-primary, 4%);
                    color: white;
                    transition: background-color 200ms, transform 200ms;
                    cursor: pointer;

                    &:hover {
                        background: @color-primary;
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
</style>