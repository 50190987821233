import request from "../utils/Request";

export function booleanSetting(obj) {
    return request({
        // url: '/api/admin/boolean-settings',
        url: 'admin/boolean-settings',
        method: 'get',
        params: obj
    })
}
export function TextSetting(obj) {
    return request({
        // url: '/api/admin/text-settings',
        url: 'admin/text-settings',
        method: 'get',
        params: obj
    })
}
export function ImageSetting(obj) {
    return request({
        // url: '/api/admin/image-settings',
        url: 'admin/image-settings',
        method: 'get',
        params: obj
    })
}

export function update(obj) {
    return request({
        // url: '/api/admin/setting-update',
        url: 'admin/setting-update',
        method: 'post',
        data: obj
    })
}

