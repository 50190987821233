<template>
    <div class="start-create wrapper">
        <div class="title">
            <div class="word">باشلاش تىزىملىكى تەھرىرلەش</div>
        </div>
        <div class="form">
            <div class="row">
                <div class="col col-2">
                    <label>ماۋزۇ</label>
                    <label>
                        <input class="input" type="text" placeholder="باشلاش تىزىملىكى ماۋزۇسىنى كىرگۈزۈڭ"
                               v-model="title" @change="titelSetModified">
                    </label>
                </div>
                <div class="col col-1">
                    <label>تەرتىپى</label>
                    <label>
                        <input class="input ltr" type="number"
                               placeholder="تىزىملىك تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" v-model="sort" @change="sortSetModified">
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>ھالىتى</label>
                    <Switch :status="status" @update="updateStatus"/>
                </div>
                <div class="col">
                    <label>دېكوراتسىيە رەڭگى</label>
                    <label>
                        <input class="input ltr" type="color" v-model="color" @change="colorSetModified">
                    </label>
                </div>
                <div class="col">
                    <label>مەنبە</label>
                    <Radio :options="sources" :chosen-index="chosenSourceIndex" @update="updateChosenSourceIndex"/>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>مەنبە ئۇچۇرى</label>
                    <label v-if="chosenSourceIndex === 0">
                        <article-search ref="fs" class="input" @articleId="getArticleId"/>

                    </label>
                    <label v-if="chosenSourceIndex === 1">
                        <select-box class="input" :options="categories" :value="selectedCategory"
                                    @select="selectCategory"/>
                    </label>
                    <label v-if="chosenSourceIndex === 2">
                        <input class="input mn ltr" type="text" placeholder="appid" v-model="appId">
                        <input class="input mn ltr" type="text" placeholder="ئەپچاق بەت ئۇلانمىسىنى تولدۇرۇڭ"
                               v-model="pageIndex">
                    </label>
                    <label v-if="chosenSourceIndex === 3">
                        <input class="input ltr sm" type="text" placeholder="سىرتقى ئۇلانما ئادرېسىنى تولدۇرۇڭ"
                               v-model="url">
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>
                        <span>تىزىملىك سىنبەلگىسى</span>
                        <Helper :content="imageHelp"/>
                    </label>
                    <div class="upload-placeholder" @click="pickImage" v-if="imageInfo === null">
                        <i class="iconfont icon-tupian"></i>
                    </div>
                    <img class="image-viewer" @click="pickImage" :src="imageInfo.url" v-if="imageInfo != null"
                         alt="adv image">
                    <input type="file" accept="image/*" ref="imageInput" @change="imageInputAtChange">
                </div>
            </div>
             <div class="btn">
                <div class="btn-save" @click="edit">ساقلاش</div>
                <div class="btn-back" @click="toBack"> بىكار قىلىش </div>
            </div>
        </div>
    </div>
     <confirm-box
    ref="confirmBox"
    :content="confirmBoxContent"
    @confirm="cancel"
    />
    <toast ref="toast" position="center" type="danger"/>
</template>

<script>
    import Toast from "../../components/Toast";
    import Switch from "../../components/Switch";
    import Radio from "../../components/Radio";
    import Helper from "../../components/Helper";
    import {upload} from "../../api/common";
    import {getDetail, update} from "../../api/start";
    import ConfirmBox from "../../components/ConfirmBox";
    import ArticleSearch from "../../components/ArticleSearch";
    import SelectBox from "../../components/SelectBox";
    import {getList} from "../../api/category";

    let self
    let requesting = false
     let modified = false;
    export default {
        name: "StartEdit",
        emits: ['showLoading'],
        components: {Toast, Helper, Radio, Switch, ArticleSearch, SelectBox, ConfirmBox},
        created() {
            self = this
            self.id = Number(self.$route.params.id)
            self.loadDetail()
            self.loadCategories()
        },
        data() {
            return {
                id: 0,
                title: '',
                sort: '',
                articleId: 0,
                color: '#5867dd',
                status: true,
                imageInfo: null,
                sources: [
                    'ئەسەر',
                    'تۈر',
                    'ئەپچاق',
                    'سىرتقى ئۇلانما',
                    'سەھىپە',
                ],
                chosenSourceIndex: 0,
                imageHelp: "كەڭلىك 320 پېكسىل ئېگىزلىك 320 پېكسىل",
                dateHelp: "مەسىلەن: <span style='display: inline-block;direction:ltr'>2021/10/12</span>",
                orgData: null,
                appId: "",
                pageIndex: "",
                url: "",
                categories: [],
                orgCategory: [],
                selectedCategory: '',
                selectedCategoryIndex: 0,
                confirmBoxContent:"",
            }
        },
        computed: {
            category() {
                return self.orgCategory[self.selectedCategoryIndex]
            },
        },
        methods: {
            titelSetModified() {
             modified = true
            },
            sortSetModified() {
              modified = true
            },
            colorSetModified() {
              modified = true
            },
            toBack() {
                if (modified) {
                 self.confirmBoxContent = "توشقۇزغان ئۇچۇرلارنى راستىنلا بىكا قىلامسىز؟";
                  self.$refs.confirmBox.show();
                 return;
                }
                self.$router.back()
            },
            cancel() {
               self.$router.back();
               modified = false
             },
            updateStatus() {
                modified = true
                self.status = !self.status
            },
            updateChosenSourceIndex(e) {
                modified = true
                self.articleId = 0
                self.appId = ""
                self.pageIndex = ""
                self.url = ""
                self.chosenSourceIndex = e.chosenIndex
            },
            pickImage() {
                self.$refs.imageInput.click()
            },
            imageInputAtChange(e) {
                modified = true
                if (e.target.files.length !== 1) {
                    return
                }
                let formData = new FormData()
                formData.append('image', e.target.files[0])
                formData.append('type', 'start')
                upload(formData).then(res => {
                     if (res.msg) {
                         self.$refs.toast.show(res.msg)
                        return
                    }
                    self.imageInfo = res
                })
            },
            getArticleId(e) {
                modified = true
                self.articleId = e
            },
            edit() {
                if (requesting) return;
                if (!self.validate()) return
                let data = {
                    id: self.id,
                    title: self.title,
                    image: self.imageInfo.path,
                    sort: self.sort === '' ? 1 : Number(self.sort),
                    status: self.status ? 1 : 0,
                    source_type: self.chosenSourceIndex,
                    color: self.color,
                    article_id: self.articleId,
                    app_id:self.appId,
                    app_url:self.pageIndex,
                    web_url:self.url,
                    category:self.category.id,
                }

                requesting = true

                update(data).then(res => {
                    self.$refs.toast.show(res, 'success')
                    setTimeout(() => {
                        self.$router.back()
                        requesting = false
                    }, 1000)
                })

            },
            validate() {
                if (self.title === '') {
                    self.$refs.toast.show('ماۋزۇنى تولدۇرۇڭ')
                    self.$refs.title.focus()
                    return false
                }
                if (self.imageInfo === null) {
                    self.$refs.toast.show('تىزىملىك سىنبەلگە رەسىمى يوللاڭ')
                    return false
                }
                return true;
            },
            loadDetail() {
                getDetail(self.id).then(res => {
                    self.orgData = res
                    self.fillData()
                })

            },
            fillData() {
                console.log(self.orgData.article_name)
                self.title = self.orgData.title
                self.sort = self.orgData.sort
                self.status = self.orgData.status === 1
                //self.word = self.orgData.source_val
                self.$refs.fs.setKeyword(self.orgData.source_val)
                self.imageInfo = {
                    url: self.orgData.imageUrl,
                    path: self.orgData.image
                }
                self.chosenSourceIndex = self.orgData.source_type === null ? 0 : Number(self.orgData.source_type)
                self.color = self.orgData.background_color
                self.appId = self.orgData.app_id
                self.pageIndex = self.orgData.app_url
                self.url = self.orgData.web_url
                for (let i = 0; i < self.orgCategory.length; i++) {
                    if (self.orgData.category_id === self.orgCategory[i].id) {
                        self.selectedCategoryIndex = i
                        self.selectedCategory = self.orgCategory[i].name
                        break
                    }
                }
                self.articleId = self.orgData.article_id
            },
            selectCategory(e) {
                self.selectedCategory = e.selectedItem
                self.selectedCategoryIndex = e.index
            },
            loadCategories() {
                getList()
                    .then(res => {
                        self.orgCategory = res
                        self.orgCategory.forEach(category => self.categories.push(category.name))
                    })
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    .start-create {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;
        @title-height: 60px;
        @inputHeight: 36px;

        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-back {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transform: rotate(90deg);
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .btn-save {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .form {
            padding: @margin-size;

            .row {
                display: flex;
                margin-bottom: @margin-size-xl;

                .col {
                    flex: 1;

                    &.col-1 {
                        flex: 1;
                    }

                    &.col-2 {
                        flex: 2;
                    }

                    &.col-3 {
                        flex: 3;
                    }

                    label {
                        display: block;
                        margin-bottom: @margin-size;

                        .input {
                            width: 60%;
                        }
                    }

                    input.input {
                        width: 74%;
                        height: @inputHeight;
                        border-radius: @inputHeight / 2;
                        padding: 0 @margin-size;
                        border: fade(@color-primary-dark, 20%) 1px solid;
                        box-sizing: border-box;
                        margin-left: @margin-size;

                        &:focus {
                            border-color: fade(@color-primary-dark, 40%)
                        }

                        &.mn {
                            width: 30%;
                        }

                        &.sm {
                            width: 60%;
                        }

                        &.btn {
                            cursor: pointer;
                        }

                        &.ltr {
                            direction: ltr;
                        }
                    }

                    input[type=file] {
                        display: none;
                    }

                    @ph-width: 150px;
                    @ph-height: 150px;

                    .upload-placeholder {
                        width: @ph-width;
                        height: @ph-height;
                        line-height: @ph-height;
                        text-align: center;
                        box-sizing: border-box;
                        border-radius: @radius-size;
                        border: fade(@color-primary-dark, 40%) 1px dashed;
                        cursor: pointer;

                        i {
                            font-size: @font-size-xxxl;
                            color: fade(@color-primary-dark, 40%);
                        }
                    }

                    .image-viewer {
                        width: @ph-width;
                        height: @ph-height;
                    }
                }
            }

            .btn {
                align-items: center;
                display: flex;

                .btn-save {
                    height: 40px;
                    line-height: 40px;
                    width: 50px;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;
                    background: @color-primary-dark;
                    color: white;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }

                .btn-back{
                   height: 40px;
                    line-height: 40px;
                    width: 70px;
                    background: fade(@color-accent, 6%);
                    color: @color-accent;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;
                    background-color: #fd397a;
                    border-color: #fd397a;
                    color: #fff;

                    &:hover {
                      background-color: #fd397a;
                      border-color: #fd397a;
                      color: #fff;
                    } 
                }
           }
        }
    }
</style>