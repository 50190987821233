import request from "../utils/Request";

export function getList(obj) {
    return request({
        // url: '/api/admin/user-list',
        url: 'admin/user-list',
        method: 'get',
        params: obj
    })
}

export function search(obj) {
    return request({
        // url: '/api/admin/user-search',
        url: 'admin/user-search',
        method: 'get',
        params: obj
    })
}

export function getContractedUser(obj) {
    return request({
        // url: '/api/admin/contracted-user',
        url: 'admin/contracted-user',
        method: 'get',
        params: obj
    })
}

export function getWithdrawUser(obj) {
    return request({
        // url: '/api/admin/withdraw-users',
        url: 'admin/withdraw-users',
        method: 'get',
        params: obj
    })
}


export function updateProfitPercent(obj) {
    return request({
        // url: '/api/admin/update-profit-percent',
        url: 'admin/update-profit-percent',
        method: 'get',
        params: obj
    })
}


export function applyWithdrew(obj) {
    return request({
        // url: '/api/admin/apply-withdraw',
        url: 'admin/apply-withdraw',
        method: 'post',
        data: obj
    })
}

export function getAgentList(obj) {
    return request({
        // url: '/api/admin/agent-list',
        url: 'admin/agent-list',
        method: 'get',
        params: obj
    })
}

export function updateStatus(obj) {
    return request({
        // url: '/api/admin/agent-list',
        url: 'admin/update-black',
        method: 'get',
        params: obj
    })
}