<template>
    <div class="toast"
            :class="{
      rtl: rtl,
      normal: $type === 'normal',
      success: $type === 'success',
      warning: $type === 'warning',
      danger: $type === 'danger',
      shown: shown,
      'shown-anim': showAnim,
      'pos-center': $position === 'center',
      'pos-left-top': $position === 'leftTop',
      'pos-left-bot': $position === 'leftBottom',
      'pos-right-top': $position === 'rightTop',
      'pos-right-bot': $position === 'rightBottom',
      'text-center': $icon === 'none',
    }"
    >
        <i :class="$icon" v-if="$icon !== 'none'"></i>{{ $text }}
    </div>
</template>
<style lang="less" scoped>
    @import "../assets/less/global.less";

    @toastMargin: 32px;
    .toast {
        position: fixed;
        z-index: 99;
        min-width: 100px;
        height: 50px;
        line-height: 50px;
        border-radius: 30px;
        padding: 0 20px;
        background: rgba(0, 0, 0, 0.61);
        color: white;
        font-size: @font-size;
        backdrop-filter: blur(8px);
        opacity: 0;
        filter: blur(12px);
        transition: all 200ms;
        display: none;
        direction: rtl;

        i {
            display: inline-block;
            margin-inline-end: 16px;
        }

        &.shown {
            display: block;
        }

        &.shown-anim {
            opacity: 1;
            filter: blur(0);
        }

        &.normal {
            background: rgba(255, 255, 255, 0.61);
            color: #333;
        }

        &.success {
            background: fadeout(@color-primary, 39%);
        }

        &.warning {
            background: fadeout(@color-warning, 39%);
        }

        &.danger {
            background: fadeout(@color-danger, 39%);
        }

        &.pos-center {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &.pos-left-top {
            left: @toastMargin;
            top: @toastMargin;
        }

        &.pos-left-bot {
            left: @toastMargin;
            bottom: @toastMargin;
        }

        &.pos-right-top {
            right: @toastMargin;
            top: @toastMargin;
        }

        &.pos-right-bot {
            right: @toastMargin;
            bottom: @toastMargin;
        }

        &.text-center {
            text-align: center;
        }
    }
</style>
<script>
    export default {
        name: "Toast",
        props: {
            type: {
                type: String,
                default: "normal", // normal | success | warning | danger
            },
            position: {
                type: String,
                default: "rightBottom", // center | leftTop | leftBottom | rightTop | rightBottom
            },
            text: {
                type: String,
                default: "",
            },
            icon: {
                type: String,
                default: "none", //iconfont class name
            },
            rtl: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                shown: false,
                showAnim: false,
                text_: "",
                type_: "normal",
                position_: "rightBottom",
                icon_: "none",
            };
        },
        computed: {
            $text() {
                return this.text_ === "" ? this.text : this.text_;
            },
            $type() {
                return this.type_ === "" ? this.type : this.type_;
            },
            $position() {
                return this.position_ === "" ? this.position : this.position_;
            },
            $icon() {
                return this.icon_ === "" ? this.icon : this.icon_;
            },
        },
        methods: {
            show(text = "", type = "", position = "", icon = "") {
                this.text_ = text;
                this.type_ = type;
                this.position_ = position;
                this.icon_ = icon;
                this.display()
            },
            display() {
                this.shown = true;
                this.showAnim = true;
                setTimeout(() => this.dismiss(), 3000);
            },
            dismiss() {
                this.showAnim = false;
                this.shown = false;
            },
            dismissed() {
                this.$emit("dismissed");
            },
        },
    };
</script>