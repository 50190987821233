<template>
  <div class="login">
    <div class="main">
      <div class="login-box">
        <h2>كىرىش</h2>
        <div class="tip">
          باشقۇرغۇچىلىق ئۇچۇرىڭىزنى كىرگۈزۈپ، سالاھىيىتىڭىزنى دەلىللەڭ
        </div>
        <form>
          <label>
            <input
              type="text"
              class="usr"
              ref="usr"
              v-model="usr"
              @keydown="usrAtKeyDown"
            />
          </label>
          <label>
            <input
              type="password"
              class="pwd"
              ref="pwd"
              v-model="pwd"
              @keydown="pwdAtKeyDown"
              autocomplete="off"
            />
          </label>
          <label>
            <button type="button" v-if="!loading" @click="login">كىرىش</button>
            <button type="button" v-if="loading">سەل ساقلاڭ</button>
          </label>
        </form>
      </div>
    </div>
    <div class="side">
      <div class="row">
        <img src="../assets/img/logo_white.png" alt="riddler" />
      </div>
      <div class="row">
        <h2>باشقۇرۇش مەركىزىگە خۇش كەپسىز!</h2>
        <div class="notice">
          تور دۇنياسى قانۇن سىرتىدىكى دۇنيا ئەمەس، قانۇن بىلەن ئ‍ويناشماڭ،
          باشقۇرۇش سېستىمىسىغا قانۇنسىز بۆسۈپ كىرىش ئەھۋالى بىردەك جىنايى ئىشلار
          قانونى بويىچە بىر تەرەپ قىلىنىدۇ!
        </div>
      </div>
      <div class="row">
        <!-- <div class="col">Riddler ©2021</div> -->
        <!--<div class="col">گۇاڭشى دەۋران تور پەن-تېخنىكا چەكلىك شىركىتى</div>-->
      </div>
    </div>
  </div>
  <toast ref="toast" />
</template>
<style lang="less" scoped>
@import "../assets/less/global.less";

.login {
  background: white;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;

  .main {
    flex: 6;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-box {
      min-width: 400px;
      min-height: 280px;
      display: flex;
      flex-direction: column;

      h2 {
        text-align: center;
      }

      .tip {
        color: fade(@color-nice-black, 40%);
        text-align: center;
      }

      label {
        height: 50px;
        margin-top: 40px;
        display: flex;

        input {
          flex: 1;
          background: fade(@color-primary-dark, 4%);
          padding: @margin-size;
          border-radius: 8px;
          transform: scale(0.9);
          border: transparent 1px solid;
          transition: 200ms transform, 400ms border-color;

          &:focus {
            transform: scale(1);
            border-color: @color-primary;
          }
        }

        button {
          flex: 1;
          background: @color-primary;
          border: none;
          color: white;
          border-radius: 8px;
          transform: scale(0.9);
          transition: 200ms transform, 200ms background;

          &:hover {
            transform: scale(1);
            background: @color-primary-dark;
          }
        }
      }
    }
  }

  .side {
    flex: 2;
    display: flex;
    flex-direction: column;
    direction: rtl;
    background: url("../assets/img/bg-1.jpg") no-repeat;
    background-size: cover;
    color: white;
    padding: 64px;
    font-size: 16px;

    .row:nth-child(2) {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .notice {
        line-height: 40px;
        color: fade(white, 80%);
      }
    }

    .row:nth-child(3) {
      display: flex;
      color: fade(white, 60%);

      .col:last-child {
        flex: 1;
        text-align: left;
      }
    }
  }
}
</style>
<script>
import Toast from "../components/Toast";
import { login } from "../api/common";

let self;
export default {
  components: { Toast },
  created() {
    self = this;
    self.loginCheck();
  },
  data() {
    return {
      usr: "",
      pwd: "",
      loading: false,
    };
  },
  methods: {
    login() {
      let chk = self.validate();
      if (!chk) return;
      self.loading = true;
      login({
        username: self.usr,
        password: self.pwd,
      })
        .then((res) => {
          if (res.err === undefined) {
            localStorage.setItem("token", res.token);
            localStorage.setItem("loginData", JSON.stringify(res));
            self.$router.replace("/");
          }else{
            self.$refs.toast.show(
            res.msg,
            "danger",
            "leftBottom"
          );  
          }
        })
        .catch(() => {
          self.$refs.toast.show(
            "كىرگۈزگەن ئۇچۇرىڭىز خاتاكەن",
            "danger",
            "leftBottom"
          );
        })
        .finally(() => {
          self.loading = false;
        });
    },
    validate() {
      if (self.usr === "") {
        self.$refs.toast.show(
          "باشقۇرغۇچىلىق نامىڭىزنى كىرگۈزۈڭ",
          "danger",
          "leftBottom"
        );
        self.$refs.usr.focus();
        return false;
      }
      if (self.pwd === "") {
        self.$refs.toast.show(
          "مەخپى نومۇرڭىزنى كىرگۈزۈڭ",
          "danger",
          "leftBottom"
        );
        self.$refs.pwd.focus();
        return false;
      }

      if (self.pwd.length < 6) {
        self.$refs.toast.show(
          "مەخپى نومۇرڭىز 6 خانىدىن كەم بولمىسۇن",
          "danger",
          "leftBottom"
        );
        self.$refs.pwd.focus();
        return false;
      }

      return true;
    },
    loginCheck() {
      if (
        localStorage.getItem("token") != null &&
        localStorage.getItem("loginData") != null
      ) {
        self.$router.replace("/");
      }
    },
    usrAtKeyDown(e) {
      if (e.code === "Enter" && self.usr.length > 3) {
        self.$refs.pwd.focus();
      }
    },
    pwdAtKeyDown(e) {
      if (e.code === "Enter" && self.pwd.length > 5) {
        self.login();
      }
    },
  },
};
</script>