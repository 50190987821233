<template>
  <div class="adv-list wrapper">
    <div class="title">
      <div class="word">كىلىشىم ئەزالار</div>
      <div class="btn">
        <div class="select">
          <div class="start-time">
            <label>
              <input
                class="input-text"
                type="text"
                placeholder="باشلىنىش ۋاقتىنى تاللاڭ"
                v-model="startDate"
                @click="showStartDatePicker"
              />
              <date-picker
                v-if="startDatePickerShow"
                v-model="startDate"
                @close="startDatePickerShow = false"
                @input="startDatePickerInput"
              />
            </label>
          </div>
          <div class="end-time">
            <label>
              <input
              class="input-text"
                type="text"
                placeholder="ئاخىرلىشىش ۋاقتىنى تاللاڭ"
                @click="showEndDatePicker"
                v-model="endDate"
              />
              <date-picker
                v-if="endDatePickerShow"
                v-model="endDate"
                @close="endDatePickerShow = false"
                @input="endDatePickerInput"
              />
            </label>
          </div>
        </div>
      </div>
      <div class="btn">
        <div class="btn-refresh" @click="loadList">
          <i class="iconfont icon-shuaxin"></i>
        </div>
      </div>
    </div>
    <div class="vipTerm-info">
      <div class="vipUser">
        <div class="vipTerm">
          <div class="vipUser-number">
            <div class="vipUser-word">
              <div class="vip">كېلىشىمدىكى ئەزالار</div>
              <div class="vipList-word">بارلىق كېلىشىم ئەزالىرى</div>
            </div>
            <span class="number">{{ totalRecord }}</span>
          </div>
          <div class="cancel-vipUser">
            <div class="cancel-word">
              <div class="cencel">بىكار قىلغان ئەزالار</div>
              <div class="cencel-number">كېلىشىمنى بىكار قىلغانلار سانى</div>
            </div>
            <span class="number">{{ non_signing }}</span>
          </div>
        </div>
      </div>
      <div class="vipUser">
        <div class="vipTerm">
          <div class="vipUser-number">
            <div class="vipUser-word">
              <div class="vip">پۇل تۇتۇلغان ئەزالار</div>
              <div class="vipList-word">ئاپتۇماتىك پۇل تۇتۇلغانلار</div>
            </div>
            <span class="green-number">{{ paid }}</span>
          </div>
          <div class="cancel-vipUser">
            <div class="cancel-word">
              <div class="cencel">پۇل تۇتۇشنى ساقلاۋاتقانلار</div>
              <div class="cencel-number">پۇل تۇتۇش مۇددىتى توشمىغانلار</div>
            </div>
            <span class="green-number">{{ awaitingPaymentUserCount }}</span>
          </div>
        </div>
      </div>
      <div class="vipUser">
        <div class="vipTerm">
          <div class="vipUser-number">
            <div class="vipUser-word">
              <div class="vip">تۇتۇلغان پۇل</div>
              <div class="vipList-word">ئاپتوماتىك تۇتۇلغان پۇل</div>
            </div>
            <span class="green-number">{{ total_amount }}￥ </span>
          </div>
          <div class="cancel-vipUser">
            <div class="cancel-word">
              <div class="cencel">ساقلاۋاتقان پۇل</div>
              <div class="cencel-number">
                ئاپتوماتىك تۇتۇشنى ساقلاۋاتقان پۇل
              </div>
            </div>
            <span class="number">{{ awaiting_payment }}￥ </span>
          </div>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="header">
        <div class="col id">ID</div>
        <div class="col cover">باش سۆرىتى</div>
        <div class="col adv-title">تور نامى</div>
        <div class="col opr">پۇل تۇتۇلغان ۋاقتى</div>
        <div class="col opr">تۇتۇلغان قىتىم سانى</div>
      </div>

      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="col id">{{ item.id }}</div>
        <div class="col cover">
          <img :src="item.avatar" />
        </div>
        <div class="col adv-title">{{ item.nick_name }}</div>
        <div class="col opr time">{{ item.last_applied_at }}</div>
        <div class="col opr">{{ item.contract_times }}</div>
      </div>
    </div>
    <div class="paginator" v-if="paginatorShown">
      <paginator
        :total="totalRecord"
        :page="curPage"
        :size="pageSize"
        :change="switchPage"
      />
    </div>
  </div>
  <toast ref="toast" />
</template>

<style lang="less" scoped>
@import "../../assets/less/global.less";
@import "../../assets/less/anim.less";

@icon-size: 40px;
@title-height: 90px;
.adv-list {
  direction: rtl;
  max-width: @wrap-width;
  min-width: @wrap-min-width;
  margin: @margin-size-large auto;
  border-radius: @radius-size;
  box-sizing: border-box;
  background: white;
  min-height: @wrap-min-height;

  .title {
    @btn-size: 40px;
    box-sizing: border-box;
    font-weight: bold;
    padding: 0 @margin-size;
    margin-bottom: @margin-size-large;
    border-bottom: fade(@color-nice-black, 10%) 1px solid;
    height: @title-height;
    line-height: @title-height;
    display: flex;

    .word {
      flex: 1;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn-refresh {
        width: @btn-size;
        height: @btn-size;
        line-height: @btn-size;
        text-align: center;
        border-radius: @radius-size;
        color: @color-primary;
        cursor: pointer;
        transition: background-color 200ms;

        &:hover {
          background: fade(@color-nice-black, 4%);
        }
      }

      .select {
        display: flex;
        text-align: center;
        align-items: center;
        padding-left: 32px;

        .start-time {
          margin: 0 @margin-size;
          direction: ltr;

          .input-text{
            padding: 8px;
            text-align: center;
            border: fade(@color-nice-black, 15%) 1px solid;
          }
        }

        .end-time {
          margin: 0 @margin-size;
          direction: ltr;
           
          .input-text{
            padding: 8px;
            text-align: center;
            border: fade(@color-nice-black, 15%) 1px solid;
          }
        }
      }
    }
  }

  .vipTerm-info {
    display: flex;
    align-items: center;
    padding: 0 @margin-size @margin-size @margin-size;
    .vipUser {
      flex: 1;
      border-left: 1px solid #ebedf2;
      padding: @margin-size;

      .vipTerm {
        padding: @margin-size;

        .vipUser-number {
          padding-bottom: @margin-size;
          border-bottom: 0.07rem dashed #ebedf2;
          display: flex;
          padding: 1.1rem 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          .vipUser-word {
            flex: 1;
            font-weight: 500;

            .vip {
              padding-bottom: @margin-size-small;
            }
            .vipList-word {
              color: #74788d;
            }
          }

          .number {
            font-size: 1.4rem;
            font-weight: 600;
            color: #5d78ff !important;
          }
          .green-number {
            color: #0abb87 !important;
            font-size: 1.4rem;
            font-weight: 600;
          }
        }

        .cancel-vipUser {
          padding-top: @margin-size;
          display: flex;

          .cancel-word {
            flex: 1;
            font-weight: 500;
            .cencel {
              padding-bottom: @margin-size-small;
            }
            .cencel-number {
              color: #74788d;
            }
          }

          .number {
            font-size: 1.4rem;
            font-weight: 600;
            color: #fd397a !important;
          }
          .green-number {
            font-size: 1.4rem;
            font-weight: 600;
            color: #5d78ff !important;
          }
        }
      }
    }
    :last-child {
      border-left: none;
    }
  }

  .list {
    @opr-btn-size: 40px;
    padding: 0 @margin-size @margin-size @margin-size;
    min-height: @wrap-min-height - 220;
    .header {
      width: 100%;
      height: @icon-size * 2;
      align-items: center;
      display: flex;

      .col.id {
        width: @icon-size * 2;
        text-align: center;
      }

      .col.cover {
        flex: 1;
      }

      .col.adv-title {
        flex: 1;
      }

      .col.status {
        flex: 2;
      }

      .col.opr {
        flex: 2;
        text-align: center;
        direction: ltr;
      }
    }

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      border-top: fade(@color-nice-black, 10%) 1px dashed;
      padding: @margin-size-small 0;

      .col.id {
        width: @icon-size * 2;
        text-align: center;
      }

      .col.cover {
        flex: 1;

        img {
          height: 40px;
        }
      }

      .col.adv-title {
        flex: 1;
      }

      .col.status {
        flex: 2;
      }

      .col.opr {
        flex: 2;
        text-align: center;
      }
    }
  }
}

.paginator {
  direction: ltr;
  box-sizing: border-box;
  height: @title-height;
  line-height: @title-height;
  padding: 0 @margin-size-large;
}
</style>

<script>
import { getContractedUser } from "../../api/user";
import Toast from "../../components/Toast";
import Paginator from "../../components/Paginator";
import Datepicker from "../../components/DatePicker.vue";

let self;
export default {
  name: "UserList",
  emits: ["showLoading"],
  components: {
    Toast: Toast,
    Paginator: Paginator,
    "date-picker": Datepicker,
  },
  data() {
    return {
      list: [],
      level_id: 2,
      keyword: "",
      curPage: 1,
      pageSize: 10,
      totalRecord: 0,
      signing: 0,
      awaiting_payment: 0,
      non_signing: 0,
      paid: 0,
      awaitingPaymentUserCount:0,
      total_amount: 0,
      startDate: "",
      endDate: "",
      startDatePickerShow: false,
      endDatePickerShow: false,
    };
  },
  created() {
    self = this;
  },
  mounted() {
    self.loadList();
  },
  computed: {
    paginatorShown() {
      if (self.totalRecord === 0) return false;
      return self.totalRecord / self.pageSize > 1;
    },
  },
  watch: {
    keyword(val) {
      if (val.length === 0) {
        self.curPage = 1;
        self.loadList();
      }
    },
  },
  methods: {
    loadList() {
      let data = {
        page: self.curPage,
      };
      if (this.startDate != "" && this.endDate != "") {
        data["start_date"] = this.startDate;
        data["end_date"] = this.endDate;
      }
      getContractedUser(data).then((res) => {
        self.curPage = res.page;
        self.pageSize = res.page_size;
        self.totalRecord = res.total;
        self.list = res.list;
        self.awaitingPaymentUserCount = res.awaiting_payment_user_count
        self.signing = res.signing_user_count;
        self.awaiting_payment = res.awaiting_payment_amount;
        (self.paid = res.paid_user_count),
          (self.non_signing = res.canceled_user_count),
          (self.total_amount = res.total_amount);
      });
    },
    switchPage(val) {
      self.curPage = val;
      self.loadList();
    },
    showStartDatePicker() {
      self.startDatePickerShow = true;
    },
    startDatePickerInput(date){
      self.startDate = date
    },
    showEndDatePicker() {
      self.endDatePickerShow = true;
    },
    endDatePickerInput(date){
      self.endDate = date
    },
  },
};
</script>