export function date2str(date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDay()
}

export function copyToClipBoard(content) {
    let fakeElement = document.createElement('textarea')
    fakeElement.value = content
    document.body.appendChild(fakeElement)
    fakeElement.select()
    document.execCommand('Copy')
    document.body.removeChild(fakeElement)
    fakeElement = null
}