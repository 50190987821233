import request from '../utils/Request'

export function create(obj) {
    return request({
        // url: '/api/admin/category-add',
        url: 'admin/category-add',
        method: 'post',
        data: obj,
    })
}
export function update(obj) {
    return request({
        // url: '/api/admin/category-update',
        url: 'admin/category-update',
        method: 'post',
        data: obj,
    })
}


export function getList() {
    return request({
        // url: '/api/admin/category-list',
        url: 'admin/category-list',
        method: 'get'
    })
}

export function getDetail(id) {
    return request({
        // url: '/api/admin/category-detail',
        url: 'admin/category-detail',
        method: 'get',
        params: {
            id: id
        }
    })
}


export function remove(id) {
    return request({
        // url: '/api/admin/category-delete',
        url: 'admin/category-delete',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function updateStatus(obj) {
    return request({
        // url: '/api/admin/category-update-status',
        url: 'admin/category-update-status',
        method: 'post',
        data: obj
    })
}

export function updateRecommend(obj) {
    return request({
        // url: '/api/admin/category-update-recommend',
        url: 'admin/category-update-recommend',
        method: 'post',
        data: obj
    })
}

export function updateArticleStatus(categoryId, status) {
    return request({
        // url: '/api/admin/category-article-update-status/' + categoryId + '/' + status,
        url: 'admin/category-article-update-status/' + categoryId + '/' + status,
        method: 'GET'
    })
}

export function updateArticleHideStatus(categoryId, status) {
    return request({
        // url: '/api/admin/category-article-update-hide-status/' + categoryId + '/' + status,
        url: 'admin/category-article-update-hide-status/' + categoryId + '/' + status,
        method: 'GET'
    })
}

export function updateArticleRecommendStatus(categoryId, status) {
    return request({
        // url: '/api/admin/category-article-update-recommend-status/' + categoryId + '/' + status,
        url: 'admin/category-article-update-recommend-status/' + categoryId + '/' + status,
        method: 'GET'
    })
}