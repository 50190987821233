<template>
    <div class="adv-list wrapper">
        <div class="title">
            <div class="word">بارلىق ئېلانلار
             <div class="first" :class="{'active': type === 0}" @click="switchType(0)">باش بەت</div>
             <!--<div class="second " :class="{'active': type === 1}"  @click="switchType(1)">قوشۇمچە بەت</div>-->
            </div>
            <div class="btn">
                <div class="btn-refresh" @click="loadList()">
                    <i class="iconfont icon-shuaxin"></i>
                </div>
            </div>
            <div class="btn">
                <div class="btn-add" @click="toAdd">قوشۇش</div>
            </div>
        </div>
        <div class="list">
            <div class="header">
                <div class="col id">ID</div>
                <div class="col cover">ئېلان سۆرىتى</div>
                <div class="col adv-title">ماۋزۇ</div>
                <div class="col status">ھالەت</div>
                <div class="col opr">مەشغۇلات</div>
            </div>

            <div class="item"  v-for="(item,index) in list" :key="index" >
                <div class="col id">{{item.id}}</div>
                <div class="col cover">
                    <img :src="item.imageUrl" @error="handleImageError(index)" :alt="item.title">
                </div>
                <div class="col adv-title">{{item.title}}</div>
                <div class="col status">
                    <Switch :status="item.status === 1" @update="switchState(item.id,item.status,index)"/>
                </div>
                <div class="col opr">
                    <operation>
                        <slot>
                            <div class="btn opr-btn" @click="toEdit(item.id)">
                            <i class="iconfont icon-bianji"></i>
                                <div class="word">تەھرىرلەش</div>
                            </div>
                            <div class="btn opr-btn" @click="confirmDelete(item.id,item.title)">
                            <i class="iconfont icon-shanchu"></i>
                                <div class="word">ئۆچۈرۈش</div>
                            </div>
                        </slot>
                    </operation>
                    <!--<div class="btn opr-btn" @click="toEdit(item.id)">-->
                        <!--<i class="iconfont icon-bianji"></i>-->
                    <!--</div>-->
                    <!--<div class="btn opr-btn" @click="confirmDelete(item.id,item.title)">-->
                        <!--<i class="iconfont icon-shanchu"></i>-->
                    <!--</div>-->
                </div>
            </div>
        </div>
    </div>
    <confirm-box ref="confirmBox" :content="confirmBoxContent" @confirm="del"/>
    <toast ref="toast"/>
</template>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    @icon-size: 40px;
    .adv-list {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;
        @title-height: 60px;

        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
                display: flex;
                align-items: center;

                .first{
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-start: @margin-size;
                    transition: background-color 200ms, color 200ms;

                     &.active {
                     background: @color-primary-dark;
                        color: white;
                     }
                    

                }

                .second{
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-start: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &.active {
                     background: @color-primary-dark;
                        color: white;
                     }
                
                }
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-refresh {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .btn-add {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-start: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .list {
            @opr-btn-size: 40px;
            padding: @margin-size;

            .header {
                width: 100%;
                height: @icon-size * 2;
                align-items: center;
                display: flex;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.cover {
                    flex: 3;
                }

                .col.adv-title {
                    flex: 4;
                }

                .col.status {
                    flex: 2;
                }

                .col.opr {
                    flex: 1;
                    text-align: center;
                }
            }

            .item {
                width: 100%;
                display: flex;
                align-items: center;
                border-top: fade(@color-nice-black, 10%) 1px dashed;
                padding: @margin-size-small 0;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.cover {
                    flex: 3;

                    img {
                        height: 80px;
                    }
                }

                .col.adv-title {
                    flex: 4;
                }

                .col.status {
                    flex: 2;
                }


                .col.opr {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    flex-direction: row;

                    .btn {
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: transparent;
                        transition: background-color 200ms, color 200ms;
                        display: flex;

                        &:hover {
                            background: fade(@color-primary-dark, 6%);
                            color: @color-primary-dark;
                        }
                    }
                    .word{
                        padding-right: 6px;
                    }
                }
            }

        }
    }
</style>

<script>
    import {getList, remove, updateStatus} from "../../api/adv";
    import ConfirmBox from "../../components/ConfirmBox";
    import Switch from "../../components/Switch";
    import Toast from "../../components/Toast";
    import Operation from "../../components/Operation";

    let self
    let standById
    let statusRequestHold = false
    export default {
        name: "AdvList",
        emits: ['showLoading'],
        components: {Toast, Switch, ConfirmBox,Operation},
        data() {
            return {
                list: [],
                confirmBoxContent: '',
                curPage: 1,
                pageSize: 10,
                totalRecord: 0,
                type:0
            }
        },
        created() {
            self = this
            
        },
        mounted() {
            console.log(self.list) 
            self.loadList()
            
        },

        methods: {
            toAdd() {
                self.$router.push('/adv/create')
            },
            loadList() {
                getList({
                   type: self.type, 
                }).then(res => {
                    console.log(res)
                   self.list = res
                })
            },
            switchType(type){
                self.type = Number(type)
                self.loadList()
            },
            handleImageError(index) {
                self.list[index].image = require('../../assets/logo.png')
            },
            confirmDelete(id, title) {
                standById = id
                self.confirmBoxContent = '«' + title + '»' + 'نى ئۆچۈرۈشنى جەزىملەمسىز؟'
                self.$refs.confirmBox.show()
            },
            del() {
                self.$refs.confirmBox.cancel()
                remove(standById).then(() => self.loadList())
            },
            switchState(id, status, index) {
                if (statusRequestHold) return
                statusRequestHold = true
                let nextStatus = status === 0 ? 1 : 0
                updateStatus({
                    id: id,
                    status: nextStatus
                })
                    .then((res) => {
                        statusRequestHold = false
                        self.list[index].status = nextStatus
                        self.$refs.toast.show(res, "success", "center")
                    })
            },
            toEdit(id) {
                self.$router.push({name:'AdvEdit',params:{id:id}})
            }
        }
    }
</script>