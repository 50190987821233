<template>
  <div class="container" :class="{ open: isShow === true }">
    <input
            type="text"
            class="input"
            placeholder="ئەسەر نامىنى كىرگۈزۈپ ئىزدەڭ"
            v-model="keyword"/>
    <div class="drop-box" :style="{ top: activeTop + 'px' }">
      <div class="divider"></div>
      <div
              class="result-item"
              v-for="(item, index) in articleList"
              :key="index"
              @click="clickItem(item)">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
    let keyword = null;
    import { articleSearch } from "../api/article";

    export default {
        name: "fs",
        props: {
            activeTop: {
                type: Number,
                default: 36,
            },
        },
        data() {
            return {
                keyword: '',
                articleList: [],
                isShow: false,
            };
        },
        watch: {
            keyword(val) {
                if (val.length > 3) {
                    if (keyword != null && val.indexOf(keyword) > -1) {
                        return;
                    }
                    keyword = val;
                    this.search();
                } else {
                    this.articleList = [];
                    this.isShow = false;
                    keyword = null;
                }
            },
        },
        methods: {
            search() {
                articleSearch({
                    name: this.keyword,
                }).then((res) => {
                    console.log(this.isShow);
                    this.articleList = res;
                    if (res.length > 0) {
                        this.isShow = true;
                    }
                });
            },
            clickItem(e) {
                this.keyword = e.name;
                this.isShow = false;
                this.$emit("articleId", e.id);
            },
            clearKeyword(){
                this.keyword = ''
                keyword = ''
            },
            setKeyword(word){
                this.keyword = word
            }
        },
    };
</script>
<style lang="less" scoped>
@import "../assets/less/global.less";

@inputHeight: 36px;
.container {
  position: relative;

  &.open {
    .input {
      border-radius: @inputHeight / 2 @inputHeight / 2 0 0;
    }

    .drop-box {
      display: block;
    }
  }

  .input {
    width: 100%;
    height: @inputHeight;
    border-radius: @inputHeight / 2;
    padding: 0 @margin-size;
    border: fade(@color-primary-dark, 20%) 1px solid;
    box-sizing: border-box;
    margin-left: @margin-size;
  }

  .drop-box {
    position: absolute;
    width: 100%;
    padding: 0 @margin-size;
    background: white;
    z-index: 1;
    border: fade(@color-primary-dark, 20%) 1px solid;
    border-width: 0 1px 1px 1px;
    box-sizing: border-box;
    margin-top: -1px;
    border-radius: 0 0 @inputHeight / 2 @inputHeight / 2;
    display: none;
    height: 250px;
    overflow-y: scroll;

    .divider {
      height: 1px;
      background: fade(@color-primary-dark, 10%);
      width: 90%;
      margin: 0 auto;
    }

    .result-item {
      padding: @margin-size-mini @margin-size-small;
      font-weight: normal;
      cursor: pointer;
      &:hover{
        color: @color-primary;
      }
    }
  }
}
</style>