<template>
    <div class="side">
        <div class="logo">
            <img class="img" src="../assets/logo.png" alt="">
        </div>
        <div class="nav">
            <div class="block" v-for="(menuItem,index) in menuData" v-bind:key="index">
                <router-link class="nav-item" :to="menuItem.link" v-if="menuItem.type === 'link'">
                    <i class="iconfont" :class="menuItem.icon"></i>{{menuItem.title}}
                </router-link>
                <label v-if="menuItem.type === 'area'">{{menuItem.title}}</label>
                <div class="area" v-if="menuItem.type === 'area'">
                    <div class="collapsable" :class="{'open':collapsable.open}" :style="{height: collapsable.height}"
                         v-for="(collapsable,collapsableIndex) in menuItem.collapsable" :key="collapsableIndex">
                        <div class="collapsable-title" :data-menu-index="index"
                             :data-collapsable-index="collapsableIndex" @click="switchCollapse($event)">
                            <i class="iconfont" :class="collapsable.icon"></i>{{collapsable.title}}
                            <i class="chevron iconfont icon-arrow-right-bold"></i>
                        </div>
                        <router-link class="collapsable-nav-item" :to="link.link"
                                     v-for="(link,linkIndex) in collapsable.links"
                                     :key="linkIndex">
                            <i class="iconfont" :class="link.icon"></i>{{link.title}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    let self
    let menuData = [
        {
            type: 'link',
            link: '/',
            title: 'باش بەت',
            icon: 'icon-shouye1'
        },
        {
            type: 'area',
            title: 'ئېلان باشقۇرۇش',
            collapsable: [
                {
                    title: 'ئېلانلار',
                    icon: 'icon-file-common-filling',
                    height: '40px',
                    open: false,
                    links: [
                        {
                            link: '/adv/list',
                            icon: 'icon-caidan',
                            title: 'بارلىق ئېلانلار'
                        },
                        {
                            link: '/adv/create',
                            icon: 'icon-add-bold',
                            title: 'يېڭىدىن قوشۇش'
                        },
                    ]
                }
            ],
        },
        {
            type: 'area',
            title: 'باشلاش تىزىملىكى باشقۇرۇش',
            collapsable: [
                {
                    title: 'باشلاش تىزىملىكى',
                    icon: 'icon-windows-fill',
                    height: '40px',
                    open: false,
                    links: [
                        {
                            link: '/start/list',
                            icon: 'icon-list',
                            title: 'بارلىق تىزىملىك'
                        },
                        {
                            link: '/start/create',
                            icon: 'icon-add-bold',
                            title: 'يېڭىدىن قوشۇش'
                        },
                    ]
                }
            ],
        },
        {
            type: 'area',
            title: 'ئەسەر باشقۇرۇش',
            collapsable: [
                // {
                //     title: 'رايون باشقۇرۇش',
                //     icon: 'icon-ditu',
                //     height: '40px',
                //     open: false,
                //     links: [
                //         {
                //             link: '/region/list',
                //             icon: 'icon-list',
                //             title: 'بارلىق رايونلار'
                //         },
                //         {
                //             link: '/region/create',
                //             icon: 'icon-add-bold',
                //             title: 'يېڭىدىن قوشۇش'
                //         }
                //     ]
                // },
                {
                    title: 'تۈر باشقۇرۇش',
                    icon: 'icon-kabao',
                    open: false,
                    links: [
                        {
                            link: '/category/list',
                            icon: 'icon-list',
                            title: 'بارلىق تۈرلەر'
                        },
                        {
                            link: '/category/create',
                            icon: 'icon-add-bold',
                            title: 'يېڭىدىن قوشۇش'
                        }
                    ]
                },
                {
                    title: 'ئەسەر باشقۇرۇش',
                    icon: 'icon-zhibo',
                    open: false,
                    links: [
                        {
                            link: '/article/list',
                            icon: 'icon-article',
                            title: 'بارلىق ئەسەرلەر'
                        },
                         {
                            link: '/article/create',
                            icon: 'icon-add-bold',
                            title: 'يېڭىدىن قوشۇش'
                        },
                        {
                            link: '/article/warning-article-list',
                            icon: 'icon-article',
                            title: 'قويۇلمايدىغان ئەسەرلەر'
                        },
                        // {
                        //     link: '/article/comment-list',
                        //     icon: 'icon-pinglun2',
                        //     title: 'ئەسەر ئىنكاسلىرى'
                        // },
                       
                    ]
                },
                // {
                //     title: 'تىتىم ئەسەر',
                //     icon: 'icon-dianying1',
                //     open: false,
                //     links: [
                //         {
                //             link: '/trailer/list',
                //             icon: 'icon-article',
                //             title: 'بارلىق تىتىم ئەسەر'
                //         },
                //         {
                //             link: '/trailer/create',
                //             icon: 'icon-add-bold',
                //             title: 'يېڭىدىن قوشۇش'
                //         },
                //     ]
                // },
                // {
                //     title: 'سەھىپە باشقۇرۇش',
                //     icon: 'icon-huatifuhao',
                //     open: false,
                //     links: [
                //         {
                //             link: '/topic/list',
                //             icon: 'icon-list',
                //             title: 'بارلىق سەھىپىلەر'
                //         },
                //         {
                //             link: '/topic/create',
                //             icon: 'icon-add-bold',
                //             title: 'يېڭىدىن قوشۇش'
                //         }
                //     ]
                // },
            ],

        },
        {
            type: 'area',
            title: 'ئەزا باشقۇرۇش',
            collapsable: [
                {
                    title: 'ئەزالار',
                    icon: 'icon-user',
                    height: '40px',
                    open: false,
                    links: [
                        {
                            link: '/user/list',
                            icon: 'icon-yonghuqun',
                            title: 'بارلىق ئەزالار'
                        },
                        {
                            link: '/user/agent-list',
                            icon: 'icon-team',
                            title: 'ۋاكالەتچى ئەزالار'
                        },
                        {
                            link: '/user/contract-list',
                            icon: 'icon-huiyuan',
                            title: 'كىلىشىم ئەزالار'
                        },
                        {
                            link: '/user/cash',
                            icon: 'icon-wenjuan',
                            title: 'نەقلەشتۈرۈش ئىلتىماسى'
                        }
                    ]
                },
                {
                    title: 'ئەزالىق تۈرى',
                    icon: 'icon-trophy-fill',
                    open: false,
                    links: [
                        {
                            link: '/vipTerm/list',
                            icon: 'icon-huiyuan',
                            title: 'بارلىق ئەزالىق تۈرلىرى'
                        },
                        {
                            link: '/vipTerm/create',
                            icon: 'icon-add-bold',
                            title: 'ئەزالىق تۈرى قوشۇش'
                        }
                    ]
                }
            ]
        },
         {
            type: 'area',
            title: 'قوللىنىشچان قوراللار',
            collapsable: [
                {
                    title: 'قوللىنىشچان قوراللار',
                    icon: 'icon-gongju',
                    height: '40px',
                    open: false,
                    links: [
                        {
                            link: '/tool/Salon',
                            icon: 'icon-weixin',
                            title: 'سالون يازمىسىدىكى ئەسەر'
                        },
                        {
                            link: '/template/list',
                            icon: 'icon-laba',
                            title: 'موبەن ئۇچۇرى يوللاش'
                        }
                    ]
                },
            ]
        },
        {
            type: 'area',
            title: 'تەڭشەك باشقۇرۇش',
            collapsable: [
                {
                    title: 'تەڭشەكلەر',
                    icon: 'icon-shezhi',
                    height: '40px',
                    open: false,
                    links: [
                        {
                            link: '/setting/boolean-list',
                            icon: 'icon-shandian',
                            title: 'ئۇنۋىرسال تەڭشەك'
                        },
                        {
                            link: '/setting/text-list',
                            icon: 'icon-guanggao',
                            title: 'خەتلىك تەڭشەك'
                        },
                        {
                            link: '/setting/image-list',
                            icon: 'icon-guanggaoshezhi',
                            title: 'رەسىملىك تەڭشەكلەر'
                        },
                        // {
                        //     link: '/setting/tencent-adv',
                        //     icon: 'icon-xiaojuchang',
                        //     title: 'تېڭشۈن ئېلان تەڭشىكى'
                        // },
                        // {
                        //     link: '/setting/poster-setting',
                        //     icon: 'icon-laba',
                        //     title: 'تەشۋىقات تەڭشىكى'
                        // }
                    ]
                }
            ]
        },
        {
                type: 'link',
                link: '/agreement',
                icon: 'icon-zhiwen',
                title: 'ئىشلىتىش كىلىشىمى',
        }
    ]
    export default {
        name: "SideBar",
        data() {
            return {
                menuData: menuData
            }
        },
        created() {
            self = this
        },
        methods: {
            switchCollapse(e) {
                let menuIndex = Number(e.target.dataset.menuIndex)
                let collapsableIndex = Number(e.target.dataset.collapsableIndex)

                for (let i = 0; i < self.menuData.length; i++) {
                    if (self.menuData[i].type === 'area') {
                        for (let j = 0; j < self.menuData[i].collapsable.length; j++) {
                            if (i === menuIndex && j === collapsableIndex) {
                                self.menuData[i].collapsable[j].open = !self.menuData[menuIndex].collapsable[collapsableIndex].open
                                self.menuData[i].collapsable[j].height = self.menuData[i].collapsable[j].open ? ((self.menuData[i].collapsable[j].links.length + 1) * 40) + 'px' : '40px';
                            } else {
                                self.menuData[i].collapsable[j].open = false
                                self.menuData[i].collapsable[j].height = '40px';
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../assets/less/global.less";

    .side {
        box-sizing: border-box;
        padding: @margin-size;
        direction: rtl;
        user-select: none;
        color: lighten(@color-side-bg, 40%);
        z-index: 99;

        .logo {
            color: lighten(@color-side-bg, 70%);
            text-align: center;
            font-weight: bold;
            padding: @margin-size-small 0 0 0;
            font-size: @font-size-large;

            .img {
               height: 60px;
            }
        }

        .nav {
            label {
                display: block;
                margin: @margin-size 0;
                color: fade(white, 40%);
            }

            .nav-item {
                display: block;
                box-sizing: border-box;
                padding: @margin-size 0;
                margin-bottom: @margin-size;
                color: lighten(@color-side-bg, 40%);
                transition: color 200ms;

                .iconfont {
                    font-size: @font-size-large;
                    vertical-align: middle;
                    margin-inline-end: @margin-size-mini;
                }

                &.router-link-active {
                    color: lighten(@color-side-bg, 50%);
                }

                &.router-link-exact-active {
                    color: lighten(@color-primary-dark, 40%);

                    .iconfont {
                        color: @color-primary;
                    }
                }
            }

            .collapsable {
                transition: height 200ms;
                height: 40px;
                overflow: hidden;

                &.open {
                    .collapsable-title {
                        color: lighten(@color-primary, 5%);

                        .chevron {
                            position: absolute;
                            transform: rotate(90deg);
                            left: 0;
                        }
                    }

                    .iconfont {
                        color: @color-primary;
                    }

                    .collapsable-nav-item {
                        display: block;
                    }
                }

                .collapsable-title {
                    position: relative;
                    cursor: pointer;
                    height: 40px;
                    line-height: 40px;
                    margin-inline-start: @margin-size-small;
                    color: lighten(@color-side-bg, 60%);

                    .chevron {
                        position: absolute;
                        z-index: 0;
                        transform: rotate(180deg);
                        left: 0;
                        transition: transform 200ms;
                        font-size: @font-size;
                    }
                }

                .iconfont {
                    font-size: @font-size-large;
                    vertical-align: middle;
                    margin-inline-end: @margin-size-mini;
                }
            }

            .collapsable-nav-item {
                display: none;
                box-sizing: border-box;
                height: 40px;
                line-height: 40px;
                margin-inline-start: @margin-size;
                color: lighten(@color-side-bg, 40%);
                transition: color 200ms;

                &.router-link-active {
                    color: lighten(@color-side-bg, 50%);
                }

                &.router-link-exact-active {
                    color: lighten(@color-primary-dark, 40%);
                }
            }
        }
    }
</style>
