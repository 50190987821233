<template>
    <div class="start-create wrapper">
        <div class="title">
            <div class="word">موبەن ئۇچۇرى يوللاش</div>
            <div class="btn">
                <div class="btn-back" @click="toBack">
                    <i class="iconfont icon-arrow-down"></i>
                </div>
            </div>
        </div>
        <div class="form">
            <div class="row">
                <div class="col col-1" >
                    <label>{{thing1}}</label>
                    <label>
                        <input class="input" type="text" placeholder="كىرگۈزۈڭ"
                               v-model="thing1Value">
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col col-1" >
                    <label>{{thing2}}</label>
                    <label>
                        <input class="input" type="text" placeholder="كىرگۈزۈڭ"
                               v-model="thing2Value">
                    </label>
                </div>
            </div>
            <div class="row" v-if="thing3 != null">
                <div class="col col-1" >
                    <label>{{thing3}}</label>
                    <label>
                        <input class="input" type="text" placeholder="كىرگۈزۈڭ"
                               v-model="thing3Value">
                    </label>
                </div>
            </div>
            <div class="row" v-if="thing4 != null">
                <div class="col col-1" >
                    <label>{{thing4}}</label>
                    <label>
                        <input class="input" type="text" placeholder="كىرگۈزۈڭ"
                               v-model="thing4Value">
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>ئۇلانماقچى بولغان ئەسەر نامىنى كىرگۈزۈپ تاللاڭ</label>
                    <label>
                        <article-search class="input" @articleId="getArticleId"/>
                    </label>
                </div>
            </div>
            <div class="card">
                <div class="top">
                    <div class="image">
                        <img src="https://wx.qlogo.cn/mmhead/Q3auHgzwzM4anWCzpUn6wpftzULmXufdkibJSKB4uqGr7PbdJVrp0Tw/0" alt="">
                    </div>
                    <div class="name">
                        Qiz丷ziQ
                    </div>
                </div>
                <div class="card-container">
                    <div class="temp-name">{{title}}</div>
                    <div class="row">
                        <label>{{thing1}}</label>
                        <label class="label-value">
                            {{thing1Value}}
                        </label>
                    </div>
                    <div class="row">
                        <label>{{thing2}}</label>
                        <label class="label-value">
                            {{thing2Value}}
                        </label>
                    </div>
                    <div class="row" v-if="thing3 != null">
                        <label>{{thing3}}</label>
                        <label class="label-value">
                            {{thing3Value}}
                        </label>
                    </div>
                    <div class="row" v-if="thing4 != null">
                        <label>{{thing4}}</label>
                        <label class="label-value">
                            {{thing4Value}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="btn">
                <div class="btn-save" @click="sendTemplate">ساقلاش</div>
                <!-- <div class="btn-back" @click="toBack"> بىكار قىلىش </div> -->
            </div>
        </div>

    </div>
    <toast ref="toast" position="center" type="danger"/>
    <confirm-box ref="confirmBox" :content="confirmBoxContent" @confirm="send"/>
</template>

<script>
    import Toast from "../../components/Toast";
    import ArticleSearch from "../../components/ArticleSearch";
    import {getDetail, send} from "../../api/template";
    import ConfirmBox from "../../components/ConfirmBox";

    let self
    let requesting = false
    export default {
        name: "TemplateMessageEdit",
        emits: ['showLoading'],
        components: {Toast, ArticleSearch,ConfirmBox},
        created() {
            self = this
            self.templateId = self.$route.params.template_id
            self.loadDetail()
        },
        data() {
            return {
                templateId: "",
                title:'',
                thing1: '',
                thing2: '',
                thing3: '',
                thing4: '',
                thing1Value: '',
                thing2Value: '',
                thing3Value: '',
                thing4Value: '',
                articleId: 0,
                confirmBoxContent:""
            }
        },
        methods: {
            toBack() {
                self.$router.back()
            },
            getArticleId(e) {
                self.articleId = e
                console.log(e)
            },
            sendTemplate(){
                self.confirmBoxContent = 'يوللاشنى جەزىملەمسىز؟'
                self.$refs.confirmBox.show()
            },
            send() {
                self.$refs.confirmBox.cancel()
                self.execute()
            },
            execute(){
                if (requesting) return;
                if (!self.validate()) return
                let data = {
                    template_id: self.templateId,
                    article_id: self.articleId,
                    thing1: self.thing1Value,
                    thing2: self.thing2Value,
                    thing3: self.thing3Value,
                    thing4: self.thing4Value,

                }

                requesting = true

                send(data).then(res => {
                    self.$refs.toast.show(res, 'success')
                    setTimeout(() => {
                        self.toBack()
                        requesting = false
                    }, 1000)
                })
            },
            validate() {
                if (self.thing1Value === '' || self.thing2Value === '') {
                    self.$refs.toast.show('تولۇق توشقۇزۇڭ')
                    self.$refs.title.focus()
                    return false
                }
                if (self.thing3 != null && self.thing3Value === ''){
                    self.$refs.toast.show('تولۇق توشقۇزۇڭ')
                    self.$refs.title.focus()
                    return false
                }
                if (self.thing4 != null && self.thing4Value === ''){
                    self.$refs.toast.show('تولۇق توشقۇزۇڭ')
                    self.$refs.title.focus()
                    return false
                }
                return true;
            },
            loadDetail() {
                getDetail({
                    template_id:self.templateId
                }).then(res => {
                    self.orgData = res
                    console.log(res)
                    self.fillData()
                })

            },
            fillData() {
                self.title = self.orgData.name
                self.thing1 = self.orgData.thing1
                self.thing2 = self.orgData.thing2
                self.thing3 = self.orgData.thing3
                self.thing4 = self.orgData.thing4
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    .start-create {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;
        @title-height: 60px;
        @inputHeight: 36px;

        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-back {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transform: rotate(90deg);
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .btn-save {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .form {
            padding: @margin-size;
            position: relative;
            .row {
                display: flex;
                margin-bottom: @margin-size-xl;
                width: 60%;

                .col {
                    flex: 1;

                    &.col-1 {
                        flex: 1;
                    }


                    label {
                        display: block;
                        margin-bottom: @margin-size;

                        .input {
                            width: 60%;
                        }
                    }

                    input.input {
                        width: 74%;
                        height: @inputHeight;
                        border-radius: @inputHeight / 2;
                        padding: 0 @margin-size;
                        border: fade(@color-primary-dark, 20%) 1px solid;
                        box-sizing: border-box;
                        margin-left: @margin-size;

                        &:focus {
                            border-color: fade(@color-primary-dark, 40%)
                        }

                        &.mn {
                            width: 30%;
                        }

                        &.sm {
                            width: 60%;
                        }

                        &.btn {
                            cursor: pointer;
                        }

                        &.ltr {
                            direction: ltr;
                        }
                    }
                }
            }

            .card{
                position: absolute;
                top: 32px;
                left: 32px;
                width: 400px;
                padding: 32px;
                border: rgba(85, 85, 85, 0.1) 1px solid;
                border-radius: 12px;
                direction: ltr;
                .top{
                    display: flex;
                    padding-bottom: 32px;
                    border-bottom: rgba(85, 85, 85, 0.1) 1px solid;
                    .image{
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        img{
                            height: 100%;
                            width: 100%;
                        }

                    }
                    .name{
                        margin-left: 32px;
                        line-height: 60px;
                    }
                }
                .card-container{
                    padding-top: 24px;
                    .temp-name{
                        font-weight: bold;
                        margin-bottom: 24px;
                    }
                    .row{
                        width: 100%;
                        .label-value{
                            margin-left:32px;
                            flex: 1;
                        }
                    }
                }
            }
             .btn {
                align-items: center;
                display: flex;
                margin-top: 30px;

                .btn-save {
                    height: 40px;
                    line-height: 40px;
                    width: 50px;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;
                      background: @color-primary-dark;
                        color: white;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }

                .btn-back{
                   height: 40px;
                    line-height: 40px;
                    width: 70px;
                    background: fade(@color-accent, 6%);
                    color: @color-accent;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;
                    background-color: #fd397a;
                      border-color: #fd397a;
                      color: #fff;

                    &:hover {
                      background-color: #fd397a;
                      border-color: #fd397a;
                      color: #fff;
                    } 
                }
            }
        }
    }
</style>
