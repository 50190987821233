import request from "../utils/Request";

export function getList(obj) {
    return request({
        // url: '/api/admin/term-list',
        url: 'admin/term-list',
        method: 'get',
        params: obj
    })
}
export function getDetail(id) {
    return request({
        // url: '/api/admin/term-detail',
        url: 'admin/term-detail',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function update(obj) {
    return request({
        // url: '/api/admin/term-update',
        url: 'admin/term-update',
        method: 'post',
        data: obj,
    })
}
export function create(obj) {
    return request({
        // url: '/api/admin/term-add',
        url: 'admin/term-add',
        method: 'post',
        data: obj,
    })
}