<template>
    <div class="multi-selector">
        <div class="selected-list" @click="openBox">
            <div class="place-holder" v-if="selectedList.length === 0">{{placeholder}}</div>
            <div class="item" v-for="(item,index) in selectedList" :key="index">{{item}}</div>
             
        </div>
        <div class="more">
            <i class="iconfont icon-zengjia"></i>
        </div>
        <div class="mask" v-if="boxShown" @click="closeBox"></div>
        <div class="box" v-if="boxShown">
            <div class="title">{{title}}</div>
            <div class="list">
                <div class="item" :class="{'selected': indexPos(index) > -1}" v-for="(item,index) in items" :key="index" @click="toggleThis(index)">{{item}}</div>
                <div class="select">
                    <div class="btn-rigth" @click="closeBox">چىكىنىش</div>
                    <div class="btn-left" @click="closeBox">جەزىملەشتۈرۈش</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MultiSelector",
        emits:['categorySelected'],
        props: {
            items: {
                type: Array,
                default: () => {
                    return []
                }
            },
            placeholder: {
                type: String,
                default: 'تاللاڭ'
            },
            title: {
                type: String,
                default: 'كۆپ تاللاڭ'
            }
        },
        data() {
            return {
                boxShown: false,
                selectedIndexes: []
            }
        },
        computed: {
            selectedList() {
                let ret = []
                for (let i = 0; i < this.selectedIndexes.length; i++) {
                    ret.push(this.items[this.selectedIndexes[i]])
                }
                return ret
            }
        },
        methods: {
            openBox() {
                this.boxShown = true
            },
            closeBox() {
                this.boxShown = false
            },
            toggleThis(index) {
                let indexPos = this.indexPos(index)
                if (indexPos > -1) {
                    this.selectedIndexes.splice(indexPos, 1)
                } else {
                    this.selectedIndexes.push(index)
                }
                this.$emit('categorySelected',this.selectedIndexes)
            },
            indexPos(index) {
                let ret = -1
                for (let i = 0; i < this.selectedIndexes.length; i++) {
                    if (this.selectedIndexes[i] === index) {
                        ret = i
                    }
                }
                return ret
            },
            setSelectedIndexes(indexes){
                this.selectedIndexes = indexes
                this.$emit('categorySelected',this.selectedIndexes)
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../assets/less/global.less";

    @inputHeight: 36px;
    .multi-selector {
        user-select: none;
        box-sizing: border-box;
        height: @inputHeight;
        line-height: @inputHeight;
        border-radius: @inputHeight / 2;
        background: @color-nice-bg;
        display: flex;
        position: relative;

        .place-holder{
            margin: 0 @margin-size;
        }

        .selected-list {
            flex: 1;
            display: flex;
            padding: 0 @margin-size-mini;
            .item{
                height: @inputHeight - 8;
                line-height: @inputHeight - 8;
                background: @color-primary-dark;
                margin: @margin-size-mini;
                padding: 0 @margin-size;
                border-radius: (@inputHeight - 8) / 2;
                color: white;
                &:first-child{
                    margin-inline-start: 0;
                }
            }
        }

        .more {
            position: absolute;
            left: 0;
            top: 0;
            width: @inputHeight;
            height: @inputHeight;
            line-height: @inputHeight;
            text-align: center;
        }

        .mask {
            position: fixed;
            z-index: 999;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: fade(@color-nice-black, 40%);
        }

        .box {
            display: flex;
            flex-direction: column;
            position: fixed;
            z-index: 1000;
            width: 720px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -80%);
            min-height: 400px;
            background: white;
            border-radius: @radius-size;
            overflow: hidden;

            .title {
                @title-height: 56px;
                height: @title-height;
                line-height: @title-height;
                text-align: center;
            }

            .list {
                display: flex;
                flex-wrap: wrap;
                padding: @margin-size;

                .item {
                    padding: 0 @margin-size;
                    margin: @margin-size-small;
                    border-radius: @radius-size;
                    background: fade(@color-primary-dark, 20%);
                    color: @color-primary-dark;
                    box-shadow: fade(@color-nice-black, 10%) 0 @margin-size-mini @margin-size;
                    cursor: pointer;

                    &.selected {
                        background: @color-primary-dark;
                        color: white;
                    }
                }

                .select{
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 52px;
                    line-height: 52px;
                    display: flex;
                    border-top: rgba(103, 58, 183, 0.04) 1px solid;

                    .btn-left{
                     flex: 1;
                    text-align: center;
                    color: #5867dd;
                      
                         cursor: pointer;

                     
                    }
                    
                    .btn-rigth{
                          -webkit-border-end: rgba(103, 58, 183, 0.04) 1px solid;
                        border-inline-end: rgba(103, 58, 183, 0.04) 1px solid;
                    color: rgba(88, 103, 221, 0.618);
                        flex: 1;
                       text-align: center;
                       cursor: pointer;
                    }
                }
            }
        }
    }
</style>