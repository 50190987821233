<template>
    <div class="adv-create wrapper">
        <div class="title">
            <div class="word">ئەسەر تەھرىرلەش</div>
            <!--<div class="btn" v-show="ToCopyStatus">-->
                <!--&lt;!&ndash;<div class="btn-add" @click="toCopy"> توپلام ئادرېسنى كۆچۈرۈش</div>&ndash;&gt;-->
            <!--</div>-->
        </div>
        <div class="form">
            <div class="row">
                <div class="col">
                    <label>ھالىتى</label>
                    <Switch :status="status" @update="updateStatus"/>
                </div>
                <div class="col">
                    <label>تەۋسىيە ھالىتى</label>
                    <Switch :status="recommendStatus" @update="updateRecommendStatus"/>
                </div>
                <div class="col">
                    <label>يوشۇرامسىز؟</label>
                    <Switch
                            :status="hiddenStatus"
                            @update="updateHiddenStatus"
                            positive-text="ھەئە"
                            negative-text="ياق"
                    />
                </div>
                <div class="col">
                    <label>باش بەتتە كۆرۈنەمدۇ؟ </label>
                    <Switch
                            :status="homePageRecommendStatus"
                            @update="updateHomePageRecommendStatus"
                            positive-text="ھەئە"
                            negative-text="ياق"
                    />
                </div>
                <div class="col">
                    <label>كۆپ قىسىملىقمۇ؟</label>
                    <Switch
                            :status="isSeries"
                            @update="updateSeriesStatus"
                            positive-text="ھەئە"
                            negative-text="ياق"
                    />
                </div>
                <div class="col">
                    <label>خەتلىكمۇ ئاۋازلىقمۇ؟</label>
                    <Switch
                            :status="isType"
                            @update="updateTypeStatus"
                            positive-text="خەتلىك"
                            negative-text="ئاۋازلىق"
                    />
                </div>

            </div>
            <div class="row">
                <div class="col col-1">
                    <label>ئەسەر نامى</label>
                    <label>
                        <input
                                class="input"
                                type="text"
                                placeholder="ئەسەر نامىنى كىرگۈزۈڭ"
                                v-model="name"
                                ref="title"
                                @change="nameSetModified"
                        />
                    </label>
                </div>
                <div class="col">
                    <label>
                        <span>ئەسەر تەرتىبى</span>
                        <helper content="قۇرۇق قويسىڭىزمۇ بولىدۇ"/>
                    </label>
                    <label>
                        <input
                                class="input"
                                type="text"
                                placeholder="ئەسەر تەرتىبىنى كىرگۈزۈڭ"
                                v-model="sort"
                                ref="sort"
                                @change="sortSetModified"
                        />
                    </label>
                </div>

            </div>

            <div class="row">
                <div class="col col-2">
                    <label>ئەسەر تەۋە تۈرلەر</label>
                    <multi-selector
                            class="multi-selector"
                            title="ئ‍ۇشبۇ ئەسەر تەۋە بولغان تۈرلەرنى تاللاڭ"
                            ref="categorySelector"
                            :items="categories"
                            @categorySelected="categorySelected"
                    />
                </div>
                <div class="col">
                    <label>توپلام باھادا سىتىۋالالامدۇ؟</label>
                    <label>
                        <Switch
                                :status="isAllSale"
                                @update="updateIsAllSale"
                                positive-text="ھەئە"
                                negative-text="ياق"
                        />
                    </label>
                </div>
                <div class="col" v-show="isAllSale">
                    <div>
                        <label>توپلام باھاسى</label>
                        <label>
                            <input
                                    class="input"
                                    type="number"
                                    step="1"
                                    maxlength="2"
                                    max="99"
                                    placeholder="ئەسەر توپلام باھاسىنى كىرگۈزۈڭ"
                                    v-model="price"
                                    ref="sort"
                            />
                        </label>
                    </div>
                </div>
                <div class="col" v-show="isAllSale">
                    <label>ئالىي ئەزالارمۇ توپلام سىتىۋالامدۇ؟</label>
                    <label>
                        <Switch
                                :status="isVipPurchase"
                                @update="updateIsVipPurchase"
                                positive-text="ھەئە"
                                negative-text="ياق"
                        />
                    </label>
                </div>
                <div class="col" v-show="isVipPurchase">
                    <div>
                        <label>ئالىي ئەزالارنىڭ توپلام باھاسى</label>
                        <label>
                            <input
                                    class="input"
                                    type="number"
                                    step="1"
                                    maxlength="2"
                                    max="99"
                                    placeholder="ئەسەر ئالىي ئەزالارنىڭ توپلام باھاسىنى كىرگۈزۈڭ"
                                    v-model="vip_price"
                                    ref="sort"
                            />
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>
                        <span>ئەسەر مۇقاۋىسى</span>
                        <Helper content="ھۆججەت چوڭلۇقى 300Kb ئىچىدە بولسۇن"/>
                    </label>
                    <div
                            class="upload-placeholder"
                            @click="pickCover"
                            v-if="coverInfo === null"
                    >
                        <i class="iconfont icon-tupian"></i>
                    </div>
                    <img
                            class="image-viewer"
                            @click="pickCover"
                            :src="coverInfo.url"
                            v-if="coverInfo != null"
                    />
                    <input
                            type="file"
                            accept="image/*"
                            ref="coverInput"
                            @change="coverInputAtChange"
                    />
                </div>
                <div class="col col-2">
                    <label>
                        <span>ئەسەر تەشۋىقات رەسىمى</span>
                        <Helper content="كەڭلىك 648 پېكسىل ئېگىزلىك 518 پېكسىل"/>
                    </label>
                    <div
                            class="upload-placeholder large"
                            @click="pickImage"
                            v-if="imageInfo === null"
                    >
                        <i class="iconfont icon-tupian"></i>
                    </div>
                    <img
                            class="image-viewer large"
                            @click="pickImage"
                            :src="imageInfo.url"
                            v-if="imageInfo != null"
                    />
                    <input
                            type="file"
                            accept="image/*"
                            ref="imageInput"
                            @change="imageInputAtChange"
                    />
                </div>

                <div class="col col-3">
                    <label>قىسقىچە چۈشەندۈرۈلۈش</label>
                    <label>
        <textarea
                class="pretty-textarea"
                rows="12"
                v-model="description"
                @change="textSetModified"
        ></textarea>
                    </label>
                </div>
            </div>


            <div class="btn">
                <div class="btn-save" @click="add">ساقلاش</div>
                <div class="btn-back" @click="toBack">بىكار قىلىش</div>
            </div>
        </div>
    </div>

    <confirm-box
            ref="confirmBox"
            :content="confirmBoxContent"
            @confirm="cancel"
    />
    <toast ref="toast" position="center" type="danger"/>
</template>

<script>
    import Switch from "../../components/Switch";
    import Helper from "../../components/Helper";

    import {upload} from "../../api/common";
    import Toast from "../../components/Toast";
    import MultiSelector from "../../components/MultiSelector";
    import {getOptions, getDetail, update} from "../../api/article";

    import ConfirmBox from "../../components/ConfirmBox";

    let self;
    let requesting = false;
    let modified = false;
    export default {
        name: "ArticleEdit",
        emits: ["showLoading"],
        components: {
            MultiSelector,
            Toast,
            Helper,
            Switch,
            ConfirmBox,
        },
        created() {
            self = this;
            self.id = Number(self.$route.params.id);
            self.getOptions();
        },
        data() {
            return {
                id: 0,
                status: true,
                recommendStatus: false,
                hiddenStatus: false,
                homePageRecommendStatus: true,
                isSeries: true,
                imageInfo: null,
                coverInfo: null,
                name: "",
                sort: "",
                categories: [],
                description: "",
                orgOptions: null,
                selectedCategoryIndexes: [],
                agreementModalShow: false,
                confirmBoxContent: "",
                isType: false,
                isAllSale: false,
                isVipPurchase: false,
                price: "",
                vip_price: ""
            };
        },
        computed: {},
        methods: {
            nameSetModified() {
                modified = true;
            },

            sortSetModified() {
                modified = true;
            },

            textSetModified() {
                modified = true;
            },

            toBack() {
                console.log(modified);
                if (modified) {
                    self.confirmBoxContent = "توشقۇزغان ئۇچۇرلارنى راستىنلا بىكا قىلامسىز؟";
                    self.$refs.confirmBox.show();
                    return;
                }
                self.$router.back();
            },
            cancel() {
                self.$router.back();
                modified = false;
            },

            loadDetail() {
                getDetail(self.id).then((res) => {
                    console.log(res);
                    self.orgData = res;
                    self.fillData();
                });
            },

            fillData() {
                self.status = self.orgData.status === 1;
                self.recommendStatus = self.orgData.is_recommend === 1;
                self.hiddenStatus = self.orgData.is_hide === 1;
                self.homePageRecommendStatus = self.orgData.is_category_recommend === 1;
                self.isSeries = self.orgData.is_series === 1;
                self.isType = self.orgData.type === 1;
                self.isAllSale = self.orgData.all_sale === 1;
                self.isVipPurchase = self.orgData.is_vip_purchase === 1;
                self.price = self.orgData.price;
                self.vip_price = self.orgData.vip_price;

                self.name = self.orgData.name;

                self.sort = self.orgData.sort;

                let selectedCategoryIndexes = [];
                for (let i = 0; i < self.orgData.category.length; i++) {
                    for (let j = 0; j < self.orgOptions.category.length; j++) {
                        if (
                            self.orgData.category[i].category_id ===
                            self.orgOptions.category[j].id
                        ) {
                            selectedCategoryIndexes.push(j);
                        }
                    }
                }
                self.$refs.categorySelector.setSelectedIndexes(selectedCategoryIndexes);
                self.coverInfo = {
                    url: self.orgData.coverUrl,
                    path: self.orgData.cover,
                };
                self.imageInfo = {
                    url: self.orgData.share_coverUrl,
                    path: self.orgData.share_cover,
                };
                self.description = self.orgData.description;


            },
            getOptions() {
                getOptions().then((res) => {
                    self.orgOptions = res;
                    self.pairOptions();
                    self.loadDetail();
                });
            },
            pairOptions() {
                console.log(self.orgOptions.source);
                self.orgOptions.category.forEach((category) =>
                    self.categories.push(category.name)
                );

            },

            categorySelected(e) {
                self.selectedCategoryIndexes = e;
            },

            updateStatus() {
                modified = true;
                self.status = !self.status;
            },
            updateRecommendStatus() {
                modified = true;
                self.recommendStatus = !self.recommendStatus;
            },
            updateHiddenStatus() {
                modified = true;
                self.hiddenStatus = !self.hiddenStatus;
            },
            updateSeriesStatus() {
                modified = true;
                self.isSeries = !self.isSeries;
            },
            updateTypeStatus() {
                modified = true;
                self.isType = !self.isType;
            },

            updateHomePageRecommendStatus() {
                modified = true;
                self.homePageRecommendStatus = !self.homePageRecommendStatus;
            },
            updateIsAllSale(){
                modified = true;
                self.isAllSale = !self.isAllSale
                if (self.isAllSale == false){
                    self.isVipPurchase = false;
                }
            },
            updateIsVipPurchase(){
                modified = true;
                self.isVipPurchase = !self.isVipPurchase
            },
            pickImage() {
                self.$refs.imageInput.click();
            },
            imageInputAtChange(e) {
                modified = true;
                if (e.target.files.length !== 1) {
                    return;
                }
                let formData = new FormData();
                formData.append("image", e.target.files[0]);
                formData.append("type", "article_image");
                upload(formData).then((res) => {
                    if (res.msg) {
                        self.$refs.toast.show(res.msg);
                        return;
                    }
                    self.imageInfo = res;
                });
            },

            pickCover() {
                self.$refs.coverInput.click();
            },
            coverInputAtChange(e) {
                console.log("25");
                modified = true;
                if (e.target.files.length !== 1) {
                    return;
                }
                let formData = new FormData();
                formData.append("image", e.target.files[0]);
                formData.append("type", "article_cover");
                upload(formData).then((res) => {
                    if (res.msg) {
                        self.$refs.toast.show(res.msg);
                        return;
                    }
                    self.coverInfo = res;
                });
            },
            add() {
                console.log(requesting);
                if (requesting) return;
                console.log(self.validate());
                if (!self.validate()) return;
                let categories = [];
                for (let i = 0; i < self.selectedCategoryIndexes.length; i++) {
                    categories.push({
                        id: self.orgOptions.category[self.selectedCategoryIndexes[i]].id,
                        name: self.orgOptions.category[self.selectedCategoryIndexes[i]].name,
                    });
                }

                let data = {
                    id: self.id,
                    status: self.status ? 1 : 0,
                    hide: self.hiddenStatus ? 1 : 0,
                    category_recommend: self.homePageRecommendStatus ? 1 : 0,

                    series: self.isSeries ? 1 : 0,
                    type: self.isType ? 1 : 0,
                    recommend: self.recommendStatus ? 1 : 0,

                    sort: self.sort === "" ? 1 : Number(self.sort),

                    name: self.name,
                    share_cover: self.imageInfo.path,
                    cover: self.coverInfo.path,
                    description: self.description,

                    category: categories,
                    all_sale:self.isAllSale ? 1 : 0,
                    is_vip_purchase:self.isVipPurchase ? 1 : 0,
                    price: self.price === "" ? 0 : Number(self.price),
                    vip_price:self.vip_price === "" ? 0 : Number(self.vip_price)

                };

                requesting = true;
                update(data).then((res) => {
                    self.$refs.toast.show(res, "success");
                    setTimeout(() => {
                        self.$router.back();
                        requesting = false;
                    }, 1000);
                });
                setTimeout(() => {
                    requesting = false;
                }, 2000);
            },
            validate() {
                if (self.name === "") {
                    self.$refs.toast.show("ئەسەر نامىنى تولدۇرۇڭ");
                    self.$refs.title.focus();
                    return false;
                }
                if (self.isAllSale){
                    let  price = Number(self.price)
                    if (price === 0){
                        self.$refs.toast.show("ئەسەر توپلام باھاسىنى كىرگۈزۈڭ ");
                        return false;
                    }
                }
                if (self.isVipPurchase){
                    let  vip_price = Number(self.vip_price)
                    if (vip_price === 0){
                        self.$refs.toast.show("ئەسەر توپلام باھاسىنى كىرگۈزۈڭ ");
                        return false;
                    }
                }
                if (self.selectedCategoryIndexes.length === 0) {
                    self.$refs.toast.show("ئەسەر تۈرىنى تاللاڭ");
                    return false;
                }

                if (self.imageInfo === null) {
                    self.$refs.toast.show("ئەسەر تەشۋىقات رەسىمىنى يوللاڭ");
                    return false;
                }
                if (self.coverInfo === null) {
                    self.$refs.toast.show("ئەسەر مۇقاۋىسىنى يوللاڭ");
                    return false;
                }
                return true;
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    .adv-create {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;
        @title-height: 60px;
        @inputHeight: 36px;

        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-back {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: white;
                    background: fade(#12cc94, 90%);
                    cursor: pointer;
                    transform: rotate(90deg);
                    transition: background-color 200ms;

                    &:hover {
                        background: #0abb87;
                        color: white;
                    }
                }

                .btn-add {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }

                .btn-save {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .form {
            padding: @margin-size;

            .row {
                display: flex;
                margin-bottom: @margin-size-xl;

                .col {
                    flex: 1;

                    &.col-1 {
                        flex: 1;
                    }

                    &.col-2 {
                        flex: 2;
                    }

                    &.col-3 {
                        flex: 3;
                    }

                    label {
                        display: block;
                        position: relative;
                        margin-bottom: @margin-size;

                        .range-screen {
                            position: absolute;
                            left: @margin-size-xxl;
                            width: @inputHeight * 2;
                            height: @inputHeight;
                            line-height: @inputHeight;
                            text-align: center;
                            border-radius: @inputHeight / 2;
                            background: @color-primary-dark;
                            color: white;
                        }
                    }

                    .picker,
                    .select-box {
                        width: 74%;
                    }

                    .multi-selector {
                        width: 86%;
                    }

                    .pretty-textarea {
                        width: 80%;
                        max-width: 80%;
                        min-width: 80%;
                        box-sizing: border-box;
                        padding: @margin-size;
                        background: @color-nice-bg;
                        border-radius: @radius-size;
                        border: none;
                    }

                    .list {
                        .serech {
                            height: @title-height;
                            padding: 0 @margin-size;
                            line-height: @title-height;
                            padding-top: @margin-size;
                            border: 1px solid @color-primary-dark;
                        }

                        .srech-item {
                            display: flex;
                            height: @title-height / 1.3;
                            line-height: @title-height / 1.3;
                        }

                        .srech-word {
                            background: fade(#12cc94, 90%);
                            width: @top-bar-height * 2;
                            text-align: center;
                            color: white;
                            cursor: pointer;
                            font-weight: normal;
                            transition: background-color 200ms, color 200ms;

                            &:hover {
                                background: #0abb87;
                                color: white;
                            }
                        }

                        .input-error {
                            width: 100%;
                            text-align: left;
                            padding-left: @font-size-small;
                            direction: ltr;
                            border: 1px solid #0abb87;
                            border-radius: @radius-size 0 0 @radius-size;

                            .input-placeholder {
                                width: 90%;
                                border: none;
                            }

                            .icon {
                                border: none;
                                color: #0abb87;
                            }

                            .errorIcon {
                                border: none;
                                color: #fd5992;
                            }
                        }

                        .input {
                            width: 100%;
                            text-align: left;
                            padding-left: @font-size-small;
                            direction: ltr;
                            border: 1px solid #0abb87;
                            border-radius: @radius-size 0 0 @radius-size;

                            .input-placeholder {
                                width: 90%;
                                border: none;
                            }

                            .icon {
                                border: none;
                                color: #0abb87;
                            }

                            .errorIcon {
                                border: none;
                                color: #fd5992;
                            }
                        }

                        .input-error {
                            width: 100%;
                            text-align: left;
                            padding-left: @font-size-small;
                            direction: ltr;
                            border: 1px solid #fd5992;
                            border-radius: @radius-size 0 0 @radius-size;
                        }

                        .remind-text {
                            text-align: left;
                            color: #fd5992;
                            padding-top: @margin-size-mini;
                        }

                        .result {
                            display: flex;
                            border: 1px solid #0abb87;
                            margin-top: @radius-size-large;
                            border-radius: @radius-size 0 0 @radius-size;
                            min-height: @title-height / 1.3;
                            vertical-align: middle;

                            .salon-number {
                                width: @top-bar-height * 2.1;
                                background: fade(@color-nice-black, 10%);
                                text-align: center;
                                vertical-align: middle;
                                display: flex;

                                .salon-info {
                                    align-self: center;
                                    margin: 0 auto;
                                }
                            }

                            .content-list {
                                flex: 1;
                                text-align: left;
                                display: flex;
                                justify-content: flex-end;
                                padding-left: @margin-size-small;

                                .content {
                                    .content-item {
                                        margin: 0;
                                        padding-top: @margin-size-small;
                                    }
                                }

                                :last-child {
                                    padding-bottom: @margin-size-small;
                                }

                                .icon {
                                    padding-top: @margin-size-small;
                                    padding-right: @margin-size-small;
                                    color: #0abb87;
                                }
                            }

                            .copy {
                                width: @margin-size-xl * 4;
                                background: fade(#12cc94, 90%);
                                text-align: center;
                                color: white;
                                display: flex;
                                border-radius: @radius-size-small 0 0 @radius-size-small;

                                .copy-word {
                                    align-self: center;
                                    margin: 0 auto;
                                }

                                &:hover {
                                    background: #0abb87;
                                    color: white;
                                    border-radius: @radius-size-small 0 0 @radius-size-small;
                                }
                            }
                        }
                    }

                    input.input {
                        width: 74%;
                        height: @inputHeight;
                        border-radius: @inputHeight / 2;
                        padding: 0 @margin-size;
                        border: fade(@color-primary-dark, 20%) 1px solid;
                        box-sizing: border-box;
                        margin-left: @margin-size;

                        &:focus {
                            border-color: fade(@color-primary-dark, 40%);
                        }

                        &.mn {
                            width: 30%;
                        }

                        &.sm {
                            width: 60%;
                        }

                        &.btn {
                            cursor: pointer;
                        }

                        &.ltr {
                            direction: ltr;
                        }
                    }

                    input[type="file"] {
                        display: none;
                    }

                    input[type="range"] {
                        appearance: none;
                        height: @inputHeight;
                        background-color: @color-nice-bg;
                        border-radius: @inputHeight / 2;
                        width: 74%;
                        border: none;

                        &::-webkit-slider-thumb {
                            appearance: none;
                            width: @inputHeight - 4;
                            height: @inputHeight - 4;
                            border-radius: (@inputHeight - 4) / 2;
                            background: @color-primary-dark;
                            cursor: pointer;
                        }

                        &.small {
                            width: 60%;
                        }
                    }

                    @ph-width: 180px;
                    @ph-height: 240px;

                    .upload-placeholder {
                        width: @ph-width;
                        height: @ph-height;
                        line-height: @ph-height;
                        text-align: center;
                        box-sizing: border-box;
                        border-radius: @radius-size;
                        border: fade(@color-primary-dark, 40%) 1px dashed;
                        cursor: pointer;

                        &.large {
                            width: @ph-width * 2;
                        }

                        i {
                            font-size: @font-size-xxxl;
                            color: fade(@color-primary-dark, 40%);
                        }
                    }

                    .image-viewer {
                        height: @ph-height;
                        width: @ph-width;

                        &.large {
                            width: @ph-width * 2;
                        }
                    }
                }
            }

            .btn {
                align-items: center;
                display: flex;
                margin-top: 30px;

                .btn-save {
                    height: 40px;
                    line-height: 40px;
                    width: 50px;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;
                    background: @color-primary-dark;
                    color: white;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }

                .btn-back {
                    height: 40px;
                    line-height: 40px;
                    width: 70px;
                    background: fade(@color-accent, 6%);
                    color: @color-accent;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;
                    background-color: #fd397a;
                    border-color: #fd397a;
                    color: #fff;

                    &:hover {
                        background-color: #fd397a;
                        border-color: #fd397a;
                        color: #fff;
                    }
                }
            }
        }
    }

</style>