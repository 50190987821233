<template>
  <div class="start-list wrapper">
    <div class="title">
      <div class="word">ئۇچۇر يوللاش خاتىرىسى</div>
      <div class="btn">
        <div class="btn-refresh" @click="loadList">
          <i class="iconfont icon-shuaxin"></i>
        </div>
      </div>
      <div class="btn">
        <div class="btn-add" @click="toBack">چىكىنىش</div>
      </div>
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in templateInfo" :key="index">
        <div class="titel">
          <div class="titel-name">{{ item.template.name }}</div>
        </div>
        <div class="content">
          <div class="row">
            <div class="col val opr">{{ item.thing1 }}</div>
            <div class="col key text">{{ item.template.thing1 }}</div>
          </div>
          <div class="row">
            <div class="col val opr">{{ item.thing2 }}</div>
            <div class="col key text">{{ item.template.thing2 }}</div>
          </div>
          <div class="row">
            <div class="col val opr">{{ item.thing3 }}</div>
            <div class="col key text">{{ item.template.thing3 }}</div>
          </div>
          <div class="row" v-if="item.thing4 != null">
            <div class="col val opr">{{ item.thing4 }}</div>
            <div class="col key text">{{ item.template.thing4 }}</div>
          </div>
          <div class="row">
            <div class="col key">ئۇچۇر تاپشۇرۇپ ئالىدىغان ئ‍ەزا</div>
            <div class="col val">{{ item.user_count }}نەپەر</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <toast ref="toast" position="center" type="danger" />
</template>

<script>
import Toast from "../../components/Toast";
import { getSubscribe } from "../../api/template";

let self;
export default {
  name: "TampleteList",
  emits: ["showLoading"],
  components: {
    Toast,
  },
  created() {
    self = this;
    self.templateId = self.$route.params.template_id;
    self.getSubscribeMessageLog();
  },
  data() {
    return {
      templateInfo: [],
    };
  },
  methods: {
    toBack() {
      self.$router.back();
    },
    getSubscribeMessageLog() {
      getSubscribe(self.templateId).then((res) => {
        self.templateInfo = res;
        console.log(self.templateInfo);
      });
    },
    loadList() {
        self.getSubscribeMessageLog()
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/less/global.less";
@import "../../assets/less/anim.less";

@icon-size: 40px;
.start-list {
  direction: rtl;
  max-width: @wrap-width;
  min-width: @wrap-min-width;
  margin: @margin-size-large auto;
  border-radius: @radius-size;
  box-sizing: border-box;
  min-height: @wrap-min-height;
  @title-height: 70px;

  .title {
    @btn-size: 40px;
    box-sizing: border-box;
    font-weight: bold;
    background: white;
    border-radius: @radius-size;
    padding: 0 @margin-size;
    margin-bottom: @margin-size;
    border-bottom: fade(@color-nice-black, 10%) 1px solid;
    height: @title-height;
    line-height: @title-height;
    display: flex;

    .word {
      flex: 1;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn-refresh {
        width: @btn-size;
        height: @btn-size;
        line-height: @btn-size;
        text-align: center;
        border-radius: @radius-size;
        color: @color-primary;
        cursor: pointer;
        transition: background-color 200ms;

        &:hover {
          background: fade(@color-nice-black, 4%);
        }
      }

      .btn-add {
        height: @btn-size;
        line-height: @btn-size - 4;
        background: fade(@color-primary-dark, 6%);
        color: @color-primary-dark;
        cursor: pointer;
        font-weight: normal;
        padding: 0 @margin-size;
        border-radius: @radius-size;
        margin-inline-start: @margin-size;
        transition: background-color 200ms, color 200ms;

        &:hover {
          background: @color-primary-dark;
          color: white;
        }
      }
    }
  }

  .list {
    flex-wrap: wrap;
    align-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    display: flex;

    .item {
      width: 346px;
      box-sizing: border-box;
      border-radius: @radius-size;
      background: white;
      margin: @margin-size;

      &:nth-child(4n) {
        margin-left: 0;
      }

      &:nth-child(4n + 1) {
        margin-right: 0;
      }

      .titel {
        height: 50px;
        line-height: 50px;
        padding-left: @margin-size-xl;
        text-align: left;
        font-size: @font-size-large;
        border-bottom: 1px solid #ebedf2;
      }

      .content {
        padding: @margin-size;
        text-align: left;

        .row {
          display: flex;
          align-items: center;
          padding: @margin-size-mini 0;

          .col {
            flex: 1;
          }

          .key {
            flex: 1;
            text-align: right;
            color: #48465b;
            font-weight: 600;
          }
          .val {
            color: #74788d;
            font-weight: 400;
          }

          .text {
            text-align: left;
          }

          .opr {
            text-align: right;
          }
        }
      }

      .btn {
        display: flex;
        align-items: center;
        height: 90px;
        line-height: 90px;
        text-align: center;
        padding: 0 @margin-size-large;
        border-top: 1px solid #ebedf2;

        .btn-edit {
          flex: 1;
          text-align: center;
          background: lighten(@color-primary, 8%);
          color: white;
          height: @icon-size;
          line-height: @icon-size - 4px;
          border-radius: @radius-size;

          &:hover {
            cursor: pointer;
            background: lighten(@color-primary, 1%);
          }
        }

        .btn-history {
          flex: 1;
          text-align: center;
          background: lighten(#0abb87, 8%);
          border-color: #0abb87;
          color: #fff;
          height: @icon-size;
          line-height: @icon-size - 4px;
          border-radius: @radius-size;
          margin-right: @margin-size;

          &:hover {
            cursor: pointer;
            background: lighten(#0abb87, 1%);
          }
        }

        .btn-kong {
          flex: 1;
        }
      }
    }
  }
}
</style>
