<template>
    <div class="index wrapper">
        <div class="main">
            <div class="chart">
                <div class="title">
                    <div class="word">ھەپتە ئىچىدىكى ئەزا سىتاستىكىسى</div>
                    <div class="btn">
                        <div class="btn-refresh" @click="simulateLoading">
                            <i class="iconfont icon-shuaxin"></i>
                        </div>
                    </div>
                </div>
                <canvas ref="UserChart"/>
            </div>
            <div class="chart">
                <div class="title">
                    <div class="word">ھەپتە ئىچىدىكى يارىتىلغان قىممەت</div>
                    <div class="btn">
                        <div class="btn-refresh">
                            <i class="iconfont icon-shuaxin"></i>
                        </div>
                    </div>
                </div>
                <canvas ref="FinanceChart"/>
            </div>
        </div>
        <div class="latest-users">
            <div class="title">
                <div class="word">يېڭى ئەزالار</div>
                <div class="btn">
                    <div class="btn-refresh" @click="loadNewUsers">
                        <i class="iconfont icon-shuaxin"></i>
                    </div>
                </div>
            </div>
            <div class="list">

                <div class="item" v-for="(user,index) in newUsers" :key="index">
                    <div class="avatar">
                        <img :src="user.avatar" alt="avatar">
                    </div>
                    <div class="info">
                        <div class="nick-name">{{user.nick_name}}</div>
                        <div class="time">{{user.updated_at}}</div>
                    </div>
                    <div class="level">
                        <div class="level-tag">{{user.level_tag}}</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<style lang="less" scoped>
    @import "../assets/less/global.less";
    @import "../assets/less/anim.less";

    @latest-users-width: 400px;
    @title-height: 60px;
    .index {
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        min-height: @wrap-min-height;
        display: flex;
        direction: rtl;

        .main {
            box-sizing: border-box;
            flex: 1;
            display: flex;
            flex-direction: column;

            .chart {
                flex: 1;
                margin: @margin-size;
                border-radius: @radius-size;
                background: white;

                .title {
                    @btn-size: 40px;
                    box-sizing: border-box;
                    font-weight: bold;
                    padding: 0 @margin-size;
                    margin-bottom: @margin-size-large;
                    height: @title-height;
                    line-height: @title-height;
                    display: flex;

                    .word {
                        flex: 1;
                    }

                    .btn {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .btn-refresh {
                            width: @btn-size;
                            height: @btn-size;
                            line-height: @btn-size;
                            text-align: center;
                            border-radius: @radius-size;
                            color: @color-primary;
                            cursor: pointer;
                            transition: background-color 200ms;

                            &:hover {
                                background: fade(@color-nice-black, 4%);
                            }
                        }
                    }
                }
            }
        }

        .latest-users {
            width: @latest-users-width;
            border-radius: @radius-size;
            box-sizing: border-box;
            background: white;
            margin: @margin-size;

            .title {
                @btn-size: 40px;
                box-sizing: border-box;
                font-weight: bold;
                padding: 0 @margin-size;
                margin-bottom: @margin-size-large;
                border-bottom: fade(@color-nice-black, 10%) 1px solid;
                height: @title-height;
                line-height: @title-height;
                display: flex;

                .word {
                    flex: 1;
                }

                .btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .btn-refresh {
                        width: @btn-size;
                        height: @btn-size;
                        line-height: @btn-size;
                        text-align: center;
                        border-radius: @radius-size;
                        color: @color-primary;
                        cursor: pointer;
                        transition: background-color 200ms;

                        &:hover {
                            background: fade(@color-nice-black, 4%);
                        }
                    }
                }
            }

            .list {
                @avatar-size: 40px;
                margin-bottom: @margin-size;

                .item {
                    display: flex;
                    padding: @margin-size;
                    border-bottom: fade(@color-nice-black, 10%) 1px dashed;

                    &:last-child {
                        border: none;
                    }

                    .avatar {
                        width: @avatar-size;
                        height: @avatar-size;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: @radius-size;
                        }
                    }

                    .info {
                        flex: 1;
                        display: flex;
                        padding-inline-start: @margin-size;
                        flex-direction: column;
                        line-height: @avatar-size/2;

                        .nick-name {
                            font-weight: bold;
                        }

                        .time {
                            color: fade(@color-nice-black, 40%);
                        }
                    }

                    .level {
                        line-height: @avatar-size;

                        .level-tag {
                            display: inline-block;
                            height: @avatar-size/2;
                            line-height: @avatar-size/2;
                            padding: 0 @margin-size;
                            border-radius: @radius-size;
                            background: @color-primary;
                            color: white;
                        }
                    }
                }
            }
        }
    }
</style>
<script>
    import Chart from "chart.js";
    import {incomeChart, newUsers, usersChart} from "../api/common";

    let self
    export default {
        name: 'Index',
        emits: ['showLoading'],
        created() {
            self = this
            self.loadNewUsers()
            self.loadUsersChart()
            self.loadIncomeChart()
        },
        mounted() {
            self.initChart();
        },
        data() {
            return {
                newUsers: [],
                userChart: null,
                financeChart: null,
                userData: [],
                incomeData:[]
            }
        },
        computed: {
            userDataset() {
                let ret = [];
                if (self.userData.datasets === undefined) {
                    return ret;
                }
                self.userData.datasets.forEach(dataset => {
                    dataset['borderWidth'] = 2
                    dataset['cubicInterpolationMode'] = "monotone"
                    dataset['spanGaps'] = false
                    dataset['fill'] = true
                    ret.push(dataset)
                });
                return ret;
            },
            incomeDataset() {
                let ret = [];
                if (self.incomeData.datasets === undefined) {
                    return ret;
                }
                self.incomeData.datasets.forEach(dataset => {
                    dataset['borderWidth'] = 2
                    dataset['cubicInterpolationMode'] = "monotone"
                    dataset['spanGaps'] = false
                    dataset['fill'] = true
                    ret.push(dataset)
                });
                return ret;
            },
        },
        methods: {
            initChart() {
                self.userChart = new Chart(self.$refs.UserChart, {
                    type: "bar",
                    data: {
                        labels: self.userData.labels,
                        datasets: self.userDataset
                    },
                    options: {},
                });

                self.financeChart = new Chart(self.$refs.FinanceChart, {
                    type: "bar",
                    data: {
                        labels: self.incomeData.labels,
                        datasets: self.incomeDataset
                    },
                    options: {},
                });
            },
            loadUsersChart() {
                usersChart()
                    .then((res) => {
                        self.userData = res;
                        console.log(self.userData)
                        self.initChart();
                    })
                    .catch((err) => {
                        self.$refs.toast.show(err.response.data, "danger");
                    });
            },
            loadIncomeChart() {
                incomeChart()
                    .then((res) => {
                        self.incomeData = res;
                        console.log(self.incomeData)
                        self.initChart();
                    })
                    .catch((err) => {
                        self.$refs.toast.show(err.response.data, "danger");
                    });
            },
            simulateLoading() {
                this.$emit('showLoading')
            },
            loadNewUsers() {
                newUsers().then(res => self.newUsers = res)
            }
        }
    }
</script>
