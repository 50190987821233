<template>
    <div class="mask" id="mask">
        <div class="container">
            <div class="iconfont">
              <div class="jiazai iconfont" :class="icon"></div>
            </div>
            <div class="content">{{content}}</div>
            <div class="word" @click="hideModal">بىلدىم</div>
        </div>
    </div>
</template>
<script>
    let self;
    export default {
        created() {
            self = this;
            console.log(self.icon)
        },
        props: {
            icon: {
                type: String,
                value: ""
            },
           content:{
               type:String,
               value:""
           }
        },
        methods: {
            hideModal() {
                self.$emit("hide");
            },
        }
    };
</script>
<style lang="less" scoped>
    @import "../assets/less/global.less";
    .mask {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(2px);
        top: 0;
        left: 0;
        .container {
            position: relative;
            width: 360px;
            height: 200px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            padding: @margin-size;
            border-radius: @margin-size / 2;
            
            .iconfont{
                text-align: center;
                height: 100px;
                line-height: 100px;
                font-size: 60px;
            }
             
            .content{
                text-align: center;
                justify-content: center;
            }
 
            .word{
                width: 80px;
                height: 40px;
                border-radius: 8px;
                text-align: center;
                justify-content: center;
                line-height: 50px;
                background: @color-primary-dark;
                color: white;
                margin: 0 auto;
                margin-top: 32px;
                line-height: 40px;
                cursor: pointer;

            }
        }
    }
</style>