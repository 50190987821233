<template>
    <div class="helper">
        <i class="iconfont icon-bangzhu" @mouseenter="up" @mouseleave="down"></i>
        <div class="hint" v-if="shown" v-html="content"></div>
    </div>
</template>

<script>
    let internal
    export default {
        name: "Helper",
        data() {
            return {
                shown: false
            }
        },
        props: {
            content: String
        },
        methods: {
            up() {
                internal = setTimeout(() => {
                    this.show()
                }, 500)
            },
            down() {
                clearTimeout(internal)
                this.hide()
            },
            show() {
                this.shown = true
            },
            hide() {
                this.shown = false
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../assets/less/global.less";

    @keyframes slideIn {
        0% {
            opacity: 0;
            filter: blur(@margin-size);
            transform: translateX(-200px);
        }
        100% {
            opacity: 1;
            filter: none;
            transform: none;
        }
    }

    .helper {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 36px;

        .hint {
            animation: slideIn 200ms;
            animation-fill-mode: forwards;
            position: absolute;
            z-index: 2;
            left: -180px;
            top: -50%;
            max-width: 200px;
            box-sizing: border-box;
            padding: @margin-size;
            border-radius: @radius-size;
            background: white;
            box-shadow: fade(@color-primary-dark, 10%) 0 @margin-size @margin-size-xxl;
        }
    }
</style>