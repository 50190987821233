import request from '../utils/Request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

export function create(obj) {
    return request({
        // url: '/api/admin/carousel-add', // url = base url + request url
        url: 'admin/carousel-add', // url = base url + request url
        method: 'post',
        data: obj,
    })
}

export function update(obj) {
    return request({
        // url: '/api/admin/carousel-update', // url = base url + request url
        url: 'admin/carousel-update', // url = base url + request url
        method: 'post',
        data: obj,
    })
}

export function getList(obj) {
    return request({
        // url: '/api/admin/carousel-list',
        url: 'admin/carousel-list',
        method: 'get',
        params: obj

    })
}

export function remove(id) {
    return request({
        // url: '/api/admin/carousel-delete',
        url: 'admin/carousel-delete',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function detail(id) {
    return request({
        // url: '/api/admin/carousel-detail',
        url: 'admin/carousel-detail',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function updateStatus(obj) {
    return request({
        // url: '/api/admin/carousel-status-update',
        url: 'admin/carousel-status-update',
        method: 'post',
        data: obj
    })
}