<template>
  <div class="adv-list wrapper">
    <div class="title">
      <div class="word">تەشۋىقات تەڭشىكى</div>
      <div class="btn">
        <div class="btn-refresh" @click="loadSettings">
          <i class="iconfont icon-shuaxin"></i>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="poster-image">
        <div class="poster-text">
          <span>تەشۋىقات رەسىم قېلىپى</span>
          <helper content="كەڭلىك 750 پېكسىل ئېگىزلىك 1330 پېكسىل" />
        </div>
        <div class="col">
          <div
            class="upload-placeholder"
            @click="pickPosterImage"
            v-if="posterCoverInfo === null"
          >
            <i class="iconfont icon-tupian"></i>
          </div>
          <img
            class="image-viewer"
            @click="pickPosterImage"
            v-if="posterCoverInfo != null"
            :src="posterCoverInfo.url"
          />
          <input
            type="file"
            accept="image/*"
            ref="posterInput"
            @change="posterInputAtChange"
          />
        </div>
        <div class="btn">
          <div class="btn-save" @click="update">ساقلاش</div>
          <!-- <div class="btn-back" @click="toBack">بىكار قىلىش</div> -->
        </div>
      </div>
      <div class="poster-info">
        <div class="row">
          <div class="text">
            <span>مۇقاۋا رەسىم چوڭ-كىچىكلىكى</span>
          </div>
          <div class="input-text">
            <input
              class="input"
              type="number"
              placeholder="ئېگىزلىكىنى كىرگۈزۈڭ"
              v-model="coverSizeHeight"
            />
            <span class="span">ئېگىزلىك</span>
            <input
              class="input"
              type="number"
              placeholder="كەڭلىكنى كىرگۈزۈڭ"
              v-model="coverSizeWidth"
            />
            <span class="span">كەڭلىك</span>
          </div>
        </div>
        <div class="row">
          <div class="text">
            <span>مۇقاۋا رەسىم كوئوردېناتى</span>
          </div>
          <div class="input-text">
            <input
              class="input"
              type="number"
              placeholder="Xئوق ئورنىنى كىرگۈزۈڭ"
              v-model="coverCoordinateX"
            />
            <span class="span">Xئوق</span>
            <input
              class="input"
              type="number"
              placeholder="Yئوق ئورنىنى كىرگۈزۈڭ "
              v-model="coverCoordinateY"
            />
            <span class="span">Yئوق</span>
          </div>
        </div>
        <div class="row">
          <div class="text"><span>ئەسەر نام كوئوردېناتى</span></div>
          <div class="input-text">
            <input
              class="input"
              type="number"
              placeholder="Xئوق ئورنىنى كىرگۈزۈڭ"
              v-model="articleNameCoordinateX"
            />
            <span class="span">Xئوق</span>
            <input
              class="input"
              type="number"
              placeholder="Yئوق ئورنىنى كىرگۈزۈڭ"
              v-model="articleNameCoordinateY"
            />
            <span class="span">Yئوق</span>
          </div>
        </div>
        <div class="row">
          <div class="text"><span>ئەسەر رايۇن كوئوردېناتى</span></div>
          <div class="input-text">
            <input
              class="input"
              type="number"
              placeholder="Xئوق ئورنىنى كىرگۈزۈڭ"
              v-model="articleRegionCoordinateX"
            />
            <span class="span">Xئوق</span>
            <input
              class="input"
              type="number"
              placeholder="Yئوق ئورنىنى كىرگۈزۈڭ"
              v-model="articleRegionCoordinateY"
            />
            <span class="span">Yئوق</span>
          </div>
        </div>
        <div class="row">
          <div class="text"><span>ئەسەر تۈر كوئوردېناتى</span></div>
          <div class="input-text">
            <input
              class="input"
              type="number"
              placeholder="Xئوق ئورنىنى كىرگۈزۈڭ"
              v-model="articleCategoryCoordinateX"
            />
            <span class="span">Xئوق</span>
            <input
              class="input"
              type="number"
              placeholder="Yئوق ئورنىنى كىرگۈزۈڭ"
              v-model="articleCategoryCoordinateY"
            />
            <span class="span">Yئوق</span>
          </div>
        </div>
        <div class="row">
          <div class="text">
            <span>ئەسەر باھا نۇمۇر كوئوردېناتى</span>
          </div>
          <div class="input-text">
            <input
              class="input"
              type="number"
              placeholder="Xئوق ئورنىنى كىرگۈزۈڭ"
              v-model="articleRateCoordinateX"
            />
            <span class="span">Xئوق</span>
            <input
              class="input"
              type="number"
              placeholder="Yئوق ئورنىنى كىرگۈزۈڭ"
              v-model="articleRateCoordinateY"
            />
            <span class="span">Yئوق</span>
          </div>
        </div>
        <div class="row">
          <div class="text">
            <span>چاچىما كود چوڭ-كىچىكلىكى</span>
          </div>
          <div class="input-text">
            <input
              class="input"
              type="number"
              placeholder="ئېگىزلىكىنى كىرگۈزۈڭ"
              v-model="qrSizeWidth"
            />
            <span class="span">ئېگىزلىك</span>
            <input
              class="input"
              type="number"
              placeholder="كەڭلىكنى كىرگۈزۈڭ"
              v-model="qrSizeHeight"
            />
            <span class="span">كەڭلىك</span>
          </div>
        </div>
        <div class="row">
          <div class="text"><span>چاچىما كود كوئوردېناتى</span></div>
          <div class="input-text">
            <input
              class="input"
              type="number"
              placeholder="Xئوق ئورنىنى كىرگۈزۈڭ"
              v-model="qrCoordinateX"
            />
            <span class="span">Xئوق</span>
            <input
              class="input"
              type="number"
              placeholder="Yئوق ئورنىنى كىرگۈزۈڭ"
              v-model="qrCoordinateY"
            />
            <span class="span">Yئوق</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <toast ref="toast" position="center" type="danger" />
  <confirm-modal
    v-if="ConfirmModalShown"
    :icon="successIcon"
    :content="confirmModalContent"
    @hide="confirmModalHide"
  >
  </confirm-modal>
</template>

<style lang="less" scoped>
@import "../../assets/less/global.less";
@import "../../assets/less/anim.less";

@icon-size: 40px;
.adv-list {
  direction: rtl;
  max-width: @wrap-width;
  min-width: @wrap-min-width;
  margin: @margin-size-large auto;
  border-radius: @radius-size;
  box-sizing: border-box;
  background: white;
  min-height: @wrap-min-height;
  @title-height: 60px;
  @inputHeight: 36px;

  .title {
    @btn-size: 40px;
    box-sizing: border-box;
    font-weight: bold;
    padding: 0 @margin-size;
    margin-bottom: @margin-size-large;
    border-bottom: fade(@color-nice-black, 10%) 1px solid;
    height: @title-height;
    line-height: @title-height;
    display: flex;

    .word {
      flex: 1;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn-refresh {
        width: @btn-size;
        height: @btn-size;
        line-height: @btn-size;
        text-align: center;
        border-radius: @radius-size;
        color: @color-primary;
        cursor: pointer;
        transition: background-color 200ms;

        &:hover {
          background: fade(@color-nice-black, 4%);
        }
      }

      .btn-add {
        height: @btn-size;
        line-height: @btn-size - 4;
        background: fade(@color-primary-dark, 6%);
        color: @color-primary-dark;
        cursor: pointer;
        font-weight: normal;
        padding: 0 @margin-size;
        border-radius: @radius-size;
        margin-inline-start: @margin-size;
        transition: background-color 200ms, color 200ms;

        &:hover {
          background: @color-primary-dark;
          color: white;
        }
      }
    }
  }

  .list {
    @opr-btn-size: 40px;
    padding: @margin-size;
    display: flex;
    @ph-width: 312px;
    @ph-height: 552px;

    .poster-image {
      margin-left: @margin-size * 2;
      width: @ph-width;
      .col {
        padding-top: @margin-size;

        input[type="file"] {
          display: none;
        }

        .upload-placeholder {
          width: @ph-width;
          height: @ph-height;
          line-height: @ph-height;
          text-align: center;
          box-sizing: border-box;
          border-radius: @radius-size;
          border: fade(@color-primary-dark, 40%) 1px dashed;
          cursor: pointer;

          &.large {
            width: @ph-width * 2;
          }

          i {
            font-size: @font-size-xxxl;
            color: fade(@color-primary-dark, 40%);
          }
        }

        .image-viewer {
          height: @ph-height;
          width: @ph-width;

          &.large {
            width: @ph-width * 2;
          }
        }
      }

      .btn {
        align-items: center;
        display: flex;
        margin-top: 30px;

        .btn-save {
          height: 40px;
          line-height: 40px;
          width: 50px;
          background: fade(@color-primary-dark, 6%);
          color: @color-primary-dark;
          cursor: pointer;
          font-weight: normal;
          padding: 0 @margin-size;
          border-radius: @radius-size;
          margin-inline-end: @margin-size;
          transition: background-color 200ms, color 200ms;
          background: @color-primary-dark;
          color: white;

          &:hover {
            background: @color-primary-dark;
            color: white;
          }
        }

        .btn-back {
          height: 40px;
          line-height: 40px;
          width: 70px;
          background: fade(@color-accent, 6%);
          color: @color-accent;
          cursor: pointer;
          font-weight: normal;
          padding: 0 @margin-size;
          border-radius: @radius-size;
          margin-inline-end: @margin-size;
          transition: background-color 200ms, color 200ms;
          background-color: #fd397a;
          border-color: #fd397a;
          color: #fff;

          &:hover {
            background-color: #fd397a;
            border-color: #fd397a;
            color: #fff;
          }
        }
      }
    }
    .poster-info {
      flex: 2;
      padding-top: @margin-size * 2;

      .row {
        display: flex;
        align-items: center;
        height: @inputHeight;
        line-height: @inputHeight;
        border-radius: @radius-size;
        padding: 0 @margin-size-small;
        margin-bottom: @margin-size * 1.5;

        .text {
          flex: 1;
        }

        .input-text {
          flex: 4;
          display: flex;

          .input {
            height: @inputHeight;
            line-height: @inputHeight;
            border: 1px solid;
            margin: 0 @margin-size;
            border-radius: @inputHeight / 2;
            padding: 0 @margin-size;
            border: fade(@color-primary-dark, 20%) 1px solid;
            box-sizing: border-box;
            margin-left: @margin-size;
            flex: 1;
          }
          .span {
            flex: 2;
            color: #c9c7c7;
          }
        }
      }
    }
  }
}
</style>

<script>
import Toast from "../../components/Toast";
import Helper from "../../components/Helper";
import ConfirmModal from "../../components/ConfirmModal";
import { posterSetting, updatePosterSetting } from "../../api/setting";
import { upload } from "../../api/common";
let self;
let requesting = false;
export default {
  name: "PosterSetting",
  emits: [""],
  components: { Helper, Toast, ConfirmModal },
  data() {
    return {
      posterCoverInfo: null,
      ConfirmModalShown: false,
      posterInfo: [],
      posterWidth: 0,
      posterheigth: 0,
      coverSizeWidth: 0,
      coverSizeHeight: 0,
      coverCoordinateX: 0,
      coverCoordinateY: 0,
      qrSizeWidth: 0,
      qrSizeHeight: 0,
      qrCoordinateX: 0,
      qrCoordinateY: 0,
      articleNameCoordinateX: 0,
      articleNameCoordinateY: 0,
      articleRegionCoordinateX: 0,
      articleRegionCoordinateY: 0,
      articleCategoryCoordinateX: 0,
      articleCategoryCoordinateY: 0,
      articleRateCoordinateX: 0,
      articleRateCoordinateY: 0,
      successIcon: "",
      confirmModalContent: "",
    };
  },
  created() {
    self = this;
    self.loadSettings();
  },
  mounted() {},
  methods: {
    toBack() {
      self.$router.back();
    },
    pickPosterImage() {
      self.$refs.posterInput.click();
    },
    confirmModalHide() {
      self.ConfirmModalShown = false;
    },
    posterInputAtChange(e) {
      if (e.target.files.length !== 1) {
        return;
      }
      console.log(e);
      self.posterInfo.forEach((element) => {
        if (element.k === "poster_bg") {
          self.posterWidth = element.width;
          self.posterheigth = element.height;
        }
      });
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("type", "poster_bg");
      formData.append("width", self.posterWidth);
      formData.append("height", self.posterheigth);
      upload(formData).then((res) => {
        if (res.msg) {
          self.$refs.toast.show(res.msg, "danger", "center");
          return;
        }
        self.PosterCoverInfo = res;
        self.posterCoverInfo = {
          path: res.path,
          url: res.url,
        };
      });
    },
    loadSettings() {
      posterSetting().then((res) => {
        self.posterInfo = res;
        self.coverSizeWidth = res[0].width;
        self.coverSizeHeight = res[0].height;
        self.coverCoordinateX = res[1].width;
        self.coverCoordinateY = res[1].height;
        self.qrSizeWidth = res[6].width;
        self.qrSizeHeight = res[6].height;
        self.qrCoordinateX = res[7].width;
        self.qrCoordinateY = res[7].height;
        self.articleNameCoordinateX = res[2].width;
        self.articleNameCoordinateY = res[2].height;
        self.articleRegionCoordinateX = res[3].width;
        self.articleRegionCoordinateY = res[3].height;
        self.articleCategoryCoordinateX = res[4].width;
        self.articleCategoryCoordinateY = res[4].height;
        self.articleRateCoordinateX = res[5].width;
        self.articleRateCoordinateY = res[5].height;
        let posterCoverInfoUrl = "https://dawran-api.ihlimtv.com/" + res[8].v;

        if (res[8].v != null) {
          self.posterCoverInfo = {
            path: res[8].v,
            url: posterCoverInfoUrl,
          };
        }
      });
    },
    update() {
      if (requesting) return;
      if (!self.validate()) return;
      let data = {
        cover_size_width: self.coverSizeWidth,
        cover_size_height: self.coverSizeHeight,
        cover_coordinate_x: self.coverCoordinateX,
        cover_coordinate_y: self.coverCoordinateY,
        qr_size_width: self.qrSizeWidth,
        qr_size_height: self.qrSizeHeight,
        qr_coordinate_x: self.qrCoordinateX,
        qr_coordinate_y: self.qrCoordinateY,
        article_name_coordinate_x: self.articleNameCoordinateX,
        article_name_coordinate_y: self.articleNameCoordinateY,
        article_region_coordinate_x: self.articleRegionCoordinateX,
        article_region_coordinate_y: self.articleRegionCoordinateY,
        article_category_coordinate_x: self.articleCategoryCoordinateX,
        article_category_coordinate_y: self.articleCategoryCoordinateY,
        article_rate_coordinate_x: self.articleRateCoordinateX,
        article_rate_coordinate_y: self.articleRateCoordinateY,
        poster_bg: self.posterCoverInfo.path,
      };
      requesting = true;

      updatePosterSetting(data).then(() => {
        requesting = false;
        self.ConfirmModalShown = true;
        self.confirmModalContent = "ئۆزگەرتىش مۇۋاپىقىيەتلىك بولدى";
        self.successIcon = "icon-duihao";
      });
    },
    validate() {
      if (self.posterCoverInfo === null) {
        self.$refs.toast.show("مۇقاۋا رەسىمنى تاللاڭ ");
        return false;
      }
      return true;
    },
  },
};
</script>