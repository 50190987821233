<template>
    <div class="start-list wrapper">
        <div class="title">
            <div class="word">موبەن تىزىملىكلىرى</div>
            <div class="btn">
                <div class="btn-refresh" @click="loadList">
                    <i class="iconfont icon-shuaxin"></i>
                </div>
            </div>
            <!-- <div class="btn">
            <div class="btn-add" @click="toAdd">قوشۇش</div>
            </div> -->
        </div>
        <div class="list">
            <div class="item" v-for="(item,index) in list" :key="index">
              <div class="titel">
                  <div class="titel-name">{{item.name}}</div>
              </div>
              <div class="content">
                  <div class="row">
                      <div class="col val opr">风味原产地·云南</div>
                      <div class="col key text">{{item.thing1}}</div>
                  </div>
                  <div class="row">
                      <div class="col val opr">纪录片</div>
                      <div class="col key text">{{item.thing2}}</div>

                  </div>
                  <div class="row" v-if="item.thing3 != null">
                      <div class="col val opr">和陈晓卿一起感受地道的云南风味！</div>
                      <div class="col key text">{{item.thing3}}</div>
                      
                  </div>
                  <div class="row" v-if="item.thing4 != null">
                      <div class="col val opr">请注意合理安排孩子看视频的时间，注意保护眼睛哦！</div>
                      <div class="col key text">{{item.thing4}}</div>
                      
                  </div>
                  <div class="row">
                      <div class="col key">ئىشلىتىلىش قېتىم سانى</div>
                      <div class="col val">{{item.uses_count}} قېتىم</div>
                  </div>
                  <div class="row">
                      <div class="col key">جەمئىي مۇشتەرى ئ‍ەزا</div>
                      <div class="col val">{{item.user_count}} نەپەر</div>
                  </div>
                  <div class="row">
                      <div class="col key">ئۇچۇر تاپشۇرۇپ ئالىدىغان ئ‍ەزا</div>
                      <div class="col val">{{item.subscribers_count}} نەپەر</div>
                  </div>
              </div>
              <div class="btn">
                 <div class="btn-edit" @click="toEdit(item.template_id,item.user_count)">موبەن يوللاش</div>
                 <div class="btn-history" @click="toHistory(item.user_count,item.template_id)">يوللاش خاتىرسى</div>
                 <div class="btn-kong"></div>
              </div>
            </div>
        </div>
    </div>
    <toast ref="toast" position="center" type="danger"/>
</template>

<script>
    import Toast from "../../components/Toast";
    import {getList} from "../../api/template";

    let self

    export default {
        name: "TemplateMessageList",
        emits: ['showLoading'],
        components: {
            Toast
        },
        created() {
            self = this
            self.loadList()
        },
        data() {
            return {
                list: []
            }
        },
        methods: {
            loadList() {
                getList().then(res => self.list = res)
            },
            toEdit(id,user_count) {
                if (Number(user_count) === 0) {
                 self.$refs.toast.show('ئەزا سانى 0دىن چوڭ بولىشى كىرەك')
                    self.$refs.title.focus()
                    return false
                }
                self.$router.push({name: 'TemplateMessageSend', params: {template_id: id}})
            },
            toHistory(user_count,id){
               if (Number(user_count) === 0) {
                 self.$refs.toast.show('بۇ ئۇسلۇپتىكى موبەننى تىخى ئىشلەتمەپسىز')
                    self.$refs.title.focus()
                    return false
                }
              self.$router.push({name: 'TampleteList', params: {template_id: id}})
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    @icon-size: 40px;
    .start-list {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        min-height: @wrap-min-height;
        @title-height: 70px;

        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            background: white;
            border-radius: @radius-size;
            padding: 0 @margin-size;
            margin-bottom: @margin-size;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-refresh {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .btn-add {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-start: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .list {
            flex-wrap: wrap;
            align-content: flex-start;

            .item {
                width: 400px;
                box-sizing: border-box;
                border-radius: @radius-size;
                background: white;
                float: right;
                
                &:last-child{
                    margin-right: 32px;
                }

                .titel{
                    height: 60px;
                    line-height: 60px;
                    padding-left: @margin-size-xl;
                    text-align: left;
                    font-size: @font-size-large;
                    border-bottom: 1px solid #ebedf2;
                }
                 
                .content{
                 padding: @margin-size-large;
                 text-align: left;

                 .row{
                     display: flex;
                     align-items: center;
                     padding: @margin-size-mini 0;

                     .col{
                         flex: 1;
                     }

                     .key{
                         flex: 1;
                         text-align: right;
                         color: #48465b;
                         font-weight: 600;
                         
                     }
                     .val{
                         color: #74788d;
                         font-weight: 400;
                     }

                     .text{
                         text-align: left;
                     }

                     .opr{
                         text-align: right;
                     }
                 }
                }

                .btn{
                    display: flex;
                    align-items: center;
                    height: 90px;
                    line-height: 90px;
                    text-align: center;
                    padding: 0 @margin-size-large;
                    border-top: 1px solid #ebedf2;
                    


                    .btn-edit{
                     flex: 1;
                     text-align: center;
                     background: lighten(@color-primary, 8%);
                     color: white;
                     height: @icon-size;
                     line-height: @icon-size - 4px;
                     border-radius: @radius-size;
                     

                     &:hover{
                        cursor: pointer;
                        background: lighten(@color-primary, 1%);
                        }
                    }

                    .btn-history{
                        flex: 1;
                        text-align: center;
                        background: lighten(#0abb87, 8%);
                        border-color: #0abb87;
                        color: #fff;
                        height: @icon-size;
                        line-height: @icon-size - 4px;
                        border-radius: @radius-size;
                        margin-right: @margin-size;

                        &:hover{
                        cursor: pointer;
                        background: lighten(#0abb87, 1%);
                        }
                    }

                    .btn-kong{
                        flex: 1;
                    }
                }
            }
        }
    }
</style>
