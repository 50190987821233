import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home'
import Login from '../views/Login'
import Index from '../views/Index'
import Agreement from '../views/Agreement'
//adv
import AdvList from '../views/adv/List'
import AdvCreate from '../views/adv/Create'
import AdvEdit from '../views/adv/Edit'
//start
import StartList from '../views/start/List'
import StartCreate from '../views/start/Create'
import StartEdit from '../views/start/Edit'

//category
import CategoryList from '../views/category/List'
import CategoryCreate from '../views/category/Create'
import CategoryEdit from '../views/category/Edit'
//article
import ArticleList from '../views/article/List'
import ArticleCreate from '../views/article/Create'
import ArticleEdit from '../views/article/Edit'
import WarningArticleList from '../views/article/WarningArticleList'

//Profile
import Profile from "../views/Profile";
//User
import UserList from '../views/user/List'
import AgentList from '../views/user/AgentList'
import ContractList from "../views/user/ContractList";
import WithdrawUserList from '../views/user/cash'

//VipTerm
import VipTermList from '../views/vipTerm/List'
import VipTermEdit from '../views/vipTerm/Edit'
import VipTermCreate from '../views/vipTerm/Create'

//Series
import SeriesList from '../views/series/List'
import SeriesEdit from '../views/series/Edit'
import SeriesCreate from '../views/series/Create'
//Setting
import SettingBooleanList from "../views/setting/BooleanList"
import SettingTextList from "../views/setting/TextList"
import SettingImageList from "../views/setting/ImageList"
import SettingTencentAdv from "../views/setting/TencentAdv"
import SettingPosterSetting from "../views/setting/PosterSetting"

// toll
import ToolSalon from '../views/tool/Salon'
import TemplateMessageList from '../views/templateMessage/List'
import TemplateMessageSend from '../views/templateMessage/Send'
import TampleteList from '../views/templateMessage/TampleteList'
const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        children: [{
                path: '/',
                name: 'Index',
                component: Index
            },
            {
                path: '/agreement',
                name: 'Agreement',
                component: Agreement
            },
            {
                path: '/profile',
                name: 'Profile',
                component: Profile
            },
            {
                path: '/adv/list',
                name: 'AdvList',
                component: AdvList
            },
            {
                path: '/adv/create',
                name: 'AdvCreate',
                component: AdvCreate
            },
            {
                path: '/adv/edit/:id',
                name: 'AdvEdit',
                component: AdvEdit
            },
            {
                path: '/start/list',
                name: 'StartList',
                component: StartList
            },
            {
                path: '/start/create',
                name: 'StartCreate',
                component: StartCreate
            },
            {
                path: '/start/edit/:id',
                name: 'StartEdit',
                component: StartEdit
            },

            {
                path: '/category/list',
                name: 'CategoryList',
                component: CategoryList
            },
            {
                path: '/category/create',
                name: 'CategoryCreate',
                component: CategoryCreate
            },
            {
                path: '/category/edit/:id',
                name: 'CategoryEdit',
                component: CategoryEdit
            },
            {
                path: '/article/list',
                name: 'ArticleList',
                component: ArticleList
            },
            {
                path: '/article/create',
                name: 'ArticleCreate',
                component: ArticleCreate
            },
            {
                path: '/article/edit/:id',
                name: 'ArticleEdit',
                component: ArticleEdit
            },
            {
                path: '/article/warning-article-list',
                name: 'WarningArticleList',
                component: WarningArticleList
            },

            {
                path: '/user/list',
                name: 'UserList',
                component: UserList
            },
            {
                path: '/user/agent-list',
                name: 'AgentList',
                component: AgentList
            },
            {
                path: '/user/contract-list',
                name: 'ContractList',
                component: ContractList
            },
            {
                path: '/user/cash',
                name: 'WithdrawUserList',
                component: WithdrawUserList
            },
            {
                path: '/vipTerm/list',
                name: 'VipTermList',
                component: VipTermList
            },
            {
                path: '/vipTerm/edit',
                name: 'VipTermEdit',
                component: VipTermEdit
            },
            {
                path: '/vipTerm/create',
                name: 'VipTermCreate',
                component: VipTermCreate
            },
            {
                path: '/series/list/:id/:type/:name',
                name: 'SeriesList',
                component: SeriesList
            },
            {
                path: '/series/edit/:id/:article_name/:name',
                name: 'SeriesEdit',
                component: SeriesEdit
            },
            {
                path: '/series/create/:id/:type/:article_name',
                name: 'SeriesCreate',
                component: SeriesCreate
            },
            {
                path: '/setting/boolean-list',
                name: 'SettingBooleanList',
                component: SettingBooleanList
            },
            {
                path: '/setting/text-list',
                name: 'SettingTextList',
                component: SettingTextList
            },
            {
                path: '/setting/image-list',
                name: 'SettingImageList',
                component: SettingImageList
            },
            {
                path: '/setting/tencent-adv',
                name: 'SettingTencentAdv',
                component: SettingTencentAdv
            },
            {
                path: '/setting/poster-setting',
                name: 'SettingPosterSetting',
                component: SettingPosterSetting
            },
            {
                path: '/tool/Salon',
                name: 'ToolSalon',
                component: ToolSalon
            },

            {
                path: '/template/list',
                name: 'TemplateMessageList',
                component: TemplateMessageList
            },
            {
                path: '/template/send/:template_id',
                name: 'TemplateMessageSend',
                component: TemplateMessageSend
            },
            {
                path: '/template/send/:template_id',
                name: 'TampleteList',
                component: TampleteList
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router