<template>
    <div class="radio">
        <div class="option" :class="{'active': index === chosenIndex}" v-for="(option,index) in options" :key="index"
             @click="update(index)">{{option}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "Radio",
        props: {
            options: Array,
            chosenIndex: Number
        },
        methods: {
            update(index) {
                this.$emit('update', {
                    chosenIndex: index
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../assets/less/global.less";

    @radio-height: 36px;
    .radio {
        display: inline-block;
        height: @radio-height;
        line-height: @radio-height - 6px;
        border-radius: @radio-height / 2;
        white-space: nowrap;
        box-sizing: border-box;
        border: fade(@color-primary-dark, 20%) 1px solid;
        overflow: hidden;
        user-select: none;

        .option {
            display: inline-block;
            padding: 0 @margin-size;
            height: @radio-height - 2px;
            border-inline-end: fade(@color-primary-dark, 20%) 1px solid;
            color: fade(@color-primary-dark, 60%);
            cursor: pointer;

            &.active {
                background: fade(@color-primary-dark, 20%);
                color: lighten(@color-primary-dark, 10%);
                border-inline-end-color: transparent;
            }

            &:last-child {
                border: none;
            }
        }
    }
</style>