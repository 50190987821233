<template>
  <div class="article-list wrapper">
    <div class="title">
      <div class="word">سالون يازما ئۇچۇرىغا ئېرىشىش</div>
      <div class="btn">
        <div class="btn-refresh" @click="refresh">
          <i class="iconfont icon-shuaxin"></i>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="select-title">
        <div>سىز ئىرىشمەكچى بولغان سالون  يازما ئۇچۇرى توپلام ئۇچۇرىمۇ؟ </div>
        <div class="switch">
          <Switch
          :status="salonBatchInfoUrlStatus"
          @update="updateSalonBatchInfoUrlStatus"
          positive-text="ھەئە"
          negative-text="ياق"
         />
        </div>
      </div>
      <div class="srech">
        <div class="srech-item">
          <div class="srech-word" @click="getResult">ئۇچۇرىغا ئىرشىش</div>
          <div v-if="showErrorIcon" class="input-error">
            <i v-if="showIcon" class="icon iconfont icon-duihao"></i>
            <i v-if="showErrorIcon" class="errorIcon iconfont icon-tanhao"></i>
            <input
              class="input-placeholder"
              v-model="inputValue"
              type="text"
              placeholder="سالون تىمىېسى قىسقا ئادرېسىنى كىرگۈزۈڭ"
            />
          </div>
          <div v-if="!showErrorIcon" class="input">
            <i v-if="showIcon" class="icon iconfont icon-duihao"></i>
            <i v-if="showErrorIcon" class="errorIcon iconfont icon-tanhao"></i>
            <input
              class="input-placeholder"
              v-model="inputValue"
              type="text"
              placeholder="سالون تىمىېسى قىسقا ئادرېسىنى كىرگۈزۈڭ"
            />
          </div>
        </div>
      </div>
      <div class="remind-text" v-if="showErrorIcon">
        سالون يازمىسى قىسقا ئادېرسىنى توغرا تولدۇرۇپ قايتا سىناڭ
      </div>
      <div class="result" v-if="showResult">
        <div class="salon-number">
          <div class="salon-info">{{ result.length }} دانە ئاۋاز</div>
        </div>
        <div class="content-list">
          <div class="content">
            <div
              class="content-item"
              v-for="(item, index) in result"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
          <i v-if="showIcon" class="icon iconfont icon-duihao"></i>
        </div>
        <div class="copy" @click="copy">
          <div class="copy-word">كۆچۈرۈش</div>
        </div>
      </div>
    </div>
  </div>
 <toast ref="toast" position="center" type="danger"/>
</template>
<style lang="less" scoped>
@import "../../assets/less/global.less";
@import "../../assets/less/anim.less";
@icon-size: 40px;
@title-height: 60px;
.article-list {
  direction: rtl;
  display: flex;
  flex-direction: column;
  max-width: @wrap-width;
  min-width: @wrap-min-width;
  margin: @margin-size-large auto;
  border-radius: @radius-size;
  box-sizing: border-box;
  background: white;
  min-height: @wrap-min-height;

  .title {
    @btn-size: 40px;
    box-sizing: border-box;
    font-weight: bold;
    padding: 0 @margin-size;
    margin-bottom: @margin-size-large;
    border-bottom: fade(@color-nice-black, 10%) 1px solid;
    height: @title-height;
    line-height: @title-height;
    display: flex;

    .word {
      flex: 1;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-inline-start: @margin-size;

      .btn-refresh {
        width: @btn-size;
        height: @btn-size;
        line-height: @btn-size;
        text-align: center;
        border-radius: @radius-size;
        color: @color-primary;
        cursor: pointer;
        transition: background-color 200ms;

        &:hover {
          background: fade(@color-nice-black, 4%);
        }
      }
    }
  }

  .list {
    padding: 0 @margin-size;
    .select-title{
      margin-bottom:16px;

      .switch{
        padding-top:16px;
      }
    }
    .serech {
      height: @title-height;
      line-height: @title-height;
      padding-top: @margin-size;
      border: 1px solid @color-primary-dark;
    }
    .srech-item {
      display: flex;
      height: @title-height / 1.3;
      line-height: @title-height / 1.3;
    }
    .srech-word {
      background: fade(#12cc94, 90%);
      width: @top-bar-height * 2;
      text-align: center;
      color: white;
      cursor: pointer;
      font-weight: normal;
      border-radius: 0 8px 8px 0;

      transition: background-color 200ms, color 200ms;
      &:hover {
        background: #0abb87;
        color: white;
      }
    }
    .input-error {
      width: 100%;
      text-align: left;
      padding-left: @font-size-small;
      direction: ltr;
      border: 1px solid #0abb87;
      border-radius: @radius-size 0 0 @radius-size;
      .input-placeholder {
        width: 90%;
        border: none;
      }
      .icon {
        border: none;
        color: #0abb87;
      }
      .errorIcon {
        border: none;
        color: #fd5992;
      }
    }
    .input {
      width: 100%;
      text-align: left;
      padding-left: @font-size-small;
      direction: ltr;
      border: 1px solid #0abb87;
      border-radius: @radius-size 0 0 @radius-size;
      .input-placeholder {
        width: 90%;
        border: none;
      }
      .icon {
        border: none;
        color: #0abb87;
      }
      .errorIcon {
        border: none;
        color: #fd5992;
      }
    }
    .input-error {
      width: 100%;
      text-align: left;
      padding-left: @font-size-small;
      direction: ltr;
      border: 1px solid #fd5992;
      border-radius: @radius-size 0 0 @radius-size;
    }
    .remind-text {
      text-align: left;
      color: #fd5992;
      padding-top: @margin-size-mini;
    }
    .result {
      display: flex;
      border: 1px solid #0abb87;
      margin-top: @radius-size-large;
      border-radius: @radius-size 0 0 @radius-size;
      min-height: @title-height / 1.3;
      vertical-align: -webkit-baseline-middle;
      .salon-number {
        width: @top-bar-height * 2.1;
        background: fade(@color-nice-black, 10%);
        text-align: center;
        vertical-align: middle;
        display: flex;
        .salon-info {
          align-self: center;
          margin: 0 auto;
        }
      }
      .content-list {
        flex: 1;
        text-align: left;
        display: flex;
        justify-content: flex-end;
        padding-left: @margin-size-small;
        .content {
          .content-item {
            margin: 0;
            padding-top: @margin-size-small;
          }
        }
        :last-child {
          padding-bottom: @margin-size-small;
        }
        .icon {
          padding-top: @margin-size-small;
          padding-right: @margin-size-small;
          color: #0abb87;
        }
      }

      .copy {
        width: @margin-size-xl * 4;
        background: fade(#12cc94, 90%);
        text-align: center;
        color: white;
        display: flex;
        border-radius: @radius-size-small 0 0 @radius-size-small;
        .copy-word {
          align-self: center;
          margin: 0 auto;
        }
        &:hover {
          background: #0abb87;
          color: white;
          border-radius: @radius-size-small 0 0 @radius-size-small;
        }
      }
    }
  }

  .paginator {
    direction: ltr;
    box-sizing: border-box;
    height: @title-height;
    line-height: @title-height;
    padding: 0 @margin-size-large;
  }
}
</style>

   

<script>
import { getSalonResult } from "../../api/common";
import {copyToClipBoard} from '../../utils/Utils'
import ConfirmBox from "../../components/ConfirmBox";
import Toast from "../../components/Toast";
import Paginator from "../../components/Paginator";
import Switch from "../../components/Switch";
import Operation from "../../components/Operation";

let self;
export default {
  name: "Salon",
  emits: ["showLoading"],
  // eslint-disable-next-line vue/no-unused-components
  components: { Switch, Toast, ConfirmBox, Paginator, Operation },
  data() {
    return {
      curPage: 1,
      pageSize: 10,
      totalRecord: 0,
      list: [],
      confirmBoxContent: "",
      showIcon: false,
      showResult: false,
      inputValue: "",
      result: [],
      showErrorIcon: false,
      salonBatchInfoUrlStatus: false,
    };
  },
  computed: {
    paginatorShown() {
      if (self.totalRecord === 0) return false;
      return self.totalRecord / self.pageSize > 1;
    },
    resultStr() {
      if (self.result.length === 0) {
        return "";
      }else{
      return this.result.join('\n\r')
      }
    },
  },
  created() {
    self = this;
  
  },
  mounted() {
  },
  updated(){
   if (self.inputValue == '') {
      self.showErrorIcon = false
      self.showResult = false
      self.showIcon = false
    }
  },
  methods: {
    getResult() {
      let data = {}
       if (self.salonBatchInfoUrlStatus == true) {
         data = {
           url: self.inputValue,
           is_collection: self.salonBatchInfoUrlStatus ? 1 :0
         }
       }else{
        data = {
        url: self.inputValue,
        };
       }
      
      getSalonResult(data)
        .then((res) => {
          if (res.length > 0) {
            self.showResult = true;
            self.showIcon = true;
            self.result = res;
          }else{
            self.showErrorIcon = true;
          }
        })
        .catch(() => {
          self.showErrorIcon = true;
        });
    },

    copy() {
      copyToClipBoard(self.resultStr)
       self.$refs.toast.show('!كۆچۈرۈش مۇۋاپىقىيەتلىك بولدى')
    },
    updateSalonBatchInfoUrlStatus(){
      self.salonBatchInfoUrlStatus = !self.salonBatchInfoUrlStatus
    }
  },
};
</script>