<template>
    <div class="adv-list wrapper">
        <div class="title">
            <div class="word">بارلىق ئەزالىق تۈرلىرى</div>
            <div class="btn">
                <div class="btn-refresh" @click="loadList">
                    <i class="iconfont icon-shuaxin"></i>
                </div>
            </div>
            <div class="btn">
                <div class="btn-add" @click="toAdd">قوشۇش</div>
            </div>
        </div>
        <div class="list">
            <div class="header">
                <div class="col id">ID</div>
                <div class="col adv-title">ئەزالىق نامى</div>
                <div class="col adv-title">ئەزالىق مۇددىتى</div>
                <div class="col adv-title">باھاسى</div>
                <div class="col adv-title">ئەسكەرتىش</div>
                <div class="col opr">مەشغۇلات</div>
            </div>

            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="col id">{{item.id}}</div>
                <div class="col adv-title">{{item.name}}</div>
                <div class="col adv-title">{{item.term}} كۈن</div>
                <div class="col adv-title">{{item.price}} يۈەن</div>
                <div class="col adv-title">{{item.hint}}</div>
                <div class="col opr">
                    <div class="btn opr-btn" @click="toEdit(item.id)">
                        <i class="iconfont icon-bianji"></i>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <toast ref="toast"/>
</template>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    @icon-size: 40px;
    .adv-list {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;
        @title-height: 60px;

        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-refresh {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .btn-add {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-start: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .list {
            @opr-btn-size: 40px;
            padding: @margin-size;

            .header {
                width: 100%;
                height: @icon-size * 2;
                align-items: center;
                display: flex;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.cover {
                    flex: 3;
                }

                .col.adv-title {
                    flex: 4;
                }

                .col.status {
                    flex: 2;
                }

                .col.opr {
                    flex: 1;
                    text-align: center;
                }
            }

            .item {
                width: 100%;
                display: flex;
                align-items: center;
                border-top: fade(@color-nice-black, 10%) 1px dashed;
                padding: @margin-size-small 0;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.cover {
                    flex: 3;

                    img {
                        height: 80px;
                    }
                }

                .col.adv-title {
                    flex: 4;
                }

                .col.status {
                    flex: 2;
                }


                .col.opr {
                    flex: 1;
                    display: flex;
                    text-align: center;
                    flex-direction: row;

                    .btn {
                        width: @icon-size;
                        height: @icon-size;
                        line-height: @icon-size;
                        text-align: center;
                        border-radius: @radius-size-half;
                        margin-inline-start: @margin-size;
                        background: transparent;
                        transition: background-color 200ms, color 200ms;
                        cursor: pointer;

                        &:hover {
                            background: fade(@color-primary-dark, 6%);
                            color: @color-primary-dark;
                        }
                    }
                }
            }

        }
    }
</style>

<script>
    import Toast from "../../components/Toast";
    import {getList} from "../../api/vipTerm";

    let self

    export default {
        name: "VipTermList",
        emits: ['showLoading'],
        components: {Toast},
        data() {
            return {
                list: []
            }
        },
        created() {
            self = this
        },
        mounted() {
            self.loadList()
        },
        methods: {
            toAdd() {
                self.$router.push('/vipTerm/create')
            },
            loadList() {
                getList().then(res => self.list = res)
            },
            handleImageError(index) {
                self.list[index].image = require('../../assets/logo.png')
            },
            toEdit(id) {
                self.$router.push({name: 'VipTermEdit', params: {id: id}})
            }
        }
    }
</script>