<template lang="html">
    <div class="editor">
        <div ref="toolbar" class="toolbar">
        </div>
        <div ref="editor" class="text">
        </div>
    </div>
</template>

<script>
    import E from 'wangeditor'
    import i18 from 'i18next'
    export default {
        name: 'Editor',
        data() {
            return {
                // uploadPath,
                editor: null,
                info_: null
            }
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            isClear: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            isClear(val) {
                // 触发清除文本域内容
                if (val) {
                    this.editor.txt.clear()
                    this.info_ = null
                }
            },
            value: function(value) {
                if (value !== this.editor.txt.html()) {
                    this.editor.txt.html(this.value)
                }
            }
            //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
        },
        mounted() {
            this.seteditor()
            this.editor.txt.html(this.value)
        },
        methods: {
            seteditor() {
                // http://192.168.2.125:8080/admin/storage/create
                this.editor = new E(this.$refs.toolbar, this.$refs.editor)
                // this.editor.customConfig.uploadImgServer = 'http://otp.cdinfotech.top/file/upload_images'// 配置服务器端地址
                // this.editor.customConfig.uploadImgHeaders = { }// 自定义 header
                // this.editor.customConfig.uploadFileName = 'file' // 后端接受上传文件的参数名
                // this.editor.customConfig.uploadImgMaxSize = 2 * 1024 * 1024 // 将图片大小限制为 2M
                // this.editor.customConfig.uploadImgMaxLength = 6 // 限制一次最多上传 3 张图片
                // this.editor.customConfig.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间

                // 配置菜单
                this.editor.config.menus = [
                    'head', // 标题
                    'bold', // 粗体
                    'fontSize', // 字号
                    'fontName', // 字体
                    'italic', // 斜体
                    'underline', // 下划线
                    'strikeThrough', // 删除线
                    'foreColor', // 文字颜色
                    'backColor', // 背景颜色
                    'link', // 插入链接
                    'list', // 列表
                    'justify', // 对齐方式
                    'quote', // 引用
                    'emoticon', // 表情
                    'image', // 插入图片
                    'table', // 表格
                    'video', // 插入视频
                    'code', // 插入代码
                    'undo', // 撤销
                    'redo', // 重复
                    'fullscreen' // 全屏
                ]

                // this.editor.customConfig.uploadImgHooks = {
                //
                //     customInsert: (insertImg, result) => {
                //         // 图片上传成功，插入图片的回调
                //         //result为上传图片成功的时候返回的数据，这里我打印了一下发现后台返回的是data：[{url:"路径的形式"},...]
                //         // console.log(result.data[0].url)
                //         //insertImg()为插入图片的函数
                //         //循环插入图片
                //         // for (let i = 0; i < 1; i++) {
                //         // console.log(result)
                //         let url = "http://otp.cdinfotech.top"+result.url
                //         insertImg(url)
                //         // }
                //     }
                // }
                this.editor.config.onchange = (html) => {
                    this.info_ = html // 绑定当前逐渐地值
                    this.$emit('change', this.info_) // 将内容同步到父组件中
                }
                this.editor.config.lang='uy'
                this.editor.config.languages['uy'] = {
                    wangEditor: {
                        插入: 'insert',
                        默认: 'default',
                        创建: 'create',
                        修改: 'edit',
                        如: 'like',
                        请输入正文: 'ئەسەر مەزمۇنىنى كىرگۈزۈڭ',
                        menus: {
                            title: {
                                标题: 'ماۋزۇ',
                                加粗: 'توم خەت',
                                字号: 'خەت چوڭلۇقى',
                                字体: 'خەت شەكلى',
                                斜体: 'يانتۇ قىلىش',
                                下划线: 'ئاستى سىزىق',
                                删除线: 'سىزىقنى يۇيۇش',
                                缩进: 'قىسقارتىش',
                                行高: 'قۇر ئىگىزلىكى',
                                文字颜色: 'خەت رەڭگى',
                                背景色: 'تەگلىك رەڭگى',
                                链接: 'ئۇلانما',
                                序列: 'تەرتىپ',
                                对齐: 'يۆنىلىش ئۆزگەرتىش',
                                引用: 'قىستۇرما',
                                表情: 'بىياۋچىڭ',
                                图片: 'رەسىم',
                                视频: 'ۋېدىئو',
                                表格: 'جەدۋەل',
                                代码: 'كود',
                                分割线: 'ئايرىش سىزىقى',
                                恢复: 'ئەسلىگە قايتۇرۇش',
                                撤销: 'چىكىندۈرۈش',
                                全屏: 'تولۇق ئىكران',
                                代办事项: 'بىجىرىشكە تىگىشلىك ئىشلار',
                            },
                            dropListMenu: {
                                设置标题: 'ماۋزۇ ',
                                背景颜色: 'تەگلىك رەڭگى',
                                文字颜色: 'خەت رەڭگى',
                                设置字号: 'خەت چوڭلۇقى',
                                设置字体: 'خەت نۇسخىسى',
                                设置缩进: 'indent',
                                对齐方式: 'خەت يۆنىلىشى',
                                设置行高: 'قۇر ئىگىزلىكى',
                                序列: 'تەرتىپ',
                                head: {
                                    正文: 'مەزمۇن',
                                },
                                indent: {
                                    增加缩进: 'indent',
                                    减少缩进: 'outdent',
                                },
                                justify: {
                                    靠左: 'سولدىن ئوڭغا',
                                    居中: 'ئوتتۇرسىغا',
                                    靠右: 'ئوڭدىن سولغا',
                                },
                                list: {
                                    无序列表: 'سانسىز تەرتىپ',
                                    有序列表: 'سان بار تەرتىپ',
                                },
                            },
                            panelMenus: {
                                emoticon: {
                                    默认: 'ئەسلى ھالىتى',
                                    新浪: 'سىنا تورى',
                                    emoji: 'بىياۋچىڭ',
                                    手势: 'قول ئىشارىتى',
                                },
                                image: {
                                    图片链接: 'رەسىم ئادىرىسى',
                                    上传图片: 'رەسىم چىقىرىش',
                                    网络图片: 'توردىكى رەسىم',
                                },
                                link: {
                                    链接: 'ئۇلانما',
                                    链接文字: 'ئۇلانما خەت',
                                    删除链接: 'يۇيۇش',
                                    查看链接: 'كۆرۈش',
                                },
                                video: {
                                    插入视频: 'ۋېدىئو قىستۇرۇش',
                                },
                                table: {
                                    行: 'قۇر',
                                    列: 'column',
                                    的: ' ',
                                    表格: 'جەدۋەل',
                                    添加行: 'قۇر قوشۇش',
                                    删除行: 'قۇر يۇيۇش',
                                    添加列: 'add column',
                                    删除列: 'delete column',
                                    设置表头: 'set header',
                                    取消表头: 'cancel header',
                                    插入表格: 'insert table',
                                    删除表格: 'delete table',
                                },
                                code: {
                                    删除代码: 'delete code',
                                    修改代码: 'edit code',
                                    插入代码: 'insert code',
                                },
                            },
                        },
                        validate: {
                            张图片: 'images',
                            大于: 'greater than',
                            图片链接: 'image link',
                            不是图片: 'is not image',
                            返回结果: 'return results',
                            上传图片超时: 'upload image timeout',
                            上传图片错误: 'upload image error',
                            上传图片失败: 'upload image failed',
                            插入图片错误: 'insert image error',
                            一次最多上传: 'once most at upload',
                            下载链接失败: 'download link failed',
                            图片验证未通过: 'image validate failed',
                            服务器返回状态: 'server return status',
                            上传图片返回结果错误: 'upload image return results error',
                            请替换为支持的图片类型: 'please replace with a supported image type',
                            您插入的网络图片无法识别: 'the network picture you inserted is not recognized',
                            您刚才插入的图片链接未通过编辑器校验: 'the image link you just inserted did not pass the editor verification',
                        },
                    }
                }
                this.editor.i18next = i18
                // 创建富文本编辑器
                this.editor.create()
            }
        }
    }
</script>

<style lang="css">
    .editor {
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 0;
    }
    .toolbar {
        border: 1px solid #ccc;
    }
    .text {
        border: 1px solid #ccc;
        min-height: 500px;
    }
    .w-e-text-container .placeholder{
        right: 10px;
    }
</style>