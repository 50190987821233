<template>
    <div class="loading-mask" v-if="shown"></div>
    <div class="loading-box" v-if="shown">
        <div class="icon">
            <i class="iconfont icon-shuaxin"></i>
        </div>
        <div class="text">{{text}}</div>
    </div>
</template>

<script>
    export default {
        name: "Loading",
        emits: ['show'],
        data() {
            return {
                shown: false
            }
        },
        props: {
            text: {
                type: String,
                default: 'يۈكلىنىۋاتىدۇ'
            }
        },
        methods: {
            show() {
                this.shown = true
            },
            hide() {
                this.shown = false
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../assets/less/global.less";

    @keyframes spin {
        0% {
            transform: rotate(1deg);
            opacity: 0.6;
        }
        50% {
            transform: rotate(180deg);
            opacity: 0.9;
        }
        100% {
            transform: rotate(359deg);
            opacity: 0.6;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 100%;
        }
    }

    @keyframes zoomIn {
        0% {
            opacity: 0;
            filter: blur(@margin-size);
            transform: scale(0.6) translate(-50%, -50%);
        }
        100% {
            filter: none;
            opacity: 100%;
            transform: translate(-50%, -50%);
        }
    }

    .loading-mask {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 999;
        background: rgba(0, 0, 0, 0.38);
        animation: fadeIn 200ms;
        animation-fill-mode: forwards;
    }

    .loading-box {
        position: fixed;
        width: 200px;
        height: 200px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
        background: rgba(0, 0, 0, 0.64);
        text-align: center;
        box-sizing: border-box;
        border-radius: @radius-size;
        padding: @margin-size-xxl;
        color: white;

        animation: zoomIn 200ms;
        animation-fill-mode: forwards;

        .icon {
            animation: spin 1s;
            animation-iteration-count: 1000;
            animation-timing-function: linear;

            .iconfont {
                font-size: @font-size-huge;
            }
        }

        .text {
            margin-top: @margin-size;
        }
    }
</style>