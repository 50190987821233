<template>
    <div class="adv-list wrapper">
        <div class="title">
            <div class="word">نەقلەشتۈرۈش خاتىرىسى</div>
            <div class="btn">
                <div class="btn-refresh" @click="loadList">
                    <i class="iconfont icon-shuaxin"></i>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="header">
                <div class="col id">ID</div>
                <div class="col cover">باش سۆرىتى</div>
                <div class="col adv-title">تور نامى</div>
                <div class="col phone">تىلفون نومۇرى</div>
                <div class="col price">سوممىسى</div>
                <div class="col status">ھالىتى</div>
                <div class="col time">نەقلەشتۈرگەن ۋاقىت</div>
                <div class="col opr">مەشغۇلات</div>
            </div>
            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="col id">{{item.id}}</div>
                <div class="col cover">
                    <img :src="item.avatar">
                </div>
                <div class="col adv-title">{{item.nick_name}}</div>
                <div class="col phone">{{item.phone}}</div>
                <div class="col price">{{item.amount}}</div>
                <div class="col status" v-if="Number(item.status) === 1">نەقلەشتۈرۈپ بولغان</div>
                <div class="col status" v-if="Number(item.status) === 0">نەقلەشتۈرمىگەن</div>
                <div class="col time">{{item.payment_time}}</div>
                <div class="col opr" v-if="Number(item.status) === 0" @click="withdraw(item.open_id,item.amount,item.partner_trade_no)">
                    <div class="word">نەقلەش</div>
                </div>
                 <div class="col opr" v-if="Number(item.status) === 1">
                </div>
            </div>

        </div>
        <div class="paginator" v-if="paginatorShown">
            <paginator :total="totalRecord" :page="curPage" :size="pageSize" :change="switchPage"/>
        </div>
    </div>
    <toast ref="toast"/>
    <confirm-box ref="confirmBox" :content="confirmBoxContent" @confirm="applyWithdraw"/>
    <confirm-modal v-if="ConfirmModalShown" :icon="successIcon" :content="confirmModalContent" @hide="confirmModalHide">
        <slot>
        </slot>
    </confirm-modal>
</template>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    @icon-size: 40px;
    @title-height: 60px;
    .adv-list {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;


        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-refresh {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .btn-add {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-start: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .list {
            @opr-btn-size: 40px;
            padding: @margin-size;

            .header {
                width: 100%;
                height: @icon-size * 2;
                align-items: center;
                display: flex;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.cover {
                    flex: 2;
                }

                .col.adv-title {
                    flex: 2;
                }

                .col.phone {
                    flex: 2;
                }

                .col.status {
                    flex: 2;
                }

                .col.price{
                    flex: 2;
                }

                .col.time{
                    flex: 2;
                }

                .col.opr {
                    flex: 1;
                    text-align: center;
                    
                }
            }

            .item {
                width: 100%;
                display: flex;
                align-items: center;
                border-top: fade(@color-nice-black, 10%) 1px dashed;
                padding: @margin-size-small 0;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.cover {
                    flex: 2;

                    img {
                        height: 40px;
                    }
                }

                .col.adv-title {
                    flex: 2;
                }

                .col.phone {
                    flex: 2;
                }

                .col.price{
                    flex: 2;
                }

                .col.status {
                    flex: 2;
                }

                .col.time {
                     flex: 2;
                     direction: ltr;
                     text-align: right;
                 }
                 
                .col.opr {
                    flex: 1;
                    text-align: center;
                   
                }

                .word{
                    
                    height: 40px;
                    width: 80px;
                    line-height: 36px;
                    background: rgba(103, 58, 183, 0.06);
                    color: #673ab7;
                    cursor: pointer;
                    font-weight: normal;
                    border-radius: 8px;
                    -webkit-margin-start: 16px;
                    margin-inline-start: 16px;
                    transition: background-color 200ms, color 200ms;
                    margin: 0 auto;


                     &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }

        }
    }

    .paginator {
        direction: ltr;
        box-sizing: border-box;
        height: @title-height;
        line-height: @title-height;
        padding: 0 @margin-size-large;
    }
</style>

<script>
    import {applyWithdrew, getWithdrawUser} from "../../api/user";
    import Toast from "../../components/Toast";
    import Paginator from "../../components/Paginator";
    import ConfirmBox from "../../components/ConfirmBox";
    import ConfirmModal from "../../components/ConfirmModal";
    let self
    let standByOpenId
    let standByAmount
    let partnerTradeNo
    export default {
        name: "WithdrawUserList",
        emits: ['showLoading'],
        components: {Toast, Paginator,ConfirmBox,ConfirmModal},
        data() {
            return {
                list: [],
                level_id: 0,
                keyword: "",
                curPage: 1,
                pageSize: 10,
                totalRecord: 0,
                confirmBoxContent: '',
                ConfirmModalShown:false,
                successIcon:"",
                confirmModalContent:""
            }
        },
        created() {
            self = this
        },
        mounted() {
             self.loadList()
        },
        watch: {
            keyword(val) {
                if (val.length === 0) {
                    self.curPage = 1
                }
            }
        },
        methods: {
            loadList() {
                getWithdrawUser({
                    page: self.curPage,
                    size: self.pageSize,
                }).then(res => {
                    console.log(res)
                    self.curPage = res.page
                    self.pageSize = res.pageSize
                    self.totalRecord = res.total
                    self.list = res.list
                })
            },
            withdraw(openId,amount,partner_trade_no){
                standByOpenId = openId
                standByAmount = amount
                partnerTradeNo = partner_trade_no
                self.confirmBoxContent = 'راستىنلا نەقلەشتۈرەمسىز؟'
                self.$refs.confirmBox.show()
             
            },       
            applyWithdraw(){
                self.$refs.confirmBox.cancel()
                  applyWithdrew({
                    open_id:standByOpenId,
                    amount:standByAmount,
                   partner_trade_no:partnerTradeNo,
                }).then((res)=>{
                 self.loadList()
                 self.ConfirmModalShown = true
                 if (res === "قالدۇق سومما سانى ماس كەلمىدى") {
                      self.successIcon = "icon-tanhao1"
                 }else{
                     self.successIcon = "icon-duihao"
                 }
               
                 self.confirmModalContent = res
                })
                
            },
            refresh() {
                self.curPage = 1
                self.loadList()
            },
            confirmModalHide(){
            self.ConfirmModalShown = false
            },
        }
    }
</script>
