<template>
    <div class="start-create wrapper">
        <div class="title">
            <div class="word">ئەزالىق تۈرى قوشۇش</div>
        </div>
        <div class="form">
            <div class="row">
                <div class="col col-1">
                    <label>ئەزالىق نامى</label>
                    <label>
                        <input class="input" type="text" placeholder="ئەزالىق نامىنى كىرگۈزۈڭ" v-model="name" @change="nameSetModified">
                    </label>
                </div>
                <div class="col col-1">
                    <label>ئەزالىق ھەققى</label>
                    <label>
                        <input class="input" type="number" placeholder="ئەزالىق ھەققىنى كىرگۈزۈڭ" v-model="price" @change="priceSetModified">
                    </label>
                </div>
                <div class="col col-1">
                    <label>ئەزالىق مۇددىتى</label>
                    <label>
                        <input class="input" type="number" placeholder="ئەزالىق مۇددىتىنى كىرگۈزۈڭ" v-model="term" @change="termSetModified">
                    </label>
                </div>
                <div class="col col-1">
                    <label>ئەزالىق ئەسكەرتىلىشى</label>
                    <label>
                        <input class="input" type="text" placeholder="ئەزالىق ئەسكەرتىلىشىنى كىرگۈزۈڭ" v-model="hint" @change="hintSetModified">
                    </label>
                </div>
            </div>
            <div class="btn">
                <div class="btn-save" @click="add">ساقلاش</div>
                <div class="btn-back" @click="toBack"> بىكار قىلىش </div>
            </div>
        </div>
    </div>
     <confirm-box
    ref="confirmBox"
    :content="confirmBoxContent"
    @confirm="cancel"
  />
    <toast ref="toast" position="center" type="danger"/>
</template>

<script>
    import Toast from "../../components/Toast";
    import {create} from "../../api/vipTerm";
    import ConfirmBox from "../../components/ConfirmBox";

    let self
    let requesting = false
     let modified = false;
    export default {
        name: "VipTermCreate",
        emits: ['showLoading'],
        components: {Toast, ConfirmBox},
        created() {
            self = this
        },
        data() {
            return {
                name: '',
                price:0,
                term:0,
                hint:'',
                confirmBoxContent:''
            }
        },
        methods: {
            nameSetModified() {
             modified = true
            },
            priceSetModified() {
             modified = true
            },
            termSetModified() {
              modified = true
            },
            hintSetModified() {
              modified = true
            },
            toBack() {
                 if (modified) {
                self.confirmBoxContent = "توشقۇزغان ئۇچۇرلارنى راستىنلا بىكا قىلامسىز؟";
               self.$refs.confirmBox.show();
               return;
                }
                self.$router.back()
            },
            cancel() {
              self.$router.back();
              modified = false
             },
            add() {
                if (requesting) return;
                if (!self.validate()) return

                let data = {
                    name: self.name,
                    price: self.price,
                    term: self.term,
                    hint: self.hint,
                }

                requesting = true

                create(data).then(res => {
                    self.$refs.toast.show(res, 'success')
                    setTimeout(() => {
                        self.$router.back()
                        requesting = false
                    }, 1000)
                })

            },
            validate() {
                if (self.name === '') {
                    self.$refs.toast.show('ئەزالىق تۈر نامىنى تولدۇرۇڭ')
                    self.$refs.title.focus()
                    return false
                }

                return true;
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    .start-create {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;
        @title-height: 60px;
        @inputHeight: 36px;

        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-back {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transform: rotate(90deg);
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .btn-save {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .form {
            padding: @margin-size;

            .row {
                display: flex;
                margin-bottom: @margin-size-xl;

                .col {
                    flex: 1;

                    &.col-1 {
                        flex: 1;
                    }

                    &.col-2 {
                        flex: 2;
                    }

                    &.col-3 {
                        flex: 3;
                    }

                    label {
                        display: block;
                        margin-bottom: @margin-size;
                    }

                    input.input {
                        width: 74%;
                        height: @inputHeight;
                        border-radius: @inputHeight / 2;
                        padding: 0 @margin-size;
                        border: fade(@color-primary-dark, 20%) 1px solid;
                        box-sizing: border-box;
                        margin-left: @margin-size;

                        &:focus {
                            border-color: fade(@color-primary-dark, 40%)
                        }

                        &.mn {
                            width: 30%;
                        }

                        &.sm {
                            width: 60%;
                        }

                        &.btn {
                            cursor: pointer;
                        }

                        &.ltr {
                            direction: ltr;
                        }
                    }

                    input[type=file] {
                        display: none;
                    }

                    @ph-width: 150px;
                    @ph-height: 150px;

                    .upload-placeholder {
                        width: @ph-width;
                        height: @ph-height;
                        line-height: @ph-height;
                        text-align: center;
                        box-sizing: border-box;
                        border-radius: @radius-size;
                        border: fade(@color-primary-dark, 40%) 1px dashed;
                        cursor: pointer;

                        i {
                            font-size: @font-size-xxxl;
                            color: fade(@color-primary-dark, 40%);
                        }
                    }

                    .image-viewer {
                        width: @ph-width;
                        height: @ph-height;
                    }
                }
            }

                        .btn {
                align-items: center;
                display: flex;

                .btn-save {
                    height: 40px;
                    line-height: 40px;
                    width: 50px;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;
                    background: @color-primary-dark;
                    color: white;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }

                .btn-back{
                   height: 40px;
                    line-height: 40px;
                    width: 70px;
                    background: fade(@color-accent, 6%);
                    color: @color-accent;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;
                    background-color: #fd397a;
                    border-color: #fd397a;
                    color: #fff;

                    &:hover {
                      background-color: #fd397a;
                      border-color: #fd397a;
                      color: #fff;
                    } 
                }
            }
        }
    }
</style>