<template>
    <div class="adv-list wrapper">
        <div class="title">
            <div class="word">رەسىملىك تەڭشەك</div>
            <div class="btn">
                <div class="btn-refresh" @click="loadList">
                    <i class="iconfont icon-shuaxin"></i>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="header">
                <div class="col id">ID</div>
                <div class="col adv-title">تەڭشەك ئىسمى</div>
                <div class="col adv-content">رەسىمى</div>
                <div class="col adv-time">ئەڭ ئاخىرقى قىتىم ئۆزگەرتكەن ۋاقىت</div>
                <div class="col opr">مەشغۇلات</div>
            </div>
            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="col id">{{item.id}}</div>
                <div class="col adv-title">{{item.label}}</div>
                <div class="col adv-content">
                    <img :src="item.v" :alt="item.label">
                </div>
                <div class="col adv-time">{{item.updated_at}}</div>
                <div class="col opr">
                    <div class="btn opr-btn" @click="toEdit(item.id,item.v,item.width,item.height)">
                        <i class="iconfont icon-bianji"></i>
                        <div class="word">تەھرىرلەش</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <toast ref="toast"/>
    <edit-modal v-if="editMoalShown" height="360px" title="تەھرىرلەش" @positive="updateContent" @hide="hideEditModal">
        <slot>
            <div class="upload-placeholder" @click="pickCover" v-if="imageInfo === null">
                <i class="iconfont icon-tupian"></i>
            </div>
            <div class="image">
                <img class="image-viewer" @click="pickCover" :src="imageInfo.url" v-if="imageInfo != null">
                <input type="file" style="display: none" accept="image/*" ref="coverInput" @change="coverInputAtChange">
            </div>
            <div style="text-align:center;margin-bottom:16px;color:#9e9e9e;" v-if="imageInfo.width && imageInfo.height ">كەڭلىك {{imageInfo.width}} پېكسىل، ئېگىزلىك {{imageInfo.height}} پېكسىل</div>
        </slot>
    </edit-modal>
</template>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    @icon-size: 40px;
    .adv-list {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;
        @title-height: 60px;

        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-refresh {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .btn-add {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-start: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .list {
            @opr-btn-size: 40px;
            padding: @margin-size;

            .header {
                width: 100%;
                height: @icon-size * 2;
                align-items: center;
                display: flex;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.adv-title {
                    flex: 2;
                }

                .col.adv-name {
                    flex: 1;
                }

                .col.adv-content {
                    flex: 4;
                }

                .col.adv-time {
                    flex: 2;
                    text-align: center;

                }

                .col.status {
                    flex: 1;
                }

                .col.opr {
                    flex: 1;
                    text-align: center;
                }
            }

            .item {
                width: 100%;
                display: flex;
                align-items: center;
                border-top: fade(@color-nice-black, 10%) 1px dashed;
                padding: @margin-size-small 0;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.adv-title {
                    flex: 2;
                }

                .col.adv-name {
                    flex: 1;
                }

                .col.adv-content {
                    flex: 4;
                    padding: 0 8px;
                    width: 200px;
                    height: 200px;
                    img {
                        width: 100%;
                        height: 200px;
                    }
                }

                .col.adv-time {
                    flex: 2;
                    text-align: center;
                    direction: ltr;
                }

                .col.status {
                    flex: 1;
                }


                .col.opr {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    flex-direction: row;

                    .btn {
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: transparent;
                        transition: background-color 200ms, color 200ms;
                        display: flex;

                        &:hover {
                            background: fade(@color-primary-dark, 6%);
                            color: @color-primary-dark;
                        }
                    }

                    .word {
                        padding-right: 6px;
                    }
                }
            }

        }
    }
    .image{
        width: 300px;
        height: 200px;
        margin: 16px auto;
        img{
            width: 100%;
            height: 100%;
        }
    }
</style>

<script>
    import Toast from "../../components/Toast";
    import {ImageSetting, update} from "../../api/setting";
    import EditModal from "../../components/EditModal";
    import {upload} from "../../api/common";

    let self

    export default {
        name: "ImageList",
        emits: ['showLoading'],
        components: {Toast, EditModal},
        data() {
            return {
                list: [],
                editMoalShown: false,
                content: "",
                id: 0,
                imageInfo:null
            }
        },
        created() {
            self = this
        },
        mounted() {
            self.loadList()
        },
        methods: {
            loadList() {
                ImageSetting().then(res =>
                    self.list = res)
            },
            toEdit(id, content,width,height) {
                self.id = id
                self.imageInfo = {
                    url:content,
                    width:width,
                    height:height
                }
                self.editMoalShown = true
            },
            hideEditModal() {
                self.id = 0
                self.content = ""
                self.editMoalShown = false
            },
            updateContent() {
                update({
                    id: self.id,
                    value: self.imageInfo.url
                }).then(res => {
                    self.hideEditModal()
                    self.$refs.toast.show(res, "success", "center")
                    self.loadList()
                })
            },
            pickCover() {
                self.$refs.coverInput.click()
            },
            coverInputAtChange(e) {
                if (e.target.files.length !== 1) {
                    return
                }
                let formData = new FormData()
                formData.append('image', e.target.files[0])
                formData.append('type', 'setting')
                formData.append('width', self.imageInfo.width)
                formData.append('height', self.imageInfo.height)
                upload(formData).then(res => {
                    if (res.msg) {
                        self.$refs.toast.show(res.msg, "danger", "center")
                        return
                    }
                    self.imageInfo = res
                })
            },  
        }
    }
</script>