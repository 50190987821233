import request from "../utils/Request";

export function create(obj) {
    return request({
        // url: '/api/admin/add-start-menu',
        url: 'admin/add-start-menu',
        method: 'post',
        data: obj,
    })
}


export function getList() {
    return request({
        // url: '/api/admin/start-menu-list',
        url: 'admin/start-menu-list',
        method: 'get',
    })
}

export function getDetail(id) {
    return request({
        // url: '/api/admin/start-menu-detail',
        url: 'admin/start-menu-detail',
        method: 'get',
        params: {
            id: id
        }
    })
}


export function updateStatus(obj) {
    return request({
        url: '/api/admin/start-menu-status-update',
        // url: 'admin/start-menu-status-update',
        method: 'post',
        data: obj
    })
}

export function update(obj) {
    return request({
        // url: '/api/admin/start-menu-update',
        url: 'admin/start-menu-update',
        method: 'post',
        data: obj
    })
}