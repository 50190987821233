<template>
    <div class="agreement wrapper">
        <div class="title">
            <div class="word">ئىشلىتىش كېلىشىمنامىسى</div>
        </div>
        <div class="content">
            <p>
                مەزكۇر سۇپىغا دۆلىتىمىزنىڭ ئالاقىدار قانۇن نىزاملىرىغا شۇنداقلا يەرلىك سىياسەتكە قارشى
                ھەرقانداق شەكىلدىكى مەزمۇننى يوللاش چەكلىنىدۇ.
            </p>
            <p>
                نەشىر ھوقوقى يوق بولغان مەزمۇنلارنى يوللاپ كېلىپ چىققان قانۇنى مەسئۇلىيەت ۋە ئىقتىسادىي ماجراغا نىسبەتەن ئاچقۇچى تەرەپ ھىچقانداق قانۇنىي ۋە ئىقتىسادى
                مەسئۇلىيەتنى ئۈستىگە ئالمايدۇ.
            </p>
            <p>
                سېرىق تۈستىكى مەزمۇنلارنى يوللاپ كېلىپ چىققان قانۇنى مەسئۇلىيەت ۋە باشقا ماجرالارغا نىسبەتەن ئاچقۇچى تەرەپ ھىچقانداق قانۇنىي ۋە ئىقتىسادى مەسئۇلىيەتنى
                ئۈستىگە ئالمايدۇ.
            </p>
            <p>
                جەمىئەتتە يامان تەسىر، خاتا كۆز قاراش، خاتا مۇنازىرە پەيدا قىلىدىغان مەزمۇنلارنى يوللاپ كېلىپ چىققان قانۇنى مەسئۇلىيەت ۋە ئىقتىسادىي ماجراغا نىسبەتەن
                ئاچقۇچى تەرەپ ھىچقانداق قانۇنىي ۋە ئىقتىسادى مەسئۇلىيەتنى ئۈستىگە ئالمايدۇ.
            </p>
            <p>
                سۇپىنىڭ ئىشلىتىش ۋە باشقۇرۇش ھوقوقى ئىشلەتكۈچى تەرەپتە بولۇپ ئاچقۇچى تەرەپ ھەرقانداق قەدەم باسقۇچقا ئارلاشمايدۇ، يوللانغان بارلىق مەزمۇن پەقەت
                ئىشلەتكۈچى تەرەپنىڭ قىزىقىشى ۋە مەيدانىنى ئىپادىلەيدۇ، ئاچقۇچى تەرەپنىڭ قىزىقىشى ۋە مەيدانى بىلەن مۇناسىۋەتسىز.
            </p>
            <p>
                تور دۇنياسى قانۇن سىرتىدىكى دۇنيا ئەمەس، ئۇمۇ قانۇننىڭ بىردەك قوغداش ۋە جازالاش دائىرىسىدىكى ئاممىۋىي سورۇن بولۇپ، ئىشلەتكۈچى تەرەپ مۇناسىۋەتلىك قانۇن
                تۈزۈم ۋە سىياسەتكە بوي سۇنغان ئاساستا مەزكۇر سۇپىنى ئىشلىتىشى كېرەك، قانۇن-تۈزۈم ۋە سىياسەتتىن چەتنەپ كەتكەن، قانۇنسىز، ئەخلاقسىز، سېرىق تۈستىكى مەزمۇنلارنى يوللاش پۈتۈنلەي چەكلىنىدۇ.
            </p>
            <p>
                ئۈستىدە دىيىلگەن ۋە دىيىلمىگەن ھەرقانداق شەكىلدىكى قانۇنغا ، جامائەت ئەخلاقىغا خىلاپ بولغان مەزمۇنلارنى يوللاش بىردەك چەكلىنىدۇ، ئەگەر شۇنداق مەزمۇنلار
                يوللىنىپ قالسا بۇ پۈتۈنلەي ئىشلەتكۈچى تەرەپ بىر تەرەپنىڭ مەسئۇلىيىتى، ئاچقۇچى تەرەپ بىلەن مۇناسىۋەتسىز.</p>
            <p>
                مەزكۇر سۇپىنى ئاچقۇچى تەرەپ، مەزمۇنغا قارىتا قەرەلسىز ھالدا تەكشۈرۈپ تۇرۇشقا ھوقوقلۇق، ناۋادا ئاچقۇچى تەرەپ بىنورمال مەزمۇننىڭ بارلىقى بايقىسا،
                ئىشلەتكۈچى تەرەپنى خەۋەرلەندۈرۈپ قويمايلا تازلىۋېتىشكە ھوقوقلۇق.</p>
            <p>
                مەزكۇر سۇپىنى ئاچقۇچى تەرەپ ئۈندىدار، QQ، 钉钉 ۋە باشقا ئالاقە ۋاستىلىرىدا ئىشلەتكۈچى بىلەن كېلىشكەن باشقا كېلىشىم مەزمۇنىمۇ ئوخشاشلا قانۇنى كۈچكە
                ئېگە.
            </p>
            <p>
                مەزكۇر سۇپىنى ئىشلەتكىنىڭىز يۇقارقى كېلىشىمگە قوشۇلغانلىقىڭىزنى بىلدۈرىدۇ. كېلىشىمگە خىلاپ ھالدا ئېلىپ بارغان ھەرقانداق مەشغۇلاتىىڭىز پەقەت ئىشلەتكۈچى
                بولغان سىزنىڭ مەيدانىڭىز ۋە قىزىقىشىڭىزنى ئىپادىلەيدۇ، ئاچقۇچى تەرەپ بىلەن مۇناسىۋەتسىز.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Agreement",
        emits: ['showLoading']
    }
</script>

<style lang="less" scoped>
    @import "../assets/less/global.less";
    @import "../assets/less/anim.less";

    @icon-size: 40px;
    .agreement {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;
        @title-height: 60px;

        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }
        }

        .content {
            @opr-btn-size: 40px;
            padding: @margin-size;

            p {
                text-align: justify-all;
                line-height: 1.5;
                color: @color-primary-text;
            }
        }
    }
</style>