<template>
    <div class="select-box" :class="{'expended' : drawerOpen}" v-clickOutside="closeDrawer">
        <div class="box" @click="switchDrawerState">{{screenContent}}</div>
        <div class="chevron">
            <i class="iconfont icon-arrow-right-bold"></i>
        </div>
        <div class="drawer" :style="drawerStyle" v-if="drawerOpen">
            <div class="option" v-for="(item,index) in options" :key="index" @click="selectThis(item,index)">{{item}}</div>
        </div>
    </div>
</template>

<script>
    const clickOutside = {
        mounted(el, binding) {
            function documentHandler(e) {
                if (el.contains(e.target)) {
                    return false;
                }
                if (binding.value) {
                    binding.value();
                }
            }

            // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
            el.__vueClickOutside__ = documentHandler;
            document.addEventListener('click', documentHandler);
        },
        unmounted(el) {
            // 解除事件监听
            document.removeEventListener('click', el.__vueClickOutside__);
            delete el.__vueClickOutside__;
        },
    };
    export default {
        name: "SelectBox",
        emits: ['select'],
        directives: {clickOutside},
        props: {
            value: {
                type: String,
                default: ''
            },
            options: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {
                drawerOpen: false
            }
        },
        computed: {
            screenContent() {
                return this.value === '' ? 'تاللاڭ' : this.value
            },
            drawerStyle() {
                let bot = -36 * this.options.length
                return {
                    bottom: bot + 'px'
                }
            }
        },
        methods: {
            switchDrawerState() {
                this.drawerOpen = !this.drawerOpen
            },
            closeDrawer() {
                this.drawerOpen = false
            },
            selectThis(selectedItem, index) {
                this.$emit('select', {
                    selectedItem: selectedItem,
                    index: index
                })
                this.closeDrawer()
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../assets/less/global.less";

    @inputHeight: 36px;
    .select-box {
        user-select: none;
        cursor: pointer;
        box-sizing: border-box;
        padding: 0 @margin-size;
        height: @inputHeight;
        line-height: @inputHeight;
        border-radius: @inputHeight / 2;
        background: @color-nice-bg;
        display: flex;
        position: relative;

        .box {
            height: @inputHeight;
            flex: 1;
            position: relative;
        }

        .chevron {
            position: absolute;
            left: 0;
            top: 0;
            width: @inputHeight;
            height: @inputHeight;
            line-height: @inputHeight;
            transform: rotate(180deg);
            transition: transform 200ms;
            text-align: center;
        }

        .drawer {
            position: absolute;
            z-index: 2;
            width: 100%;
            left: 0;
            bottom: -@inputHeight;
            border-radius: 0 0 @inputHeight / 2 @inputHeight / 2;
            background: @color-nice-bg;
            max-height: @inputHeight * 8;
            overflow-y: scroll;

            .option {
                height: @inputHeight;
                padding: 0 @margin-size;
                transition: background-color 200ms, color 200ms;

                &:last-child {
                    border-radius: 0 0 @inputHeight / 2 @inputHeight / 2;
                }

                &:hover {
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                }
            }
        }

        &.expended {
            border-radius: @inputHeight / 2 @inputHeight / 2 0 0;

            .drawer {
                box-shadow: fade(@color-nice-black, 6%) 0 @margin-size-small @margin-size;
            }

            .chevron {
                transform: rotate(90deg);
            }
        }


    }
</style>