import request from "../utils/Request";

export function getList(obj) {
    return request({
        // url: '/api/admin/article-list',
        url: 'admin/article-list',
        method: 'get',
        params: obj
    })
}

export function getWarningList(obj) {
    return request({
        // url: '/api/admin/article-warning',
        url: 'admin/article-warning',
        method: 'get',
        params: obj
    })
}

export function search(obj) {
    return request({
        // url: '/api/admin/article-search',
        url: 'admin/article-search',
        method: 'get',
        params: obj
    })
}

export function articleSearch(obj) {
    return request({
        // url: '/api/admin/article-list-search',
        url: 'admin/article-list-search',
        method: 'get',
        params: obj
    })
}

export function updateStatus(obj) {
    return request({
        // url: '/api/admin/article-update-status',
        url: 'admin/article-update-status',
        method: 'post',
        data: obj
    })
}

export function updateRecommend(obj) {
    return request({
        // url: '/api/admin/article-update-recommend-status',
        url: 'admin/article-update-recommend-status',
        method: 'post',
        data: obj
    })
}


export function remove(id) {
    return request({
        // url: '/api/admin/article-delete',
        url: 'admin/article-delete',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function getOptions() {
    return request({
        // url: '/api/admin/article-add-options',
        url: 'admin/article-add-options',
        method: 'get'
    })
}

export function create(obj) {
    return request({
        // url: '/api/admin/article-add',
        url: 'admin/article-add',
        method: 'post',
        data: obj,
    })
}

export function getDetail(id) {
    return request({
        // url: '/api/admin/article-detail',
        url: 'admin/article-detail',
        method: 'get',
        params: {
            id: id
        }
    })
}

export function update(obj) {
    return request({
        // url: '/api/admin/article-update',
        url: 'admin/article-update',
        method: 'post',
        data: obj,
    })
}

export function commentList(obj) {
    return request({
        // url: '/api/admin/comment-list',
        url: 'admin/comment-list',
        method: 'get',
        params: obj
    })
}

export function commentUpdate(obj) {
    return request({
        // url: '/api/admin/comment-status-update',
        url: 'admin/comment-status-update',
        method: 'get',
        params: obj
    })
}

export function commentDelete(obj) {
    return request({
        // url: '/api/admin/comment-delete',
        url: 'admin/comment-delete',
        method: 'get',
        params: obj
    })
}


export function updateShareToGroup(obj) {
    return request({
        // url: '/api/admin/article-update-share-to-group-status',
        url: 'admin/article-update-share-to-group-status',
        method: 'post',
        data: obj
    })
}