<template>
    <div class="mask" id="mask">
        <div class="container" :style="{ height: height }">
            <div class="title">{{ title }}</div>
            <slot></slot>
            <div class="footer">
                <div class="btn" @click="positive">
                    مۇقىملاش
                </div>
                <div class="btn" @click="hideModal">
                    بىكار قىلىش
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    let self;
    export default {
        created() {
            self = this;
        },
        props: {
            title: {
                type: String,
                value: ""
            },
            height: {
                type: String,
                value: ""
            }
        },
        methods: {
            hideModal() {
                self.$emit("hide");
            },
            positive(){
                self.$emit('positive')
            }
        }
    };
</script>
<style lang="less" scoped>
    @import "../assets/less/global.less";
    .mask {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(2px);
        top: 0;
        left: 0;
        .container {
            position: relative;
            width: 600px;
            height: 500px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            padding: @margin-size;
            border-radius: @margin-size;
            .title {
                font-size: @font-size-large;
                text-align: center;
                color: transparent;
                background: @color-primary;
                background-size: 120px;
                -webkit-background-clip: text;
            }
            .footer {
                position: fixed;
                bottom: @margin-size;
                left: 0;
                right: 0;
                text-align: center;
                display: flex;
                .btn {
                    flex: 1;
                    cursor: pointer;
                    padding: 16px;
                }
            }
        }
    }
</style>