<template>
  <div class="profile wrapper">
    <div class="title">
      <div class="word">ئارخىب تەھرىرلەش</div>
      <!-- <div class="btn">
        <div class="btn-save" @click="save">ساقلاش</div>
      </div> -->
      <div class="btn">
        <div class="btn-back" @click="toBack">
          <i class="iconfont icon-arrow-down"></i>
        </div>
      </div>
    </div>
    <div class="form">
      <div class="info">
        <div class="top">
          <div class="avatar">
            <img
              class="image-viewer"
              @click="pickImage"
              :src="avatarUrl"
              :alt="nickName"
            />
          </div>
          <div class="name">
            <div class="userName">{{ nickName }}</div>
            <div class="userNameInfo">باشقۇرغۇچى</div>
          </div>
        </div>
        <div class="phone">
          <div class="phone-text">تىلغۇن نومۇر</div>
          <div class="phone-number">{{ phone }}</div>
        </div>
        <div
          class="password"
          :class="{ active: type === 0 }"
          @click="switchType(0)"
        >
          <i class="iconfont icon-arrow-down"></i>
          <span>ئارخىب تەھىرلەش</span>
        </div>
        <div
          class="updetPassword"
          :class="{ active: type === 1 }"
          @click="switchType(1)"
        >
          <i class="iconfont icon-arrow-down"></i>
          <span>پارول ئۆزگەرتىش</span>
        </div>
      </div>
      <div class="body">
        <div class="row" v-show="type === 0">
          <div class="col">
            <label>باشقۇرغۇچى نامىڭىز</label>
            <label>
              <input
                class="input"
                type="text"
                placeholder="تور نامىڭىزنى كىرگۈزۈڭ"
                v-model="nickName"
                ref="title"
              />
            </label>
          </div>
          <div class="col">
            <label>يانفۇن نومۇرىڭىز</label>
            <label>
              <input
                class="input"
                type="text"
                placeholder="يانفۇن نومۇرىڭىزنى كىرگۈزۈڭ"
                v-model="phone"
                ref="phone"
              />
            </label>
          </div>
        </div>
        <div class="row" v-show="type === 0">
          <div class="col">
            <label>
              <span>باش سۆرەت</span>
              <helper :content="imageHelp" />
            </label>
            <div
              class="upload-placeholder"
              @click="pickImage"
              v-if="imageInfo === null"
            >
              <i class="iconfont icon-tupian"></i>
            </div>
            <img
              class="image-viewer"
              @click="pickImage"
              :src="imageInfo.url"
              v-if="imageInfo != null"
              alt="adv image"
            />
            <input
              type="file"
              accept="image/*"
              ref="imageInput"
              @change="imageInputAtChange"
            />
          </div>
        </div>
        <div class="row" v-show="type === 1">
          <div class="col">
            <label>
              <span>ھازىرقى پارولىڭىز</span>
            </label>
            <label>
              <input
                class="input"
                type="password"
                autocomplete="off"
                placeholder="نۆۋەتتە ئىشلىتىۋاتقان پارولىڭىزنى كىرگۈزۈڭ"
                v-model="getPassword"
                ref="password"
              />
            </label>
          </div>
        </div>
        <div class="row" v-show="type === 1">
          <div class="col">
            <label>
              <span>يېڭى پارولىڭىز</span>
            </label>
            <label>
              <input
                class="input"
                type="password"
                autocomplete="off"
                placeholder="ئالماشتۇرماقچى بولغان يىڭى پارولىڭىزنى كىرگۈزۈڭ"
                v-model="newPassword"
                ref="newPassword"
              />
            </label>
          </div>
          <div class="col">
            <label>
              <span>يېڭى پارولىڭىز</span>
            </label>
            <label>
              <input
                class="input"
                type="password"
                autocomplete="off"
                placeholder="ئالماشتۇرماقچى بولغان يىڭى پارولىڭىزنى قايتا كىرگۈزۈڭ"
                v-model="getNewPassword"
                ref="getNewPassword"
              />
            </label>
          </div>
        </div>
        <div class="btn" v-show="type === 1">
          <div class="btn-save" @click="selecetPassword">ساقلاش</div>
          <div class="btn-back" @click="toBack">بىكار قىلىش</div>
        </div>
        <div class="btn" v-show="type === 0">
          <div class="btn-save" @click="updateInfo">ساقلاش</div>
          <div class="btn-back" @click="toBack">بىكار قىلىش</div>
        </div>
      </div>
    </div>
  </div>
  <toast ref="toast" position="center" type="danger" />
</template>

<script>
import { upload, updateAdminInfo } from "../api/common";

let self;
import Helper from "../components/Helper";
import Toast from "../components/Toast";

export default {
  name: "Profile",
  components: { Toast, Helper },
  created() {
    self = this;
    let loginData = localStorage.getItem("loginData");
    loginData = JSON.parse(loginData);
    self.avatarUrl = loginData.avatar;
    self.nickName = loginData.user_name;
    self.phone = loginData.phone;
    self.adminId = loginData.id;
  },
  data() {
    return {
      nickName: "",
      phone: "",
      imageInfo: null,
      avatarUrl: "",
      imageHelp: "باش سۆرەت رەسىمىڭىزنى چىقىرىڭ",
      type: 0,
      getPassword: "",
      newPassword: "",
      getNewPassword: "",
      adminId: 0,
    };
  },
  methods: {
    toBack() {
      self.$router.back();
    },
    save() {},
    pickImage() {
      self.$refs.imageInput.click();
    },
    imageInputAtChange(e) {
      if (e.target.files.length !== 1) {
        return;
      }
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("type", "profile");
      upload(formData).then((res) => (self.imageInfo = res));
    },
    switchType(type) {
      self.type = Number(type);
    },
    selecetPassword() {
      if (self.getPassword === "") {
        self.$refs.toast.show("نوۋەتتە ئىشلىتىۋاتقان پالولىڭىزنى كىرگۈزۈڭ");
        self.$refs.password.focus();
        return;
      }
      if (self.newPassword === "") {
        self.$refs.toast.show(
          "ئالماشتۇرماقچى بولغان يىڭى پارولىڭىزنى كىرگۈزۈڭ"
        );
        self.$refs.newPassword.focus();
        return;
      }
      if (self.getNewPassword === "") {
        self.$refs.toast.show(
          "ئالماشتۇرماقچى بولغان يىڭى پارولىڭىزنى قايتا كىرگۈزۈڭ"
        );
        self.$refs.getNewPassword.focus();
        return;
      }
      if(self.newPassword.length < 6){
      self.$refs.toast.show(
          "يىڭى پارولىڭىزنىڭ ئۇزۇنلۇقى ئالتە خانىدىن كەم بولمىسۇن"
        );
        self.$refs.newPassword.focus();
        return;
      }
      if (self.newPassword != self.getNewPassword) {
        self.$refs.toast.show(
          "ئالماشتۇرماقچى بولغان يىڭى پارولىڭىزنى توغرا كىرگۈزۈڭ"
        );
        self.$refs.newPassword.focus();
        return;
      }
      let data = {
        id: self.adminId,
        old_password: self.getPassword,
        password: self.newPassword,
      };
      updateAdminInfo(data).then((res) => {
        console.log(res);
        if (res.err == 0) {
          localStorage.removeItem("loginData");
          self.$router.replace("/login");
        } else {
          self.$refs.toast.show(res.msg, "danger");
        }
      });
    },
    updateInfo() {
      if (self.nickName === "") {
        self.$refs.toast.show("تور نامىڭىزنى تولدۇرۇڭ");
        self.$refs.title.focus();
        return;
      }
      if (self.phone === "" || self.phone.length != 11) {
        self.$refs.toast.show("يانفۇن نومۇرىڭىزنى تولدۇرۇڭ");
        self.$refs.phone.focus();
        return;
      }
      if (self.imageInfo === null) {
        self.$refs.toast.show("باش سۈرەت رەسىمنى تاللاڭ");
        return;
      }
      let data = {
        id: self.adminId,
        username: self.nickName,
        phone: self.phone,
        avatar: self.imageInfo.path,
      };
      updateAdminInfo(data).then((res) => {
        self.$refs.toast.show(res, "success");
        let loginData = localStorage.getItem("loginData");
        loginData = JSON.parse(loginData);
        loginData.avatar = self.imageInfo.url;
        loginData.phone = self.phone;
        loginData = JSON.stringify(loginData);
        localStorage.setItem("loginData", loginData);
        location.reload();
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../assets/less/global.less";
@import "../assets/less/anim.less";

.profile {
  direction: rtl;
  max-width: @wrap-width;
  min-width: @wrap-min-width;
  margin: @margin-size-large auto;
  border-radius: @radius-size;
  box-sizing: border-box;
  background: white;
  min-height: @wrap-min-height;
  @title-height: 60px;
  @inputHeight: 36px;

  .title {
    @btn-size: 40px;
    box-sizing: border-box;
    font-weight: bold;
    padding: 0 @margin-size;
    margin-bottom: @margin-size-large;
    border-bottom: fade(@color-nice-black, 10%) 1px solid;
    height: @title-height;
    line-height: @title-height;
    display: flex;

    .word {
      flex: 1;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn-back {
        width: @btn-size;
        height: @btn-size;
        line-height: @btn-size;
        text-align: center;
        border-radius: @radius-size;
        color: @color-primary;
        cursor: pointer;
        transform: rotate(90deg);
        transition: background-color 200ms;

        &:hover {
          background: fade(@color-nice-black, 4%);
        }
      }

      .btn-save {
        height: @btn-size;
        line-height: @btn-size - 4;
        background: fade(@color-primary-dark, 6%);
        color: @color-primary-dark;
        cursor: pointer;
        font-weight: normal;
        padding: 0 @margin-size;
        border-radius: @radius-size;
        margin-inline-end: @margin-size;
        transition: background-color 200ms, color 200ms;

        &:hover {
          background: @color-primary-dark;
          color: white;
        }
      }
    }
  }

  .form {
    padding: @margin-size;
    display: flex;

    .body {
      flex: 4;
    }

    .info {
      flex: 1;
      border: fade(@color-primary-dark, 20%) 1px solid;
      @ph-size: 110px;
      padding: 18px;
      margin-left: 32px;

      .top {
        display: flex;
        .avatar {
          flex: 1;

          .image-viewer {
            height: @ph-size;
            max-width: @ph-size;
            border-radius: @ph-size / 2;
          }
        }

        .name {
          flex: 2;
          padding-top: 30px;
          padding-right: 24px;

          .userName {
            font-size: 22px;
            font-weight: 500;
          }
          .userNameInfo {
            font-size: 18px;
            color: #5f5f5f;
            padding-top: 12px;
          }
        }
      }
      .phone {
        display: flex;
        padding-top: 20px;

        .phone-number {
          flex: 1;
        }
        .phone-text {
          flex: 1;
        }
      }
      .password {
        width: 100%;
        height: 50px;
        line-height: 45px;
        margin-top: 32px;

        &:hover {
          transition: color 0.3s ease;
          background: #f2f3f7;
          border-radius: 4px;
          color: #5d78ff;
          cursor: pointer;
        }
        &.active {
          transition: color 0.3s ease;
          background: #f2f3f7;
          border-radius: 4px;
          color: #5d78ff;
          cursor: pointer;
        }
        .iconfont {
          padding-left: 12px;
        }
      }

      .updetPassword {
        width: 100%;
        height: 50px;
        line-height: 45px;
        margin-top: 24px;

        &:hover {
          transition: color 0.3s ease;
          background: #f2f3f7;
          border-radius: 4px;
          color: #5d78ff;
          cursor: pointer;
        }
        &.active {
          transition: color 0.3s ease;
          background: #f2f3f7;
          border-radius: 4px;
          color: #5d78ff;
          cursor: pointer;
        }
        .iconfont {
          padding-left: 12px;
        }
      }
    }

    .row {
      display: flex;
      margin-bottom: @margin-size-xl;

      .col {
        flex: 1;

        &.col-1 {
          flex: 1;
        }

        &.col-2 {
          flex: 2;
        }

        &.col-3 {
          flex: 3;
        }

        label {
          display: block;
          margin-bottom: @margin-size;
        }

        input.input {
          width: 74%;
          height: @inputHeight;
          border-radius: @inputHeight / 2;
          padding: 0 @margin-size;
          border: fade(@color-primary-dark, 20%) 1px solid;
          box-sizing: border-box;
          margin-left: @margin-size;

          &:focus {
            border-color: fade(@color-primary-dark, 40%);
          }

          &.mn {
            width: 30%;
          }

          &.sm {
            width: 60%;
          }

          &.btn {
            cursor: pointer;
          }

          &.ltr {
            direction: ltr;
          }
        }

        input[type="file"] {
          display: none;
        }

        @ph-size: 160px;

        .upload-placeholder {
          width: @ph-size;
          height: @ph-size;
          line-height: @ph-size;
          text-align: center;
          box-sizing: border-box;
          border-radius: @ph-size / 2;
          border: fade(@color-primary-dark, 40%) 1px dashed;
          cursor: pointer;

          i {
            font-size: @font-size-xxxl;
            color: fade(@color-primary-dark, 40%);
          }
        }

        .image-viewer {
          height: @ph-size;
          max-width: @ph-size;
          border-radius: @ph-size / 2;
        }
      }
    }
    .btn {
      align-items: center;
      display: flex;
      margin-top: 30px;

      .btn-save {
        height: 40px;
        line-height: 40px;
        width: 50px;
        background: fade(@color-primary-dark, 6%);
        color: @color-primary-dark;
        cursor: pointer;
        font-weight: normal;
        padding: 0 @margin-size;
        border-radius: @radius-size;
        margin-inline-end: @margin-size;
        transition: background-color 200ms, color 200ms;
        background: @color-primary-dark;
        color: white;

        &:hover {
          background: @color-primary-dark;
          color: white;
        }
      }

      .btn-back {
        height: 40px;
        line-height: 40px;
        width: 70px;
        background: fade(@color-accent, 6%);
        color: @color-accent;
        cursor: pointer;
        font-weight: normal;
        padding: 0 @margin-size;
        border-radius: @radius-size;
        margin-inline-end: @margin-size;
        transition: background-color 200ms, color 200ms;
        background-color: #fd397a;
        border-color: #fd397a;
        color: #fff;

        &:hover {
          background-color: #fd397a;
          border-color: #fd397a;
          color: #fff;
        }
      }
    }
  }
}
</style>