<template>
    <div class="paginator" >
        <div class="pages" v-if="paginationTotal">
            <div
                    @click="pageUp(0)"
                    class="page"
                    :class="startDisabled ? 'disabled' : ''"
                    style="width:88px">
                باش بەت
            </div>
            <div
                    @click="pageUp(1)"
                    class="page"
                    :class="startDisabled ? 'disabled' : ''"
            >
                <span class="iconfont icon-arrow-left-copy"></span>
            </div>
            <div
                    class="page"
                    :class="item === paginationCurrentPage ? 'active' : ''"
                    v-for="item in paginationLength"
                    :key="item"
                    @click="jump(item)"
            >
                {{ item }}
            </div>
            <div
                    @click="pageDown(1)"
                    class="page"
                    :class="endDisabled ? 'disabled' : ''"
            >
                <span class="iconfont icon-arrow-right"></span>
            </div>
            <div
                    @click="pageDown(0)"
                    class="page"
                    :class="endDisabled ? 'disabled' : ''"
                    style="width:88px">
                ئاخىرقى بەت
            </div>
        </div>
        <div class="meta-info">
            جەمئىي: {{ paginationPage }}بەت | {{ total }} دانە
        </div>
    </div>
</template>

<script>
    export default {
        name: "pagination",
        props: ["total", "size", "page", "change", "isUrl"],
        data() {
            return {
                paginationTotal: this.total, //总条目数
                paginationSize: this.size ? this.size : 10, //每页显示条目个数
                paginationLength: [],
                paginationCurrentPage: this.page ? this.page : 1, //当前页数默认1
                paginationPage: 0, //可分页数
                startDisabled: true, //是否可以点击首页上一页
                endDisabled: true, //是否可以点击尾页下一页
                pageChange: this.change, //修改方法
                pageIsUrl: false, //是否开启修改url
            };
        },
        methods: {
            jump(item) {
                this.paginationCurrentPage = item;
                this.pagers();
                this.pageChange(this.paginationCurrentPage);
            }, //跳转页码
            pagers() {
                //可分页数
                this.paginationPage = Math.ceil(
                    this.paginationTotal / this.paginationSize
                );
                //url修改
                if (this.pageIsUrl) {
                    this.$router.replace({
                        path: this.$route.path,
                        query: {
                            page: this.paginationCurrentPage,
                        },
                    });
                }
                //是否可以点击上一页首页
                this.startDisabled = this.paginationCurrentPage != 1 ? false : true;
                //是否可以点击下一页尾页
                this.endDisabled =
                    this.paginationCurrentPage != this.paginationPage ? false : true;
                if (this.paginationPage === 0) this.endDisabled = true;

                //重置
                this.paginationLength = [];
                //开始页码1
                let start =
                    this.paginationCurrentPage - 4 > 1
                        ? this.paginationCurrentPage - 4
                        : 1;
                //当前页码减去开始页码得到差
                let interval = this.paginationCurrentPage - start;
                //最多9个页码，总页码减去interval 得到end要显示的数量+
                let end =
                    9 - interval < this.paginationPage
                        ? 9 - interval
                        : this.paginationPage;
                //最末页码减开始页码小于8
                if (end - start != 8) {
                    //最末页码加上与不足9页的数量，数量不得大于总页数
                    end =
                        end + (8 - (end - start)) < this.paginationPage
                            ? end + (8 - (end - start))
                            : this.paginationPage;
                    //最末页码加上但是还不够9页，进行开始页码追加，开始页码不得小于1
                    if (end - start != 8) {
                        start = end - 8 > 1 ? end - 8 : 1;
                    }
                }
                for (let i = start; i <= end; i++) {
                    this.paginationLength.push(i);
                }
            }, //计算分页显示的数字
            pageUp(state) {
                if (this.startDisabled) return;
                if (this.paginationCurrentPage - 1 != 0 && state == 1) {
                    this.jump(this.paginationCurrentPage - 1);
                } else {
                    this.jump(1);
                }
            }, //上一页跟首页 state=1是上一页 state=0是首页
            pageDown(state) {
                if (this.endDisabled) return;
                if (
                    this.paginationCurrentPage + 1 != this.paginationPage &&
                    state == 1
                ) {
                    this.jump(this.paginationCurrentPage + 1);
                } else {
                    this.jump(this.paginationPage);
                }
            }, // state=1是下一页 state=0是尾页
            pageCurrentChange() {
                this.pageChange(this.paginationCurrentPage);
                this.pagers();
            },
        },
        created() {
            this.pageCurrentChange();
        },
        watch: {
            total: function (val) {
                this.paginationTotal = val;
                this.pagers();
            },
            page: function (val) {
                this.paginationCurrentPage = val;
                this.pagers();
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "../assets/less/global.less";
    @paginator-height: 50px;
    @paginator-size: 30px;
    .paginator {
        height: @paginator-height;
        line-height: @paginator-height;
        box-sizing: border-box;
        padding: 0 @margin-size;
        background: white;
        margin: 0 @margin-size;
        border-radius: @radius-size;
        display: flex;
        box-shadow: fade(@color-nice-black, 2%) 0 @margin-size-mini @margin-size;
        user-select: none;
        .pages {
            flex: 1;
            .page {
                display: inline-block;
                width: @paginator-size;
                height: @paginator-size;
                line-height: @paginator-size;
                text-align: center;
                margin-top: 5px;
                margin-right: @margin-size;
                border-radius: @paginator-size / 2;
                color: fade(@color-nice-black, 60%);
                cursor: pointer;
                &:hover {
                    color: fade(@color-nice-black, 90%);
                }
                &.active {
                    background: @color-primary;
                    color: white;
                    cursor: default;
                }
                &.disabled {
                    color: #e4e4e4 !important;
                    background-color: #fff;
                    cursor: not-allowed;
                }
            }
        }
        .meta-info {
            color: fade(@color-nice-black, 30%);
        }
    }
</style>