<template>
    <div class="adv-list wrapper">
        <div class="title">
            <div class="word">بارلىق ئەزالار</div>
            <div class="btn">
                <div class="search-box">
                    <label>
                        <input type="text" placeholder="تور نامىنى كىرگۈزۈپ ئىزدەڭ" maxlength="36" v-model="keyword"
                               @keydown="keywordInputAtKeydown">
                    </label>
                    <div class="btn-search" @click="search">
                        <i class="iconfont icon-sousuo"></i>
                    </div>
                </div>
            </div>
            <div class="btn">
                <div class="btn-refresh" @click="loadList">
                    <i class="iconfont icon-shuaxin"></i>
                </div>
            </div>
            <!--<div class="btn">-->
            <!--<div class="btn-add" @click="toAdd">قوشۇش</div>-->
            <!--</div>-->
        </div>
        <div class="list">
            <div class="header">
                <div class="col id">ID</div>
                <div class="col cover">باش سۆرىتى</div>
                <div class="col adv-title">تور نامى</div>
                <div class="col adv-title">ئەزالىق ۋاقتى</div>
                <div class="col adv-title">قارا تىزىملىكتىمۇ؟</div>
                <div class="col income">كىرىم ئەھۋالى</div>
                <div class="col opr">مەشغۇلات</div>
            </div>

            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="col id">{{item.id}}</div>
                <div class="col cover">
                    <img :src="item.avatar" @error="handleImageError(index)" :alt="item.nick_name">
                </div>
                <div class="col adv-title">{{item.nick_name}}</div>
                <div class="col adv-title" v-if="Number(item.is_vip) === 0">{{item.level_tag}}</div>
                <div class="col adv-title time" v-if="Number(item.is_vip) === 1">{{item.vip_expire_at}}</div>
                <div class="col adv-title">
                    <Switch
                            :status="item.is_black === 1"
                            @update="switchState(item.open_id, item.is_black, index)"
                    />
                </div>
                <div class="col income">{{item.income}}</div>
                <div class="col opr">
                    <operation>
                        <slot>
                            <div class="btn opr-btn" @click="profitModalshow(item.profit_percent,item.open_id)">
                                <i class="iconfont icon-icon-test"></i>
                                <div class="word">ئۇچۇر ئۆزگەرتىش</div>
                            </div>
                            <div class="btn opr-btn" @click="toCopy(item.id)">
                                <i class="iconfont icon-fuzhi"></i>
                                <div class="word">ID كۆچۈرۈش</div>
                            </div>
                        </slot>
                    </operation>
                </div>
            </div>

        </div>
        <div class="paginator" v-if="paginatorShown">
            <paginator :total="totalRecord" :page="curPage" :size="pageSize" :change="switchPage"/>
        </div>
    </div>
    <!--<profit-modal ref="confirmBox" :open_id="open_id" :profit_percent="inputValue" @hide="btnConfirm"/>-->
    <edit-modal v-if="editMoalShown" height="320px" title="ئۇچۇر ئۆزگەرتىش" @positive="updateProfitPercent" @hide="hideEditModal">
        <slot>
            <div>پايدا پىرسەنتى<input type="number" class="pretty-input" v-model="profitPercent" /></div>
            <div>ئەزالىق ۋاقتى قوشۇش (كۈن)<input type="number" class="pretty-input" v-model="vipDay" /></div>

        </slot>
    </edit-modal>
    <toast ref="toast" position="center" type="danger"/>
</template>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    @icon-size: 40px;
    @title-height: 60px;
    .adv-list {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;


        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-refresh {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .search-box {
                    width: @btn-size * 8;
                    height: @btn-size;
                    background: fade(@color-nice-black, 4%);
                    border-radius: @btn-size / 2;
                    display: flex;
                    position: relative;

                    label {
                        flex: 1;
                        display: flex;

                        input {
                            box-sizing: border-box;
                            flex: 1;
                            background: none;
                            border: none;
                            padding: 0 @margin-size;
                            padding-inline-end: @icon-size;
                        }
                    }

                    .btn-search {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: @icon-size;
                        height: @icon-size;
                        line-height: @icon-size;
                        text-align: center;
                        cursor: pointer;
                        transition: color 200ms;

                        &:hover {
                            color: @color-primary;
                        }
                    }
                }

                .btn-add {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-start: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .list {
            @opr-btn-size: 40px;
            padding: @margin-size;

            .header {
                width: 100%;
                height: @icon-size * 2;
                align-items: center;
                display: flex;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.cover {
                    flex: 3;
                }

                .col.adv-title {
                    flex: 3;
                }

                .col.income {
                    flex: 2;
                }

                .col.opr {
                    flex: 1;
                    text-align: center;
                }
            }

            .item {
                width: 100%;
                display: flex;
                align-items: center;
                border-top: fade(@color-nice-black, 10%) 1px dashed;
                padding: @margin-size-small 0;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.cover {
                    flex: 3;

                    img {
                        height: 40px;
                    }
                }

                .col.adv-title {
                    flex: 3;
                }

                .col.adv-title.time {
                    direction: ltr;
                    text-align: right;
                }

                .col.income {
                    flex: 2;
                }


                .col.opr {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    flex-direction: row;

                    .btn {
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: transparent;
                        transition: background-color 200ms, color 200ms;
                        display: flex;

                        &:hover {
                            background: fade(@color-primary-dark, 6%);
                            color: @color-primary-dark;
                        }
                    }

                    .word {
                        padding-right: 6px;
                    }
                }
            }

        }
    }

    .paginator {
        direction: ltr;
        box-sizing: border-box;
        height: @title-height;
        line-height: @title-height;
        padding: 0 @margin-size-large;
    }
    .pretty-input{
        width: 100%;
        border: 1px solid #ccc;
        padding: 12px;
        margin: 0 auto;
        box-sizing: border-box;
        margin-top: 32px;
        border-radius: 8px;
    }
</style>

<script>
    import Toast from "../../components/Toast";
    import {getList, search, updateProfitPercent, updateStatus} from "../../api/user";
    import Paginator from "../../components/Paginator";
    import Operation from "../../components/Operation";
    import {copyToClipBoard} from '../../utils/Utils';
    import EditModal from "../../components/EditModal";
    import Switch from "../../components/Switch";

    let self
    let searching = false
    let requesting = false;
    export default {
        name: "UserList",
        emits: ['showLoading'],
        components: {Toast, Paginator, Operation,EditModal,Switch},
        data() {
            return {
                list: [],
                keyword: "",
                curPage: 1,
                pageSize: 10,
                totalRecord: 0,
                editMoalShown:false,
                profitPercent: 0,
                openId: "",
                vipDay:0
            }
        },
        created() {
            self = this
        },
        mounted() {
            self.loadList()
        },
        computed: {
            paginatorShown() {
                if (self.totalRecord === 0) return false
                return (self.totalRecord / self.pageSize) > 1
            }
        },
        watch: {
            keyword(val) {
                if (val.length === 0) {
                    searching = false
                    self.curPage = 1
                    self.loadList()
                }
            }
        },
        methods: {
            loadList() {
                getList({
                    page: self.curPage,
                    size: self.pageSize,
                })
                    .then((res => {
                        self.list = res.users
                        self.totalRecord = res.total  
                         self.pageSize = Number(res.pageSize);  
                    }))
            },
            switchState(id, status, index) {
                if (requesting) return;
                requesting = true;
                let nextStatus = status === 0 ? 1 : 0;
                updateStatus({
                    open_id: id,
                    is_black: nextStatus,
                }).then((res) => {
                    requesting = false;
                    self.list[index].is_black = nextStatus;
                    self.$refs.toast.show(res, "success", "center");
                });
            },
            handleImageError(index) {
                self.list[index].image = require('../../assets/logo.png')
            },
            toEdit(id) {
                self.$router.push({name: 'AdvEdit', params: {id: id}})
            },
            keywordInputAtKeydown(e) {
                if (e.code === 'Enter') self.search()
            },
            switchPage(val) {
                self.curPage = val;
                searching ? self.search() : self.loadList()
            },
            profitModalshow(profit_percent, open_id) {
                self.profitPercent = profit_percent
                self.openId = open_id
                self.editMoalShown = true
            },
            hideEditModal(){
                self.profitPercent = 0
                self.openId = ""
                self.editMoalShown = false
            },
            updateProfitPercent(){
                let data ={
                    open_id:self.openId,
                    percent:self.profitPercent,
                    vip_day:self.vipDay
                }
                updateProfitPercent(data).then((res)=>{
                    self.hideEditModal()
                    self.$refs.toast.show(res,'success','center')
                    self.loadList()
                })
            },
            search() {
                // if (self.keyword.length < 2) {
                //     searching = false
                //     return
                // }
                // if (!searching) {
                //     self.curPage = 1
                //     searching = true
                // }
                search({
                    page: self.curPage,
                    size: self.pageSize,
                    nick_name: self.keyword,
                    id:self.keyword
                }).then(res => {
                    self.curPage = res.page
                    self.pageSize = res.pageSize
                    self.totalRecord = res.total
                    self.list = res.list
                })
            },
            toCopy(id) {
                copyToClipBoard(id)
                self.$refs.toast.show('!كۆچۈرۈش مۇۋاپىقىيەتلىك بولدى')
            }
        }
    }
</script>
