<template>
    <div class="adv-create wrapper">
        <div class="title">
            <div class="word">{{ article_name }} غا يىڭى قىسىم قوشۇش</div>
        </div>
        <div class="form">
            <div class="row">
                <div class="col">
                    <label>ھالىتى</label>
                    <Switch :status="status" @update="updateStatus"/>
                </div>
                <div class="col">
                    <div>
                        <label>VIP تەلەپ قىلامدۇ؟</label>
                        <Switch
                                :status="vipStatus"
                                @update="updateVipStatus"
                                positive-text="ھەئە"
                                negative-text="ياق"
                        />
                    </div>
                </div>
                <div class="col">
                    <div v-show="vipStatus">
                        <label>يەككە باھاسى</label>
                        <label>
                            <input
                                    class="input"
                                    type="number"
                                    step="1"
                                    maxlength="2"
                                    max="99"
                                    placeholder="ئەسەر باھاسىنى كىرگۈزۈڭ"
                                    v-model="price"
                                    ref="price"
                            />
                        </label>
                    </div>
                </div>
                <div class="col" v-show="vipStatus">
                    <div>
                        <label>ئالىي ئەزامۇ پۇل تۆلەمدۇ؟</label>
                        <Switch
                                :status="isVipPurchase"
                                @update="updateIsVipPurchase"
                                positive-text="ھەئە"
                                negative-text="ياق"
                        />
                    </div>
                </div>
                <div class="col">
                    <div v-show="isVipPurchase">
                        <label>ئالىي ئەزا يەككە باھاسى</label>
                        <label>
                            <input
                                    class="input"
                                    type="number"
                                    step="1"
                                    maxlength="2"
                                    max="99"
                                    placeholder="ئەسەر ئالىي ئەزا باھاسىنى كىرگۈزۈڭ"
                                    v-model="vip_price"
                                    ref="vip_price"
                            />
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-1">
                    <label>
                        <span>قىسىم نامى</span>
                        <helper content="قۇرۇق قويسىڭىزمۇ بولىدۇ"/>
                    </label>
                    <label>
                        <input
                                class="input"
                                type="text"
                                placeholder="قىسىم نامىنى كىرگۈزۈڭ"
                                v-model="name"
                                ref="title"
                        />
                    </label>
                </div>
                <div class="col col-1">
                    <label>
                        <span>قىسىم تەرتىبى</span>
                        <helper content="قۇرۇق قويسىڭىزمۇ بولىدۇ"/>
                    </label>
                    <label>
                        <input
                                class="input"
                                type="text"
                                placeholder="قىسىم تەرتىبىنى كىرگۈزۈڭ"
                                v-model="sort"
                                ref="sort"
                        />
                    </label>
                </div>

            </div>
            <div class="col col-4"></div>
            <div class="row">

                <!--<div class="col" v-show="vipStatus">-->
                <!--<label>ئەسەر ھەقلىقمۇ؟</label>-->
                <!--<label>-->
                <!--<Switch-->
                <!--:status="isNotFree"-->
                <!--@update="updateIsNotFreeStatus"-->
                <!--positive-text="ھەئە"-->
                <!--negative-text="ياق"-->
                <!--/>-->
                <!--</label>-->
                <!--</div>-->

                <!--<div class="col">-->
                <!--<div v-show="vipStatus">-->
                <!--<label>يەككە باھاسى</label>-->
                <!--<label>-->
                <!--<input-->
                <!--class="input"-->
                <!--type="number"-->
                <!--step="1"-->
                <!--maxlength="2"-->
                <!--max="99"-->
                <!--placeholder="ئەسەر باھاسىنى كىرگۈزۈڭ"-->
                <!--v-model="price"-->
                <!--ref="sort"-->
                <!--/>-->
                <!--</label>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="col">-->
                <!--<div v-show="isNotFree">-->
                <!--<label>VIP مۇ پۇل تۆلەمدۇ؟</label>-->
                <!--<label>-->
                <!--<Switch-->
                <!--:status="isVipPurchase"-->
                <!--@update="updateIsVipPurchaseStatus"-->
                <!--positive-text="ھەئە"-->
                <!--negative-text="ياق"-->
                <!--/>-->
                <!--</label>-->
                <!--</div>-->
                <!--</div>-->
                <div class="col"></div>
            </div>
            <div class="row" v-if="type === 1">
                <div class="col">
                    <label>ئەسەر مەزمۇنى</label>
                    <editor v-model="content" :isClear="isClear" @change="change"></editor>
                </div>
            </div>
            <div class="row" v-if="type === 0">
                <div class="col">
                    <label>ئەسەر مەنبەسى</label>
                    <label>
                        <radio
                                :options="articleResources"
                                :chosen-index="chosenSourceIndex"
                                @update="updateChosenSourceIndex"
                        />
                    </label>
                </div>
                <div class="col col-2">
                    <div v-show="chosenSourceIndex === 0">
                        <label>
                            <span>ئۈندىدار ئەسەر تەرتىپ نومۇرى</span>
                            <helper content="wxv بىلەن باشلانغان تەرتىپ نومۇرنى كىرگۈزۈڭ"/>
                        </label>
                        <label>
              <textarea
                      class="input"
                      type="text"
                      placeholder="ئۈندىدار ئەسەر تەرتىپ نومۇرىنى كىرگۈزۈڭ"
                      v-model="articleWxvUrl"
                      ref="articleWxvUrl"
              />
                        </label>
                    </div>
                    <div v-show="chosenSourceIndex === 1">
                        <label>
                            <span>بىۋاستە قويۇش ئادرىسى</span>
                            <helper content="mp3 ئادرېسى تولدۇرسىڭىز بولىدۇ"/>
                        </label>
                        <label>
              <textarea
                      class="input"
                      type="text"
                      placeholder="ئەسەر ئادرېسىنى كىرگۈزۈڭ"
                      v-model="articleDirectUrl"
                      ref="articleDirectUrl"
              />
                        </label>
                    </div>

                    <div v-show="chosenSourceIndex === 2">
                        <label>
                            <span>سالون ماقالە ئادرىسى</span>
                            <helper
                                    content="مەزمۇنىدا ئەسەر بار بولغان سالون توپ ماقالە ئادرېسىنى كىرگۈزۈڭ"
                            />
                        </label>
                        <label>
                            <div class="list">
                                <div class="srech">
                                    <div class="srech-item">
                                        <div class="srech-word" @click="getResult">
                                            ئۇچۇرىغا ئىرشىش
                                        </div>
                                        <div v-if="showErrorIcon" class="input-error">
                                            <i v-if="showIcon" class="icon iconfont icon-duihao"></i>
                                            <i
                                                    v-if="showErrorIcon"
                                                    class="errorIcon iconfont icon-tanhao"
                                            ></i>
                                            <input
                                                    class="input-placeholder"
                                                    v-model="inputValue"
                                                    type="text"
                                                    placeholder="سالون تىمىېسى قىسقا ئادرېسىنى كىرگۈزۈڭ"
                                            />
                                        </div>
                                        <div v-if="!showErrorIcon" class="input">
                                            <i v-if="showIcon" class="icon iconfont icon-duihao"></i>
                                            <i
                                                    v-if="showErrorIcon"
                                                    class="errorIcon iconfont icon-tanhao"
                                            ></i>
                                            <input
                                                    class="input-placeholder"
                                                    v-model="inputValue"
                                                    type="text"
                                                    placeholder="سالون تىمىېسى قىسقا ئادرېسىنى كىرگۈزۈڭ"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="remind-text" v-if="showErrorIcon">
                                    سالون يازمىسى قىسقا ئادېرسىنى توغرا تولدۇرۇپ قايتا سىناڭ
                                </div>
                                <div class="result" v-if="showResult">
                                    <div class="salon-number">
                                        <div class="salon-info">
                                            {{ result.length }} دانە ئاۋاز
                                        </div>
                                    </div>
                                    <div class="content-list">
                                        <div class="content">
                                            <div
                                                    class="content-item"
                                                    v-for="(item, index) in result"
                                                    :key="index"
                                            >
                                                {{ item }}
                                            </div>
                                        </div>
                                        <i v-if="showIcon" class="icon iconfont icon-duihao"></i>
                                    </div>
                                    <div class="copy" @click="copy">
                                        <div class="copy-word">كۆچۈرۈش</div>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="btn">
                <div class="btn-save" @click="add">ساقلاش</div>
                <div class="btn-back" @click="toBack">بىكار قىلىش</div>
            </div>
        </div>
    </div>
    <toast ref="toast" position="center" type="danger"/>
</template>

<script>
    import Switch from "../../components/Switch";
    import Helper from "../../components/Helper";
    import Toast from "../../components/Toast";
    import Editor from "../../components/Editor"
    import {getSalonResult} from "../../api/common";
    import {copyToClipBoard} from "../../utils/Utils";
    import Radio from "../../components/Radio";
    import {getOptions} from "../../api/article";
    import {create, getList} from "../../api/series";

    let self;
    let requesting = false;
    export default {
        name: "SeriesCreate",
        emits: ["showLoading"],
        components: {Radio, Toast, Helper, Switch, Editor},
        created() {
            self = this;
            self.id = Number(self.$route.params.id);
            self.type = Number(self.$route.params.type);
            self.article_name = self.$route.params.article_name;
            self.getOptions();
            console.log(self.type)
        },
        data() {
            return {
                id: 0,
                type: 0,
                status: true,
                vipStatus: false,
                isNotFree: false,
                isVipPurchase: false,
                article_name: "",
                name: "",
                sort: "",
                articleArticleUrl: "",
                chosenTypeIndex: 0,
                articleResources: [],
                chosenSourceIndex: 0,
                articleDirectUrl: "",
                articleWxvUrl: "",
                orgOptions: null,
                price: "",
                vip_price:"",
                article_id: 0,
                showIcon: false,
                showResult: false,
                inputValue: "",
                result: [],
                showErrorIcon: false,
                advEnable: false,
                isCollection: 1,
                content: '',
                isClear: false,
                detail: ""
            };
        },
        computed: {
            source() {
                return self.orgOptions.source[self.chosenSourceIndex];
            },
            resultStr() {
                console.log(self.result);
                if (self.result.length === 0) return "";
                return this.result.join("\n");
            },
        },
        mounted() {
            self.loadList();
        },
        updated() {
            if (self.inputValue == "") {
                self.showErrorIcon = false;
                self.showResult = false;
                self.showIcon = false;
            }
        },
        methods: {
            change(val) {
                console.log(val)
                self.content = val
            },
            toBack() {
                self.$router.back();
            },
            getOptions() {
                getOptions().then((res) => {
                    self.orgOptions = res;
                    self.pairOptions();
                });
            },
            loadList() {
                getList({
                    article_id: self.id,
                }).then((res) => {
                    console.log(res)
                });
            },
            getResult() {
                let data = {
                    url: self.inputValue,
                    // is_collection: self.isCollection,
                };
                getSalonResult(data)
                    .then((res) => {
                        console.log(res)
                        if (res.length > 0) {
                            self.showResult = true;
                            self.showIcon = true;
                            self.result = res;
                        }
                    })
                    .catch(() => {
                        self.showErrorIcon = true;
                    });
            },

            copy() {
                copyToClipBoard(self.resultStr);
                self.$refs.toast.show("!كۆچۈرۈش مۇۋاپىقىيەتلىك بولدى");
                console.log(self.chosenSourceIndex);
                self.chosenSourceIndex = 0;
                self.articleWxvUrl = self.resultStr;
                self.showResult = false;
            },
            pairOptions() {
                self.orgOptions.source.forEach((source) =>
                    self.articleResources.push(source.name)
                );
                self.articleResources.push("سالون ماقالىسى");
                console.log(self.articleResources);
            },
            updateStatus() {
                self.status = !self.status;
            },
            updateVipStatus() {
                self.vipStatus = !self.vipStatus;
                if (self.vipStatus === true) {
                    self.isNotFree = true;
                } else {
                    self.isNotFree = false;
                    self.isVipPurchase = false
                }
            },
            updateIsNotFreeStatus() {
                self.isNotFree = !self.isNotFree;
            },
            updateIsVipPurchase() {
                self.isVipPurchase = !self.isVipPurchase;
            },
            updateChosenTypeIndex(e) {
                self.chosenTypeIndex = e.chosenIndex;
            },
            updateChosenSourceIndex(e) {
                self.chosenSourceIndex = e.chosenIndex;
            },
            add() {
                if (requesting) return;
                if (!self.validate()) return;
                let url = "";
                if (self.source.tag === "tencent") {
                    url = self.articleWxvUrl;
                } else if (self.source.tag === "direct") {
                    url = self.articleDirectUrl;
                }
                let data = {
                    status: self.status ? 1 : 0,
                    sort: self.sort === "" ? 1 : Number(self.sort),
                    name: self.name,
                    url: url,
                    content: self.content,
                    source_id: self.source.id,
                    article_id: self.id,
                    vip: self.vipStatus ? 1 : 0,
                    free: self.isNotFree ? 0 : 1,
                    price: self.price === "" ? 0 : Number(self.price),
                    is_vip_purchase: self.isVipPurchase ? 1 : 0,
                    vip_price: self.vip_price === "" ? 0 : Number(self.vip_price),
                };

                requesting = true;

                create(data).then((res) => {
                    self.$refs.toast.show(res, "success");
                    setTimeout(() => {
                        self.toBack();
                        requesting = false;
                    }, 1000);
                });
                setTimeout(() => {
                    requesting = false;
                }, 2000);
            },
            validate() {
                    if (self.isNotFree) {
                        let price = Number(self.price);
                        if (price === 0) {
                            self.$refs.toast.show("باھاسىنى كىرگۈزۈڭ");
                            return false;
                        }
                    }
                    if (self.isVipPurchase){
                        let vip_price = Number(self.vip_price)
                        if (vip_price === 0){
                            self.$refs.toast.show("ئالىي ئەزا سىتىۋىلىش باھاسىنى كىرگۈزۈڭ");
                            return false;
                        }
                    }
                    if (self.chosenSourceIndex === 3) {
                        self.$refs.toast.show("مەزمۇنىدا ئەسەر بار بولغان سالون توپ ماقالە ئادرېسىنى كىرگۈزۈڭ");
                        return false
                    }

                    if (self.source.tag === "direct" && self.articleDirectUrl === "") {
                        self.$refs.toast.show("ئەسەر ئادېرىسىنى كىرگۈزۈڭ");
                        return false;
                    }
                    if (self.source.tag === "tengxun" && self.articleTencentUrl === "") {
                        self.$refs.toast.show("تېڭشۈن ۋىدىئو ئادېرسىنى كىرگۈزۈڭ");
                        return false;
                    }

                return true;
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    .adv-create {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;
        @title-height: 60px;
        @inputHeight: 36px;

        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-back {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transform: rotate(90deg);
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .btn-save {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .form {
            padding: @margin-size;

            .row {
                display: flex;
                margin-bottom: @margin-size-xl;

                .col {
                    flex: 1;

                    &.col-1 {
                        flex: 1;
                    }

                    &.col-2 {
                        flex: 2;
                    }

                    &.col-3 {
                        flex: 3;
                    }

                    label {
                        display: block;
                        position: relative;
                        margin-bottom: @margin-size;

                        .copyFlimUrl {
                            display: flex;
                        }
                        .articleName {
                            flex: 1;
                            padding: 0 0 0 8px;
                        }
                        .copy {
                            text-align: right;
                            color: white;
                            text-align: center;
                            background: fade(#12cc94, 90%);
                            width: 160px;
                            text-align: center;
                            color: white;
                            cursor: pointer;
                            font-weight: normal;
                            transition: background-color 200ms, color 200ms;
                            flex: 1;
                            padding: 8px;
                            border-radius: @radius-size-small;

                            &:hover {
                                background: #0abb87;
                                color: white;
                                border-radius: @radius-size-small 0 0 @radius-size-small;
                            }
                        }
                        .kog {
                            flex: 1;
                        }
                        .range-screen {
                            position: absolute;
                            left: @margin-size-xxl;
                            width: @inputHeight * 2;
                            height: @inputHeight;
                            line-height: @inputHeight;
                            text-align: center;
                            border-radius: @inputHeight / 2;
                            background: @color-primary-dark;
                            color: white;
                        }
                    }

                    .picker,
                    .select-box {
                        width: 74%;
                    }

                    .multi-selector {
                        width: 86%;
                    }

                    .list {
                        padding: 0 @margin-size;
                        .serech {
                            height: @title-height;
                            line-height: @title-height;
                            padding-top: @margin-size;
                            border: 1px solid @color-primary-dark;
                        }
                        .srech-item {
                            display: flex;
                            height: @title-height / 1.3;
                            line-height: @title-height / 1.3;
                        }
                        .srech-word {
                            background: fade(#12cc94, 90%);
                            width: @top-bar-height * 2;
                            text-align: center;
                            color: white;
                            cursor: pointer;
                            font-weight: normal;

                            transition: background-color 200ms, color 200ms;
                            &:hover {
                                background: #0abb87;
                                color: white;
                            }
                        }
                        .input-error {
                            width: 100%;
                            text-align: left;
                            padding-left: @font-size-small;
                            direction: ltr;
                            border: 1px solid #0abb87;
                            border-radius: @radius-size 0 0 @radius-size;
                            .input-placeholder {
                                width: 90%;
                                border: none;
                            }
                            .icon {
                                border: none;
                                color: #0abb87;
                            }
                            .errorIcon {
                                border: none;
                                color: #fd5992;
                            }
                        }
                        .input {
                            width: 100%;
                            text-align: left;
                            padding-left: @font-size-small;
                            direction: ltr;
                            border: 1px solid #0abb87;
                            border-radius: @radius-size 0 0 @radius-size;
                            .input-placeholder {
                                width: 90%;
                                border: none;
                            }
                            .icon {
                                border: none;
                                color: #0abb87;
                            }
                            .errorIcon {
                                border: none;
                                color: #fd5992;
                            }
                        }
                        .input-error {
                            width: 100%;
                            text-align: left;
                            padding-left: @font-size-small;
                            direction: ltr;
                            border: 1px solid #fd5992;
                            border-radius: @radius-size 0 0 @radius-size;
                        }
                        .remind-text {
                            text-align: left;
                            color: #fd5992;
                            padding-top: @margin-size-mini;
                        }
                        .result {
                            display: flex;
                            border: 1px solid #0abb87;
                            margin-top: @radius-size-large;
                            border-radius: @radius-size 0 0 @radius-size;
                            min-height: @title-height / 1.3;
                            vertical-align: -webkit-baseline-middle;
                            .salon-number {
                                width: @top-bar-height * 2.1;
                                background: fade(@color-nice-black, 10%);
                                text-align: center;
                                vertical-align: middle;
                                display: flex;
                                .salon-info {
                                    align-self: center;
                                    margin: 0 auto;
                                }
                            }
                            .content-list {
                                flex: 1;
                                text-align: left;
                                display: flex;
                                justify-content: flex-end;
                                padding-left: @margin-size-small;
                                .content {
                                    .content-item {
                                        margin: 0;
                                        padding-top: @margin-size-small;
                                    }
                                }
                                :last-child {
                                    padding-bottom: @margin-size-small;
                                }
                                .icon {
                                    padding-top: @margin-size-small;
                                    padding-right: @margin-size-small;
                                    color: #0abb87;
                                }
                            }

                            .copy {
                                width: @margin-size-xl * 4;
                                background: fade(#12cc94, 90%);
                                text-align: center;
                                color: white;
                                display: flex;
                                border-radius: @radius-size-small 0 0 @radius-size-small;
                                .copy-word {
                                    align-self: center;
                                    margin: 0 auto;
                                }
                                &:hover {
                                    background: #0abb87;
                                    color: white;
                                    border-radius: @radius-size-small 0 0 @radius-size-small;
                                }
                            }
                        }
                    }
                    .pretty-textarea {
                        width: 80%;
                        max-width: 80%;
                        min-width: 80%;
                        box-sizing: border-box;
                        padding: @margin-size;
                        background: @color-nice-bg;
                        border-radius: @radius-size;
                        border: none;
                    }

                    input.input {
                        width: 74%;
                        height: @inputHeight;
                        border-radius: @inputHeight / 2;
                        padding: 0 @margin-size;
                        border: fade(@color-primary-dark, 20%) 1px solid;
                        box-sizing: border-box;
                        margin-left: @margin-size;

                        &:focus {
                            border-color: fade(@color-primary-dark, 40%);
                        }

                        &.mn {
                            width: 30%;
                        }

                        &.sm {
                            width: 60%;
                        }

                        &.btn {
                            cursor: pointer;
                        }

                        &.ltr {
                            direction: ltr;
                        }
                    }
                    textarea.input {
                        width: 80%;
                        max-width: 80%;
                        min-width: 80%;
                        box-sizing: border-box;
                        padding: @margin-size-small;
                        border-radius: @radius-size;
                        mni-height: @inputHeight;
                        max-height: @inputHeight * 5;
                        border: fade(@color-primary-dark, 20%) 1px solid;
                    }

                    input[type="file"] {
                        display: none;
                    }

                    input[type="range"] {
                        appearance: none;
                        height: @inputHeight;
                        background-color: @color-nice-bg;
                        border-radius: @inputHeight / 2;
                        width: 74%;
                        border: none;

                        &::-webkit-slider-thumb {
                            appearance: none;
                            width: @inputHeight - 4;
                            height: @inputHeight - 4;
                            border-radius: (@inputHeight - 4) / 2;
                            background: @color-primary-dark;
                            cursor: pointer;
                        }

                        &.small {
                            width: 60%;
                        }
                    }

                    @ph-width: 180px;
                    @ph-height: 240px;

                    .upload-placeholder {
                        width: @ph-width;
                        height: @ph-height;
                        line-height: @ph-height;
                        text-align: center;
                        box-sizing: border-box;
                        border-radius: @radius-size;
                        border: fade(@color-primary-dark, 40%) 1px dashed;
                        cursor: pointer;

                        &.large {
                            width: @ph-width * 2;
                        }

                        i {
                            font-size: @font-size-xxxl;
                            color: fade(@color-primary-dark, 40%);
                        }
                    }

                    .image-viewer {
                        height: @ph-height;
                        width: @ph-width;

                        &.large {
                            width: @ph-width * 2;
                        }
                    }
                }
            }
            .btn {
                align-items: center;
                display: flex;
                margin-top: 30px;

                .btn-save {
                    height: 40px;
                    line-height: 40px;
                    width: 50px;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;
                    background: @color-primary-dark;
                    color: white;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }

                .btn-back {
                    height: 40px;
                    line-height: 40px;
                    width: 70px;
                    background: fade(@color-accent, 6%);
                    color: @color-accent;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-end: @margin-size;
                    transition: background-color 200ms, color 200ms;
                    background-color: #fd397a;
                    border-color: #fd397a;
                    color: #fff;

                    &:hover {
                        background-color: #fd397a;
                        border-color: #fd397a;
                        color: #fff;
                    }
                }
            }
        }
    }
</style>