<template>
    <div class="adv-list wrapper">
        <div class="title">
            <div class="word">ئۇنىۋېرسال تەڭشەك</div>
            <div class="btn">
                <div class="btn-refresh" @click="loadList">
                    <i class="iconfont icon-shuaxin"></i>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="header">
                <div class="col id">ID</div>
                <div class="col adv-title">تەڭشەك مەزمۇنى</div>
                <div class="col status">ھالەت</div>
                <div class="col adv-time">ئەڭ ئاخىرقى قىتىم ئۆزگەرتكەن ۋاقىت</div>
            </div>

            <div class="item" v-for="(item,index) in list" :key="index">
                <div class="col id">{{item.id}}</div>
                <div class="col adv-title">{{item.label}}</div>
                <div class="col status">
                    <Switch :status="item.v === 1" @update="switchState(item.id,item.v,index)"/>
                </div>
                <div class="col adv-time">{{item.updated_at}}</div>
            </div>
        </div>
    </div>
    <toast ref="toast"/>
</template>

<style lang="less" scoped>
    @import "../../assets/less/global.less";
    @import "../../assets/less/anim.less";

    @icon-size: 40px;
    .adv-list {
        direction: rtl;
        max-width: @wrap-width;
        min-width: @wrap-min-width;
        margin: @margin-size-large auto;
        border-radius: @radius-size;
        box-sizing: border-box;
        background: white;
        min-height: @wrap-min-height;
        @title-height: 60px;

        .title {
            @btn-size: 40px;
            box-sizing: border-box;
            font-weight: bold;
            padding: 0 @margin-size;
            margin-bottom: @margin-size-large;
            border-bottom: fade(@color-nice-black, 10%) 1px solid;
            height: @title-height;
            line-height: @title-height;
            display: flex;

            .word {
                flex: 1;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-refresh {
                    width: @btn-size;
                    height: @btn-size;
                    line-height: @btn-size;
                    text-align: center;
                    border-radius: @radius-size;
                    color: @color-primary;
                    cursor: pointer;
                    transition: background-color 200ms;

                    &:hover {
                        background: fade(@color-nice-black, 4%);
                    }
                }

                .btn-add {
                    height: @btn-size;
                    line-height: @btn-size - 4;
                    background: fade(@color-primary-dark, 6%);
                    color: @color-primary-dark;
                    cursor: pointer;
                    font-weight: normal;
                    padding: 0 @margin-size;
                    border-radius: @radius-size;
                    margin-inline-start: @margin-size;
                    transition: background-color 200ms, color 200ms;

                    &:hover {
                        background: @color-primary-dark;
                        color: white;
                    }
                }
            }
        }

        .list {
            @opr-btn-size: 40px;
            padding: @margin-size;

            .header {
                width: 100%;
                height: @icon-size * 2;
                align-items: center;
                display: flex;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }

                .col.adv-title {
                    flex: 2;
                }
                .col.adv-name {
                    flex: 1;
                }
                .col.adv-content {
                    flex: 4;
                }
                .col.adv-time {
                    flex: 2;
                    text-align: center;

                }
                .col.status {
                    flex: 1;
                }

                .col.opr {
                    flex: 1;
                    text-align: center;
                }
            }

            .item {
                width: 100%;
                display: flex;
                align-items: center;
                border-top: fade(@color-nice-black, 10%) 1px dashed;
                padding: @margin-size-small 0;

                .col.id {
                    width: @icon-size * 2;
                    text-align: center;
                }
                .col.adv-title {
                    flex: 2;
                }
                .col.adv-name {
                    flex: 1;
                }
                .col.adv-content {
                    flex: 4;
                    text-align: justify;
                    padding: 0 8px;
                }
                .col.adv-time {
                    flex: 2;
                    text-align: center;
                    direction: ltr;
                }

                .col.status {
                    flex: 1;
                }


                .col.opr {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    flex-direction: row;

                    .btn {
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: transparent;
                        transition: background-color 200ms, color 200ms;
                        display: flex;

                        &:hover {
                            background: fade(@color-primary-dark, 6%);
                            color: @color-primary-dark;
                        }
                    }

                    .word {
                        padding-right: 6px;
                    }
                }
            }

        }
    }
</style>

<script>
    import Toast from "../../components/Toast";
    import {booleanSetting, update} from "../../api/setting";
    import Switch from "../../components/Switch";

    let self

    let statusRequestHold = false
    export default {
        name: "BooleanList",
        emits: ['showLoading'],
        components: {Toast,Switch},
        data() {
            return {
                list: [],
            }
        },
        created() {
            self = this
        },
        mounted() {
            self.loadList()
        },
        methods: {
            loadList() {
                booleanSetting().then(res =>
                    self.list = res)
            },
            switchState(id, status, index) {
                if (statusRequestHold) return
                statusRequestHold = true
                let nextStatus = status === 0 ? 1 : 0
                update({
                    id: id,
                    value: nextStatus
                }).then((res) => {
                    statusRequestHold = false
                    self.list[index].status = nextStatus
                    self.$refs.toast.show(res, "success", "center")
                    self.loadList()
                })
            }
        }
    }
</script>