<template>
    <router-view/>
</template>

<style lang="less">
    @import "assets/less/global.less";
    @import "assets/font/font.css";
    @import "assets/css/iconfont.css";
    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 14px;
        color: @color-nice-black;
        background: @color-nice-bg;
        font-family: "UKIJEkran";
    }

    input,textarea,button,select,option{
        outline: none;
        font-family: "UKIJEkran", Helvetica, Arial, sans-serif;
        font-size: @font-size;
    }
    a{
        text-decoration: none;
        color: @color-accent;
    }
    #app {
        position: fixed;
        width: 100%;
        height: 100%;
        font-family: "UKIJEkran", Helvetica, Arial, sans-serif;
        background: @color-nice-bg;
    }
</style>
