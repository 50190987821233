<template>
    <div class="mask" :class="{'hidden': maskHidden,'shown': maskShown}" @click="maskAtClick"></div>
    <div class="confirm-box" :class="{'hidden': boxHidden,'shown': boxShown}">
        <div class="top">
            {{title}}
        </div>
        <div class="mid">
            {{content}}
        </div>
        <div class="bot">
            <div class="btn primary" @click="confirm">{{positiveText}}</div>
            <div class="btn" @click="cancel">{{negativeText}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ConfirmBox",
        emits: ['confirm', 'show', 'cancel'],
        data() {
            return {
                maskHidden: true,
                boxHidden: true,
                maskShown: false,
                boxShown: false,
            }
        },
        props: {
            title: {
                type: String,
                default: 'ئەسكەرتىش'
            },
            content: {
                type: String,
                default: 'جەزىملەمسىز؟'
            },
            positiveText: {
                type: String,
                default: 'شۇنداق'
            },
            negativeText: {
                type: String,
                default: 'ياق'
            },
            clickMaskToCancel: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            confirm() {
                this.$emit('confirm')
            },
            cancel() {
                this.maskShown = false
                this.boxShown = false
                setTimeout(() => {
                    this.maskHidden = true
                    this.boxHidden = true
                }, 200)

            },
            show() {
                this.maskHidden = false
                this.boxHidden = false
                setTimeout(() => {
                    this.boxShown = true
                    this.maskShown = true
                }, 1)
            },
            maskAtClick() {
                if (!this.clickMaskToCancel) {
                    return
                }
                this.cancel()
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../assets/less/global.less";

    .mask {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 999;
        background: rgba(0, 0, 0, 0.382);
        opacity: 0;
        transition: opacity 200ms;

        &.hidden {
            display: none;
        }

        &.shown {
            opacity: 1;
        }
    }

    .confirm-box {
        @bar-height: 52px;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        width: 400px;
        min-height: 200px;
        border-radius: @radius-size;
        background: white;
        position: fixed;
        z-index: 1000;
        left: 50%;
        top: 50%;
        filter: blur(@margin-size);
        transform: translate(-50%, -80%);
        transition: opacity 200ms, transform 200ms, filter 200ms;
        cursor: default;
        opacity: 0;

        &.hidden {
            display: none;
        }

        &.shown {
            opacity: 1;
            filter: none;
            transform: translate(-50%, -50%);
        }

        .top {
            height: @bar-height;
            line-height: @bar-height;
            text-align: center;
        }

        .mid {
            flex: 1;
            direction: rtl;
            padding: @margin-size;
        }

        .bot {
            height: @bar-height;
            line-height: @bar-height;
            display: flex;
            border-top: fade(@color-primary-dark, 4%) 1px solid;

            .btn {
                flex: 1;
                text-align: center;
                color: fade(@color-primary, 61.8%);
                cursor: pointer;

                &:first-child {
                    border-inline-end: fade(@color-primary-dark, 4%) 1px solid;
                }

                &.primary {
                    color: @color-primary;
                }
            }
        }
    }
</style>